
import Vue from 'vue';

export default Vue.extend({
  name: 'ProdTechSearchBarViewToggle',
  props: {
    currentViewType: {
      type: String,
      default: null,
    },
    defaultViewType: {
      type: String,
      default: 'grid',
    },
  },
  computed: {
    isListSelected(): boolean {
      return this.currentViewType === 'list';
    },
    isGridSelected(): boolean {
      return this.currentViewType === 'grid';
    },
  },
  beforeMount() {
    if (!this.currentViewType) {
      this.currentViewType = this.defaultViewType;
    }
  },
  methods: {
    updateViewType(viewType: string) {
      this.$emit('update-view-type', viewType);
    },
  },
});
