import { ProdTech } from '@src/client/vue-app/types/ProdTech';

export function filterProdTechByProgression(
  prodTechs: ProdTech[],
  progression: string
): ProdTech[] {
  return prodTechs.filter((prodTech) => {
    if (!progression) {
      return prodTechs;
    }

    if (!prodTech.technicalInformation || !prodTech.technicalInformation.progression) {
      return false;
    }

    return prodTech.technicalInformation.progression === progression;
  });
}
