var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("iframe", {
    staticClass: "sound-cloud-player",
    style: _vm.style,
    attrs: {
      scrolling: "no",
      frameborder: "no",
      allow: "autoplay",
      src: _vm.embedUrl,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }