
import Vue from 'vue';

import { WaterQualityParameters } from '@src/client/vue-app/data/waterQualityParameters';

import goToStateMixin from '@src/client/vue-app/mixins/goToStateMixin';
import { ProdTechSearchFilters } from '@src/client/vue-app/types/ProdTechSearchFilters';
import { ProdTech } from '@src/client/vue-app/types/ProdTech';
import ProdTechSearchBarViewToggle from './ProdTechSearchBarViewToggle.vue';
import BaseSelect from './BaseSelect.vue';

interface SelectOptionGroup {
  groupName: string;
  items: SelectOption[];
}

interface SelectOption {
  value?: string;
  label: string;
}

export default Vue.extend({
  name: 'ProdTechSearchBar',
  components: { BaseSelect, ProdTechSearchBarViewToggle },
  mixins: [goToStateMixin],
  // NOTE: Using `props` instead of `data` for this, in the event that we decide to use query parameters later
  props: {
    contaminantGroups: {
      type: Array as () => WaterQualityParameters[],
      required: true,
    },
    currentViewType: {
      type: String,
      required: true,
    },
    filters: {
      type: Object as () => ProdTechSearchFilters,
      required: true,
    },
    technologies: {
      type: Array as () => ProdTech[],
      required: true,
    },
  },
  data: () => ({
    prodTechMethods: [
      { value: '', label: '-- Method --' },
      { label: 'Sedimentation' },
      { label: 'Filtration' },
      { label: 'Disinfection' },
      { label: 'Safe Storage' },
    ] as SelectOption[],
    prodTechProgressions: [
      { value: '', label: '-- Progression --' },
      { label: 'Established' },
      { label: 'Adopted' },
      { label: 'Tested' },
      { label: 'Emerging' },
    ] as SelectOption[],
    prodTechTypes: [
      { value: 'product', label: 'Products' },
      { value: 'technology', label: 'Technologies' },
    ] as SelectOption[],
  }),
  computed: {
    prodTechContaminants(): Array<SelectOptionGroup | SelectOption> {
      return [
        { value: '', label: '-- Contaminants --' },
        ...this.contaminantGroups.map(({ groupName, items }) => ({
          groupName,
          items: items.map(({ key, caption }) => ({
            value: key,
            label: caption,
          })),
        })),
      ];
    },
    prodTechTechnologies() {
      return [
        { value: '', label: '-- Technology --' },
        ...this.technologies.map((technology) => ({
          value: technology.id,
          label: technology.title,
        })),
      ];
    },
  },
  methods: {
    onFilterContaminantChange(changeEvent) {
      this.$emit('update-filters', { contaminant: changeEvent.target.value });
    },
    onFilterMethodChange(changeEvent) {
      this.$emit('update-filters', { method: changeEvent.target.value });
    },
    onFilterProgressionChange(changeEvent) {
      this.$emit('update-filters', { progression: changeEvent.target.value });
    },
    onFilterTechnologyChange(changeEvent) {
      this.$emit('update-filters', { technology: changeEvent.target.value });
    },
    onUpdateSearchString(inputEvent) {
      this.$emit('update-filters', { search: inputEvent.target.value });
    },
    onUpdateViewType(viewType: string) {
      this.$emit('update-view-type', viewType);
    },
  },
});
