var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "prodtech-results-list-item bg-color-light",
    },
    [
      _c("div", { staticClass: "prodtech-results-list-item__body" }, [
        _c("div", { staticClass: "prodtech-results-list-item__body--image" }, [
          _c(
            "a",
            {
              staticClass: "mb-small",
              attrs: {
                href: `/products-technologies/${_vm.prodTech.slugId}/${_vm.prodTech.slug}`,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goToState({
                    stateName: "prodtech.detail",
                    params: {
                      prodtechID: _vm.prodTech.slugId,
                      prodtechSlug: _vm.prodTech.slug,
                    },
                  })
                },
              },
            },
            [
              _c("ProdTechImage", {
                attrs: { "prod-tech": _vm.prodTech, size: _vm.imgSize },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "prodtech-results-list-item__body--details" },
          [
            _c("h3", [_vm._v(_vm._s(_vm.prodTech.title))]),
            _vm._v(" "),
            _c("ProdTechResultsListItemMbaList", {
              staticClass: "prodtech-results-list-item__mba--desktop",
              style: {
                marginTop: "10px",
                marginBottom: "10px",
              },
              attrs: { "prod-tech": _vm.prodTech },
            }),
            _vm._v(" "),
            _vm.prodTech.type === "product"
              ? _c(
                  "div",
                  { staticClass: "prodtech-results-list-item__pbar--desktop" },
                  [
                    _c("ProgressionBar", {
                      staticStyle: {
                        "margin-top": "0",
                        "margin-right": "0",
                        "margin-left": "-14px",
                      },
                      attrs: { "prod-tech": _vm.prodTech },
                      on: { click: _vm.onProgressionClicked },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("table", { style: { marginBottom: "20px" } }, [
              _c(
                "tr",
                { staticClass: "prodtech-results-list-item__mba--tablet" },
                [
                  _c("th", [_vm._v("Barriers:")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.barriers.join(", ") || _vm.defaultMessage)
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.prodTech.type === "product"
                ? _c(
                    "tr",
                    { staticClass: "prodtech-results-list-item__pbar--tablet" },
                    [
                      _c("th", [_vm._v("Progression:")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.progression))]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.prodTech.type === "product"
                ? _c("tr", [
                    _c("th", [_vm._v("Technologies:")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.parentTechnologies.join(", ") ||
                            _vm.defaultMessage
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Target contaminants:")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.targetContaminants.join(", ") || _vm.defaultMessage
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Flow rate:")]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.flowRateMessage))]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Time to treat:")]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.timeToTreatMessage))]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("ProdTechResultsListItemActions", {
        attrs: { "prod-tech": _vm.prodTech },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }