var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "pt-sidebar-select" }, [
    _c("h4", { staticClass: "pt-sidebar-select__title" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "pt-sidebar-select__list" },
      _vm._l(_vm.items, function (item, index) {
        return _c(
          "li",
          {
            key: `${item.value}-${index}`,
            staticClass: "pt-sidebar-select__list-item",
          },
          [
            _c(
              "a",
              {
                staticClass: "pt-sidebar-select__link",
                class: { active: _vm.value === item.value },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("input", item.value)
                  },
                },
              },
              [_vm._v(_vm._s(item.label))]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }