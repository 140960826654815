/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */

(function() {
  angular.module('app').controller('CountryListPageCtrl', [
    '$http',
    '$rootScope',
    '$scope',
    'MetaTagsUtil',
    function($http, $rootScope, $scope, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////
      var listLimit = 42;
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList = {};
      $scope.countryList.featuredCountries = [];
      $scope.countryList.allCountries = [];
      $scope.countryList.query = '';
      $scope.countryList.limit = listLimit;
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.clearSearch = function() {
        $scope.countryList.query = '';
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.getFlagImageUrl = function(img) {
        return img ? $rootScope.image_serve_url + 'images/flags/' + img : '';
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.loadMore = function() {
        $scope.countryList.limit += listLimit;
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.loadingFeatured = true;

      $http.get($rootScope.api_url + '/public/country-focus').then(
        function(response) {
          if (response && response.data) {
            $scope.countryList.featuredCountries = _.toArray(response.data);
            if (!$scope.$$phase) $scope.$apply();
            $scope.countryList.loadingFeatured = false;
          }
        },
        function() {
          /* Error */
        },
      );
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.loadingAll = true;

      $http.get($rootScope.api_url + '/public/country-focus-all').then(
        function(response) {
          if (response && response.data) {
            $scope.countryList.allCountries = _.toArray(response.data);
            if (!$scope.$$phase) $scope.$apply();
            $scope.countryList.loadingAll = false;
          }
        },
        function() {
          /* Error */
        },
      );
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.showFeaturedCountries = function() {
        var show = true;
        if ($scope.countryList.loadingFeatured) show = false;
        if ($scope.countryList.query.length > 0) show = false;
        if ($scope.countryList.featuredCountries.length <= 0) show = false;
        return show;
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.countryList.showLoadMore = function() {
        var show = true;
        if ($scope.countryList.loadingAll) show = false;
        if ($scope.countryList.query.length > 0) show = false;
        if ($scope.countryList.allCountries.length <= $scope.countryList.limit) show = false;
        return show;
      };
      // //////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: 'Country Focus | Household Water Treatment and Safe Storage Knowledge Base',
        ogDescription:
          'Projects, experiences, research, questions, and strategies organized by country.',
        ogUrl: 'country-focus',
        ogImage: 'images/social/hwts_social_media_countries.jpg',
      });
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
