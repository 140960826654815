var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "lesson-view-content" },
    [
      !_vm.resourceUrl
        ? [
            _c("div", { staticClass: "temp-content" }, [
              _c("div", { staticClass: "temp-content__body" }, [
                _c(
                  "h3",
                  {
                    staticClass:
                      "text-transform-uppercase temp-iframe__body--main",
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.tempTitle) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "temp-iframe__body--sub" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.tempMessage) + "\n        "
                  ),
                ]),
              ]),
            ]),
          ]
        : _vm.lesson.lessonType === "embedded-module"
        ? [
            _c("iframe", {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: {
                    heightCalculationMethod: "lowestElement",
                    tolerance: 200,
                  },
                  expression:
                    "{ heightCalculationMethod: 'lowestElement', tolerance: 200 }",
                },
              ],
              staticClass: "lesson-iframe",
              attrs: {
                id: "module-iframe",
                src: _vm.resourceUrl,
                title: _vm.lesson.title,
                frameborder: "0",
              },
            }),
          ]
        : _vm.lesson.lessonType === "embedded-video"
        ? [
            _c("iframe", {
              staticClass: "video-iframe",
              attrs: {
                src: _vm.resourceUrl,
                frameborder: "0",
                allow: "autoplay; fullscreen",
                allowfullscreen: "",
              },
            }),
          ]
        : _vm.lesson.lessonType === "podcast-sound-cloud"
        ? [_c("SoundCloudPlayer", { attrs: { "embed-url": _vm.resourceUrl } })]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }