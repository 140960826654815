/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */

(function() {
  angular.module('app.directives').directive('sideMenu', function() {
    return {
      restrict: 'E',
      templateUrl: '/scripts/directives/side_menu.html',
      scope: {
        sections: '=',
        scrollToSection: '=',
        loading: '=',
      },
    };
  });
})();
