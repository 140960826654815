var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container content padding-bottom-0" }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4 col-md-3" }, [
        _c(
          "a",
          {
            staticClass:
              "btn-u btn-u-lg btn-brd btn-brd-hover btn-block margin-top-10 margin-bottom-30",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goToState({
                  stateName: "form",
                  params: { form: "add-a-product" },
                })
              },
            },
          },
          [
            _c("span", { staticClass: "glyphicon glyphicon-plus" }),
            _vm._v("\n        Add a Product\n      "),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "form",
        {
          staticClass: "sky-form",
          staticStyle: { border: "0" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return (() => {}).apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "col-md-12 col-lg-4" }, [
            _c("label", { staticClass: "input" }, [
              _c("i", { staticClass: "icon-append fa fa-search" }),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                staticStyle: { "margin-bottom": "5px" },
                attrs: {
                  type: "text",
                  placeholder: "Search products and technologies...",
                },
                domProps: { value: _vm.filters.search },
                on: { input: _vm.onUpdateSearchString },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("BaseSelect", {
            staticClass: "col-sm-3 col-lg-2",
            attrs: {
              value: _vm.filters.method,
              items: _vm.prodTechMethods,
              name: "filterMBA",
            },
            on: { change: _vm.onFilterMethodChange },
          }),
          _vm._v(" "),
          _c("BaseSelect", {
            staticClass: "col-sm-3 col-lg-2",
            attrs: {
              value: _vm.filters.technology,
              items: _vm.prodTechTechnologies,
              name: "filterTechnology",
            },
            on: { change: _vm.onFilterTechnologyChange },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3 col-lg-2" }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("BaseSelect", {
                  staticStyle: { "flex-grow": "1" },
                  attrs: {
                    value: _vm.filters.contaminant,
                    name: "filterContaminants",
                    items: _vm.prodTechContaminants,
                  },
                  on: { change: _vm.onFilterContaminantChange },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      "font-size": "1.6rem",
                    },
                    attrs: { id: "contaminant-note-link" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit("scrollToNote")
                      },
                    },
                  },
                  [_vm._v("*")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("BaseSelect", {
            staticClass: "col-sm-3 col-lg-2",
            attrs: {
              value: _vm.filters.progression,
              name: "filterProgression",
              items: _vm.prodTechProgressions,
            },
            on: { change: _vm.onFilterProgressionChange },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("resetForm")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-repeat" }),
                    _c("span", [_vm._v(" Reset")]),
                  ]
                ),
                _vm._v(" "),
                _c("ProdTechSearchBarViewToggle", {
                  attrs: {
                    "current-view-type": _vm.currentViewType,
                    "default-view-type": "grid",
                  },
                  on: { "update-view-type": _vm.onUpdateViewType },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-sm-8 col-md-9" }, [
      _c("div", { staticClass: "headline" }, [
        _c("h1", [_vm._v("Products & Technologies")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }