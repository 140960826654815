
import Vue from 'vue';
import { SelectItem } from '@src/client/vue-app/types/SelectItem';

export default Vue.extend({
  name: 'ProdTechSidebarSelect',
  props: {
    items: {
      type: Array as () => SelectItem[],
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
});
