import iFrameResize from 'iframe-resizer/js/iframeResizer';

// Adapted from:
//  How to use with Vue | iframe-resizer | Github
//    https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/use_with/vue.md
//  Writing Custom Vue.js Components | Alligator.io
//    https://alligator.io/vuejs/custom-directives/
export const Resize = {
  bind(el, { value = {} }) {
    el.addEventListener('load', () => iFrameResize(value, el));
  },
};
