
import Vue from 'vue';

export default Vue.extend({
  name: 'LessonViewPagination',
  props: {
    hasNextLesson: {
      type: Boolean,
      required: true,
    },
    hasPreviousLesson: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onNext() {
      if (this.hasNextLesson) {
        this.$emit('next');
      }
    },
    onPrevious() {
      if (this.hasPreviousLesson) {
        this.$emit('previous');
      }
    },
  },
});
