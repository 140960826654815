import {
  contaminantEffectiveToken,
  contaminantVeryEffectiveToken,
} from '@src/client/vue-app/filters/data';

export function filterProdTechByContaminant(items, contaminant) {
  // If the contaminant is falsy, don't bother filtering
  if (!contaminant) {
    return items;
  }

  return items.filter((item) => {
    // If there is no technical information, then filter the item out
    if (
      !item.technicalInformation
      || !item.technicalInformation.treatmentCapacity
      || item.technicalInformation.treatmentCapacity.length === 0
    ) {
      return false;
    }

    const capacity = item
      .technicalInformation
      .treatmentCapacity
      .find(({ parameter }) => parameter === contaminant);

    return (
      capacity
      && (capacity.effectiveness === contaminantVeryEffectiveToken
        || capacity.effectiveness === contaminantEffectiveToken)
    );
  });
}
