/* eslint-disable import/first */

import angular from 'angular';

import {
  configureAuth,
  configureGoogleMaps,
  createUserService,
  doAllAuthImports,
} from '@cawst/angularjs';

import { deploymentConfig } from '@src/config/deploymentConfig';

window.appSettings = { ...deploymentConfig };

// Entry
import '@src/client/scripts/app';

// Vue migration script
import '@src/client/vue-app/vue.components';

// Config block
require('../scripts/config');
require('../scripts/route');

configureAuth(angular);
configureGoogleMaps(angular, deploymentConfig);

// Run block
require('../scripts/run');

// Services
createUserService(angular, deploymentConfig);

import '@src/client/scripts/services/services';

require('../scripts/services/metatags_util');
require('../scripts/services/hwts_ui');
require('../scripts/services/languageInterceptor');
require('../scripts/services/sweet-alert-util');
require('../scripts/services/maps');
require('../scripts/services/form_util');
require('../scripts/services/learn/LessonUtil');

// Controllers
require('../scripts/controllers/main');
require('../scripts/controllers/home');
require('../scripts/controllers/error');
require('../scripts/controllers/themes');
require('../scripts/controllers/search');
require('../scripts/controllers/prodtech/prodtech-list');
require('../scripts/controllers/prodtech/prodtech-detail');
require('../scripts/controllers/prodtech/prodtech-info');
require('../scripts/controllers/projects/projects');
require('../scripts/controllers/projects/project-detail');
require('../scripts/controllers/content');
require('../scripts/controllers/forms');
require('../scripts/controllers/country/country-list');
require('../scripts/controllers/country/country-detail');
require('../scripts/controllers/emergency');
require('../scripts/controllers/promotional/promo-colombia');
require('../scripts/controllers/learn/home');
require('../scripts/controllers/learn/lesson');
require('../scripts/controllers/learn/learnNavController');
require('../scripts/controllers/network/main');
require('../scripts/controllers/network/news');

// Directives
import '@src/client/scripts/directives/directives';

require('../scripts/directives/scroll_position');
require('../scripts/directives/loading_bar');
require('../scripts/directives/header_search_inline');
require('../scripts/directives/progression_bar');
require('../scripts/directives/search_list_item');
require('../scripts/directives/content_section');
require('../scripts/directives/side_menu');
require('../scripts/directives/prodtech_info_section');
require('../scripts/directives/multibarrier_approach');

doAllAuthImports(angular, deploymentConfig);

import '@src/client/scripts/filters/filters';
import '@src/client/scripts/route';

require('../scripts/localization');

import '../styles/main.scss';
import '../styles/print.scss';
