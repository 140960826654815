
import Vue from 'vue';

interface ProdTechProgression {
  id: string;
  imageSrc: string;
  label: string;
  sortOrder: number;
}

const prodTechProgressions: ProdTechProgression[] = [
  {
    id: 'emerging',
    imageSrc: 'images/progression/emerging.png',
    label: 'Emerging',
    sortOrder: 0,
  },
  {
    id: 'tested',
    imageSrc: 'images/progression/tested.png',
    label: 'Tested',
    sortOrder: 1,
  },
  {
    id: 'adopted',
    imageSrc: 'images/progression/adopted.png',
    label: 'Adopted',
    sortOrder: 2,
  },
  {
    id: 'established',
    imageSrc: 'images/progression/established.png',
    label: 'Established',
    sortOrder: 3,
  },
];

export default Vue.extend({
  name: 'ProgressionBar',
  props: {
    prodTech: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    progressions: prodTechProgressions,
  }),
  computed: {
    sortedProgressions(): ProdTechProgression[] {
      return this.progressions.slice().sort((a, b) => a.sortOrder - b.sortOrder);
    },
    currentProgression(): ProdTechProgression {
      const currentLabel = (this.prodTech
          && this.prodTech.technicalInformation
          && this.prodTech.technicalInformation.progression)
        || 'Emerging';

      return this.progressions.find((progression) => progression.label === currentLabel);
    },
  },
  methods: {
    showCircleProgressed(progression: ProdTechProgression): boolean {
      return this.currentProgression.sortOrder >= progression.sortOrder;
    },
    showBarProgressed(progression: ProdTechProgression): boolean {
      const isFurtherProgressed = this.currentProgression.sortOrder > progression.sortOrder;
      const isLastProgression = this.currentProgression.id
        === this.sortedProgressions[this.sortedProgressions.length - 1].id;
      return isFurtherProgressed || isLastProgression;
    },
  },
});
