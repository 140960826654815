
// TODO: Note that the current implementation of BaseButton is incomplete. Further features to be
//  implemented can be found in the Unify 1.9.4 template below.
// /Unify_v1.9.4/HTML/shortcode_btn_general.html

// FIXME: In order to control whether we render a `<button />` or an`<a />`, it would be best if we
//   rewrote this component using Vue render functions

import Vue from 'vue';

// From /Unify_v1.9.4/HTML/shortcode_btn_general.html section on default Unify buttons
const unifyColorMap = new Map([
  ['blue', 'btn-u-blue'],
  ['red', 'btn-u-red'],
  ['orange', 'btn-u-orange'],
  ['turquoise', 'btn-u-sea'],
  ['green', 'btn-u-green'],
  ['yellow', 'btn-u-yellow'],
  ['grey', 'btn-u-default'],
  ['dark', 'btn-u-dark'],
  ['purple', 'btn-u-purple'],
  ['aqua', 'btn-u-aqua'],
  ['brown', 'btn-u-brown'],
  ['dark-blue', 'btn-u-dark-blue'],
  ['light-green', 'btn-u-light-green'],
]);

export default Vue.extend({
  name: 'BaseButton',
  props: {
    unifyColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    colorClass(): string {
      if (!this.unifyColor) {
        return '';
      }

      const color = unifyColorMap.get(this.unifyColor);

      return color || '';
    },
  },
});
