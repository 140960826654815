var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "learn-home-tab-content" },
    [
      _c("h2", { staticClass: "margin-bottom-30" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "margin-bottom-30" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.lessons.length > 0
        ? _c("LessonList", {
            attrs: { lessons: _vm.lessons, locale: _vm.locale },
            on: { "lesson-selected": _vm.onLessonSelected },
          })
        : [
            _c("div", { staticClass: "no-lessons" }, [
              _c("div", { staticClass: "container no-lessons__body" }, [
                _c("h2", { staticClass: "no-lessons__title" }, [
                  _vm._v("No lessons found"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "no-lessons__message" }, [
                  _vm._v(
                    "\n          We could not find any lessons.\n        "
                  ),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }