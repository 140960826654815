
import Vue from 'vue';

type AlertType = 'danger' | 'info' | 'success' | 'warning';

export default Vue.extend({
  name: 'AlertBlock',
  props: {
    alertType: {
      type: String as () => AlertType,
      default: 'info',
    },
    permanent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    alertTypeClass(): string {
      switch (this.alertType) {
        case 'danger':
          return 'alert-danger';
        case 'success':
          return 'alert-success';
        case 'warning':
          return 'alert-warning';
        default:
          return 'alert-info';
      }
    },
  },
});
