
// FIXME: This component should be considered temporary. It was created due to limitations between ngVue and slots

import Vue from 'vue';

import AlertBlock from './AlertBlock.vue';

export default Vue.extend({
  name: 'LearnBetaAlert',
  components: { AlertBlock },
});
