var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "prodtech-results-list" },
    _vm._l(_vm.prodTechs, function (prodTech, index) {
      return _c("ProdTechResultsListItem", {
        key: prodTech.id,
        style: {
          marginBottom: index < _vm.prodTechs.length - 1 ? "20px" : "0",
        },
        attrs: { "prod-tech": prodTech },
        on: {
          "open-progression-details": function ($event) {
            return _vm.$emit("open-progression-details")
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }