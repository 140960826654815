import angular from 'angular';

(() => {
  angular
    .module('app.directives')
    .directive(
      'prodtechInfoSection',
      () => ({
        restrict: 'E',
        templateUrl: 'scripts/directives/prodtech_info_section.html',
        scope: {
          prodtechData: '=',
          section: '=',
          references: '=',
          array: '=',
        },
      })
    );
})();
