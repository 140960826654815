export const environment = {
  isProduction() {
    return process.env.NODE_ENV === 'production';
  },
  isDevelopment() {
    return !this.isProduction();
  },
  getSignedInUserId() {
    // FIXME: TypeCheck does not recognize localStorage
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const profileString = localStorage.getItem('profile');

    if (!profileString) {
      return '';
    }

    // Assumes that our profile object includes email, which at time of writing,
    //   it does
    const profile = JSON.parse(profileString);
    return profile.userId || '';
  },
};

export const defaultLocale = 'en';

// Constant for tracking whether or not a user has _used_ a microlearning
export const defaultUsedLessonMs = 10000;

export const config = {
  mbaMethods: [
    'Source Protection',
    'Safe Transportation',
    'Sedimentation',
    'Filtration',
    'Disinfection',
    'Safe Storage',
  ],
  // Fuse.js search options can be found here: https://fusejs.io/api/options.html
  prodTechFuzzySearchOptions: {
    // threshold: 0.0-1.0
    //   - Represents how close the fuzzy-search match must be
    //   - the smaller the value, the closer the match must be
    threshold: 0.3,

    // location: At what character index in the strings to begin searching
    location: 0,

    // distance: The farthest character index the fuzzy-search will check inside of string values
    distance: 100,
    minMatchCharLength: 2,

    // NOTE: Each weight needs to be between 0 and 1, but _all_ weights _do not_ need to add up to 1
    //   For ease of reading / concept, I have implemented an X / 100 weighting style
    keys: [
      {
        name: 'id',
        weight: 10 / 100,
      },
      {
        name: 'slug',
        weight: 10 / 100,
      },
      {
        name: 'slugId',
        weight: 10 / 100,
      },
      {
        name: 'title',
        weight: 40 / 100,
      },
      {
        name: 'manufacturer.name',
        weight: 10 / 100,
      },
      {
        name: 'description',
        weight: 10 / 100,
      },
      {
        name: 'prodtech.title',
        weight: 10 / 100,
      },
      {
        name: 'technicalInformation.progression',
        weight: 20 / 100,
      },
      {
        name: 'technicalInformation.treatmentProcess',
        weight: 20 / 100,
      },
      {
        name: 'technicalInformation.treatmentCapacity.parameter',
        weight: 20 / 100,
      },
    ],
  },
};
