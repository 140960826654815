var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "prodtech-image",
      style: { width: _vm.size, height: _vm.size },
    },
    [
      _c("div", {
        staticClass: "prodtech-image__image",
        class: _vm.imageClasses,
        style: {
          width: "100%",
          height: "100%",
          backgroundImage: `url('${_vm.imageSrc}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
      }),
      _vm._v(" "),
      _vm.prodTech.type === "product"
        ? _c("div", { staticClass: "prodtech-image__overlay" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }