/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */

(function() {
  angular.module('app.services').factory('httpRequestInterceptor', [
    '$location',
    '$q',
    '$rootScope',
    function($location, $q, $rootScope) {
      var translatedLanguages = ['en', 'fr', 'es'];

      var language = getCookie('l');
      if (!language || translatedLanguages.indexOf(language) === -1) {
        var systemLanguage = null;
        if (navigator && navigator.languages) {
          for (var i = 0; i < navigator.languages.length; i += 1) {
            if (translatedLanguages.indexOf(navigator.languages[i]) !== -1) {
              systemLanguage = navigator.languages[i];
              break;
            }
          }
        }
        if (systemLanguage) language = systemLanguage;
        else language = 'en';
        if (language !== 'en') {
          document.cookie = 'l=' + language + ';path=/';
          location.reload(true);
        }
      } else {
        if (language !== 'en') document.cookie = 'l=' + language + ';path=/';
      }
      moment.locale(language);

      return {
        // On request failure
        responseError: function(rejection) {
          if (rejection.status === 404) {
            // console.log(rejection); // Contains the data about the error on the request.
            $location.path('/');
            // Return the promise rejection.
            return $q.reject(rejection);
          }
        },
        request: function(config) {
          if (config.url.indexOf($rootScope.api_url) !== -1) {
            if (config.url.indexOf('?') === -1) config.url += '?';
            if (
              config.url[config.url.length - 1] !== '&' &&
              config.url[config.url.length - 1] !== '?'
            )
              config.url += '&';
            config.url += 'l=' + language;
          }
          return config;
        },
      };
    },
  ]);

  function getCookie(name) {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return value !== null ? unescape(value[1]) : null;
  }
})();
