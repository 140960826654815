/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */

(function() {
  angular.module('app').controller('ProjectDetailPageCtrl', [
    '$http',
    '$rootScope',
    '$scope',
    '$state',
    'GoogleMapUtil',
    'MetaTagsUtil',
    function($http, $rootScope, $scope, $state, GoogleMapUtil, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////
      $scope.loadingProjects = true;
      $scope.mapSettings = GoogleMapUtil.getMapSettings();
      // //////////////////////////////////////////////////////////////////////
      function setMetaTags() {
        MetaTagsUtil.SetSocialMetaTags({
          ogTitle:
            'Project: ' +
            $scope.projectDetails.title +
            ' | Household Water Treatment and Safe Storage Knowledge Base',
          ogDescription: $scope.projectDetails.description
            ? $scope.projectDetails.description
            : ' ',
          ogUrl: 'projects/' + $state.params.projectID + '/' + $state.params.projectSlug,
          ogImage: 'images/social/hwts_social_media_project.jpg',
        });
      }
      // //////////////////////////////////////////////////////////////////////
      GoogleMapUtil.getProject($state.params.projectID, function(project) {
        $scope.loadingProjects = false;
        if (project) {
          $scope.projectDetails = project;
          $rootScope.projectTitle = project.title; // used for breadcrumb
          $scope.mapSettings.map.zoom = 6;
          $scope.mapSettings.map.center = {
            latitude: project.latitude,
            longitude: project.longitude,
          };
          setMetaTags();
        }
      });
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
