/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import angular from 'angular';

import { deploymentConfig } from '@src/config/deploymentConfig';

(() => {
  angular
    .module('app') //
    .controller('EmergencyCtrl', [
      '$element',
      '$http',
      '$scope',
      '$uiViewScroll',
      'GoogleMapUtil',
      'MetaTagsUtil',
      function ($element, $http, $scope, $uiViewScroll, GoogleMapUtil, MetaTagsUtil) {
        $scope.emergencyDetail = {
          loading: true,
          data: {},
          sectionLength: 4,
          sections: {
            featured: {
              id: 'featured',
              show: false,
              title: 'Featured Resources',
              items: [],
            },
            prodtech: {
              id: 'prodtech',
              show: false,
              title: 'Products and Technologies',
              items: [],
            },
            research: {
              id: 'research',
              show: false,
              title: 'Research',
              items: [],
            },
            document: {
              id: 'document',
              show: false,
              title: 'Documents',
              items: [],
            },
            experience: {
              id: 'experience',
              show: false,
              title: 'Experiences',
              items: [],
            },
            'case-study': {
              id: 'case-study',
              show: false,
              title: 'Case Studies',
              items: [],
            },
            project: {
              id: 'project',
              show: false,
              title: 'Projects',
              items: [],
            },
          },
          scrollToSection: (elementId) => {
            $uiViewScroll($element.find('#' + elementId));
          },
        };

        function getContent(contentType, next) {
          if (contentType === 'featured') {
            // Get Featured items
            $http
              .get(deploymentConfig.api.appUrl + '/public/featured')
              .then((response) => {
                if (response && response.data) {
                  // eslint-disable-next-line max-len
                  $scope.emergencyDetail.sections[contentType].items = response.data.emergency || [];
                  next(contentType, $scope.emergencyDetail.sections[contentType].items);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            $http
              .get(deploymentConfig.api.appUrl + '/public/emergency/' + contentType)
              .then((response) => {
                if (response && response.data) {
                  const { data } = response;
                  if (contentType === 'project') {
                    const projects = data ? GoogleMapUtil.processProjects(data) : [];
                    const latitudes = [];
                    const longitudes = [];
                    projects.forEach((project) => {
                      if (project.latitude) {
                        latitudes.push(project.latitude);
                      }
                      if (project.longitude) {
                        longitudes.push(project.longitude);
                      }
                    });
                    $scope.emergencyDetail.data.center = {
                      latitude: window._.mean(latitudes),
                      longitude: window._.mean(longitudes),
                    };
                    $scope.emergencyDetail.sections[contentType].items = projects;
                  } else {
                    $scope.emergencyDetail.sections[contentType].items = data;
                  }
                  next(contentType, $scope.emergencyDetail.sections[contentType].items);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }

        // making calls for the content types from the sections
        const sections = Object.keys($scope.emergencyDetail.sections);

        sections.forEach((section) => {
          getContent(section, (contentType, data) => {
            if (data && data.length > 0) {
              $scope.emergencyDetail.sections[contentType].show = true;
            }
          });
        });

        $scope.emergencyDetail.loading = false;

        MetaTagsUtil.SetSocialMetaTags({
          ogTitle: 'Household Water Treatment in Emergencies',
          ogDescription:
            'These resources have been identified as relevant to emergency response and, specifically, HWTS in the emergency context.',
          ogUrl: 'emergency',
          ogImage: 'images/social/hwts_social_media_emergency.jpg', // TODO: upload this image
        });
      },
    ]);
})();
