var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "prodtech-results-list-item-actions" }, [
    _c(
      "a",
      {
        staticClass:
          "btn-u btn-brd btn-brd-hover prodtech-results-list-item-actions--details",
        attrs: {
          href: `/products-technologies/${_vm.prodTech.slugId}/${_vm.prodTech.slug}`,
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.goToState(_vm.detailsState)
          },
        },
      },
      [_vm._v("Details")]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass:
          "btn-u btn-brd btn-brd-hover prodtech-results-list-item-actions--technical",
        class: {
          "btn-u-orange": _vm.isTechInfoAvailable,
          "btn-u-light-grey": !_vm.isTechInfoAvailable,
          disabled: !_vm.isTechInfoAvailable,
        },
        attrs: {
          href: `/products-technologies/${_vm.prodTech.slugId}/${_vm.prodTech.slug}/technical-information`,
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.goToState(_vm.technicalState)
          },
        },
      },
      [_vm._v("Technical Information")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }