
import Vue from 'vue';

import MainSectionCard from '@src/client/vue-app/components/MainSectionCard.vue';

export default Vue.extend({
  components: {
    MainSectionCard,
  },
  // FIXME: Each card-data type needs to have its `title` and `description` run through t-functions (eventually). I am
  //   not sure where that content should live. I believe an `i18n/` folder would be ideal.
  data() {
    return {
      emergencyData: {
        title: 'Emergency Context',
        description:
          'Are you an emergency response practitioner? Here’s your one stop shop for resources on HWTS in emergencies.',
        icon: 'emergency-icon',
        state: {
          stateName: 'emergency',
        },
      },
      prodTechData: {
        title: 'Products & Technologies',
        description:
          'Catalogue of HWTS solutions focused on independent evaluation and evidence-based action. Information on microbiological performance, operation and maintenance, research, experiences and other supporting documents.',
        icon: 'fa-tint',
        state: {
          stateName: 'prodtech',
        },
      },
      approchesData: {
        title: 'Implementation Approaches',
        description:
          'A technology is only as good as how it is implemented. Learn about and share experiences with different implementation approaches. What has worked, what hasn’t and why?',
        icon: 'fa-pencil',
        state: {
          stateName: 'theme',
          params: { themeSlug: 'approaches' },
        },
      },
      projectsData: {
        title: 'Projects',
        description:
          "Who's doing what where. See what's happening in your country and add your own project.",
        icon: 'fa-map-marker',
        state: {
          stateName: 'projects',
        },
      },
      countryFocusData: {
        title: 'Country Focus',
        description:
          'Learn about relevant, country-specific government policies and regulations along with other key documents, projects and research, by country.',
        icon: 'fa-globe',
        state: {
          stateName: 'country',
        },
      },
      monitoringData: {
        title: 'Monitoring & Evaluation',
        description:
          'How do you measure success? How do you learn from failure? Learn how to effectively monitor and evaluate HWTS projects.',
        icon: 'fa-check-square-o',
        state: {
          stateName: 'theme',
          params: { themeSlug: 'monitoring-evaluation' },
        },
      },
    };
  },
});
