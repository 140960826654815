
import Vue from 'vue';

export default Vue.extend({
  props: {
    browsingLanguage: {
      type: String,
      default: 'en',
    },
  },
});
