
import Vue from 'vue';

import goToStateMixin from '../mixins/goToStateMixin';

import LessonService from '../services/learn/LessonService';
import { Lesson } from '../types/Lesson';
import { defaultLocale } from '../config';
import LessonList from './LessonList.vue';

// FIXME: This interaction would typically be handled by Vuex
const lessonService = new LessonService();

export default Vue.extend({
  name: 'LearnHomeTabContent',
  components: {
    LessonList,
  },
  mixins: [goToStateMixin],
  props: {
    lessonGroupSlug: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: defaultLocale,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    lessons(): Lesson[] {
      return this.lessonGroupSlug
        ? lessonService.getLessonsByGroupSlug(this.lessonGroupSlug)
        : lessonService.getLessons();
    },
  },
  methods: {
    clearLessonFilters() {
      this.goToState({
        stateName: 'learn',
        config: { inherit: false },
      });
    },
    onLessonSelected(lessonId) {
      const lesson = lessonService.getLessonById(lessonId);

      if (lesson.lessonType === 'embedded-module') {
        window.open(lessonService.getLessonResourceUrl(lessonId, this.locale));
      } else {
        this.goToState({
          stateName: 'learn.lesson',
          params: { id: lessonId },
          config: { inherit: false },
        });
      }
    },
  },
});
