var render = function render(_c, _vm) {
  return _vm._m(0)
}
var staticRenderFns = [
  function (_c, _vm) {
    return _c("div", { staticClass: "learn-home-heading" }, [
      _c("div", { staticClass: "learn-home-heading__spacer" }),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-sm-8" }, [
            _c("h1", { staticClass: "learn-home-heading__title" }, [
              _vm._v("Household Water Treatment Learn Space"),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "learn-home-heading__subtitle",
                staticStyle: { "margin-bottom": "20px" },
              },
              [
                _vm._v(
                  "Wondering how you can provide safe drinking water in homes, schools and clinics in your community?"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "learn-home-heading__tagline",
                staticStyle: { "margin-bottom": "20px" },
              },
              [
                _vm._v(
                  "\n          Household Water Treatment and Safe Storage (HWTS) is a simple and effective solution.\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "learn-home-heading__tagline" }, [
              _vm._v("Find all the basics to get started here!"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }