import angular from 'angular';

import LessonService from '@src/client/vue-app/services/learn/LessonService';
import { Lesson } from '@src/client/vue-app/types/Lesson';

(() => {
  angular //
    .module('app.services')
    .service('LessonUtil', [
      '$state',
      ($state) => {
        const lessonService = new LessonService();

        return {
          currentLesson(): Lesson | null {
            return lessonService.getLessonById($state.params.id);
          },
          getLessonById(lessonId: string): Lesson | null {
            return lessonService.getLessonById(lessonId);
          },
        };
      },
    ]);
})();
