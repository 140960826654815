// From the `manager-site` project
// src/client/scripts/controllers/ng-ctrl-hwts-items.ts:86-128

type GroupItem = {
  key: string;
  caption: string;
}

export type WaterQualityParameters = {
  groupName: string;
  items: GroupItem[];
}

export const waterQualityParameters: WaterQualityParameters[] = [
  {
    groupName: 'Chemicals',
    items: [
      { key: 'Arsenic', caption: 'Arsenic' },
      { key: 'Fluoride', caption: 'Fluoride' },
      { key: 'Heavy Metals', caption: 'Heavy Metals' },
      { key: 'Chromium', caption: '- Chromium' },
      { key: 'Lead', caption: '- Lead' },
      { key: 'Iron', caption: 'Iron' },
      { key: 'Manganese', caption: 'Manganese' },
      { key: 'Nitrate', caption: 'Nitrate' },
      { key: 'Pesticides', caption: 'Pesticides' },
      { key: 'Salt', caption: 'Salt' },
    ],
  },
  {
    groupName: 'Microbiological',
    items: [
      { key: 'Bacteria', caption: 'Bacteria' },
      { key: 'Cholera', caption: '- Cholera' },
      { key: 'Helminths', caption: 'Helminths' },
      { key: 'Protozoa', caption: 'Protozoa' },
      { key: 'Cryptosporidium', caption: '- Cryptosporidium' },
      { key: 'Giardia', caption: '- Giardia' },
      { key: 'Toxoplasma oocysts', caption: '- Toxoplasma oocysts' },
      { key: 'Viruses', caption: 'Viruses' },
    ],
  },
  {
    groupName: 'Physical',
    items: [
      { key: 'Colour', caption: 'Colour' },
      { key: 'Hardness', caption: 'Hardness' },
      { key: 'Odour', caption: 'Odour' },
      { key: 'Organic matter', caption: 'Organic matter' },
      { key: 'pH', caption: 'pH' },
      { key: 'Suspended particles', caption: 'Suspended particles' },
      { key: 'Taste', caption: 'Taste' },
      { key: 'Turbidity', caption: 'Turbidity' },
    ],
  },
];
