
import Vue from 'vue';

import goToStateMixin from '@src/client/vue-app/mixins/goToStateMixin';
import LessonService from '@src/client/vue-app/services/learn/LessonService';
import { Lesson } from '@src/client/vue-app/types/Lesson';
import { defaultLocale, defaultUsedLessonMs } from '@src/client/vue-app/config';
import LessonViewBanner from '../components/LessonViewBanner.vue';
import LessonViewContent from '../components/LessonViewContent.vue';
import LessonViewPagination from '../components/LessonViewPagination.vue';

// FIXME: When scaled, replace this local service with a connection to Vuex
const lessonService = new LessonService();

export default Vue.extend({
  name: 'LearnLesson',
  components: {
    LessonViewBanner,
    LessonViewContent,
    LessonViewPagination,
  },
  mixins: [goToStateMixin],
  props: {
    lessonId: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: defaultLocale,
    },
    trackUsedMicroLearning: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    trackVisitedMicroLearning: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
  },
  data: () => ({
    timeoutFnId: null,
  }),
  computed: {
    hasNextLesson() {
      return lessonService.hasNextLesson(this.lessonId);
    },
    hasPreviousLesson() {
      return lessonService.hasPreviousLesson(this.lessonId);
    },
    lesson(): Lesson {
      return lessonService.getLessonById(this.lessonId);
    },
    lessonResourceUrl(): string {
      return lessonService.getLessonResourceUrl(this.lessonId, this.locale);
    },
  },
  mounted() {
    if (this.lesson.lessonType === 'embedded-video') {
      this.trackVisitedMicroLearning();

      this.timeoutFnId = setTimeout(() => {
        this.trackUsedMicroLearning();
      }, defaultUsedLessonMs);
    }
  },
  beforeDestroy() {
    if (this.timeoutFnId) {
      clearTimeout(this.timeoutFnId);
    }
  },
  methods: {
    onMenu() {
      this.goToState({ stateName: 'learn' });
    },
    onNext() {
      const nextLesson = lessonService.getNextLesson(this.lessonId);
      this.goToState({
        stateName: 'learn.lesson',
        params: { id: nextLesson.id },
      });
    },
    onPrevious() {
      const previousLesson = lessonService.getPreviousLesson(this.lessonId);
      this.goToState({
        stateName: 'learn.lesson',
        params: { id: previousLesson.id },
      });
    },
    openLessonUrl() {
      window.open(lessonService.getLessonResourceUrl(this.lessonId, this.locale));
    },
  },
});
