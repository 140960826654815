import { render, staticRenderFns } from "./ProdTechResultsList.vue?vue&type=template&id=06bba7de&"
import script from "./ProdTechResultsList.vue?vue&type=script&lang=ts&"
export * from "./ProdTechResultsList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/node-apps/hwts-site/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06bba7de')) {
      api.createRecord('06bba7de', component.options)
    } else {
      api.reload('06bba7de', component.options)
    }
    module.hot.accept("./ProdTechResultsList.vue?vue&type=template&id=06bba7de&", function () {
      api.rerender('06bba7de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/vue-app/components/ProdTechResultsList.vue"
export default component.exports