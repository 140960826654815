/* eslint-disable no-param-reassign */

import angular from 'angular';
import * as _ from 'lodash';

import { captureError } from '@src/client/scripts/util/captureError';
import { captureErrorMessage } from '@src/client/scripts/util/captureErrorMessage';

(() => {
  angular.module('app').controller('ProdTechInfoCtrl', [
    '$http',
    '$sce',
    '$rootScope',
    '$scope',
    '$state',
    'HwtsUiUtil',
    'MetaTagsUtil',
    ($http, $sce, $rootScope, $scope, $state, HwtsUiUtil, MetaTagsUtil) => {
      // ///////////////////////////////////////////////////////////////////////
      const prodTechId = $state.params.prodtechID;

      // FIXME: Illegal character range, but not sure how to fix it
      const styleRegex = /(font-family|font-size|line-height|margin(-[a-z]*)?):\s?[.a-zA-Z0-9(&quot;),-\s]*(;|(?="))\s?/g;

      // ///////////////////////////////////////////////////////////////////////
      $scope.prodTechInfo = {};
      $scope.loadingProdTechInfo = true;

      // ///////////////////////////////////////////////////////////////////////
      function setMetaTags() {
        MetaTagsUtil.SetSocialMetaTags({
          ogTitle: $scope.prodTechInfo.title,
          ogDescription: $scope.prodTechInfo.description ? $scope.prodTechInfo.description : ' ',
          ogUrl: `products-technologies/${prodTechId}/${$state.params.prodtechSlug}/technical-information`,
          ogImage: 'images/social/hwts_social_media_prodtech.jpg',
        });
      }

      // ///////////////////////////////////////////////////////////////////////
      function getReferencesForParameters(items) {
        const referenceMeta = {
          references: [],
          referenceInfo: {},
        };

        if (items && items.length > 0) {
          // get references for labratory efficiency
          for (let entryIndex = 0; items && entryIndex < items.length; entryIndex += 1) {
            const effectivenessEntry = items[entryIndex];

            for (
              let laboratoryEfficiencyIndex = 0;
              effectivenessEntry.laboratoryEfficiency
              && laboratoryEfficiencyIndex < effectivenessEntry.laboratoryEfficiency.length;
              laboratoryEfficiencyIndex += 1
            ) {
              // eslint-disable-next-line max-len
              const laboratoryEfficiencyEntry = effectivenessEntry.laboratoryEfficiency[laboratoryEfficiencyIndex];

              for (
                let referenceIndex = 0;
                laboratoryEfficiencyEntry.references
                && referenceIndex < laboratoryEfficiencyEntry.references.length;
                referenceIndex += 1
              ) {
                const reference = laboratoryEfficiencyEntry.references[referenceIndex];

                // if reference doesn't exist, add it
                if (referenceMeta.references.indexOf(reference.id) === -1) {
                  referenceMeta.references.push(reference.id);
                  referenceMeta.referenceInfo[reference.id] = reference;
                }
              }
            }
          }

          // get references for field efficiency
          for (
            let effectivenessEntryIndex = 0;
            items && effectivenessEntryIndex < items.length;
            effectivenessEntryIndex += 1
          ) {
            const effectivenessEntry = items[effectivenessEntryIndex];

            for (
              let fieldEfficiencyIndex = 0;
              effectivenessEntry.fieldEfficiency
              && fieldEfficiencyIndex < effectivenessEntry.fieldEfficiency.length;
              fieldEfficiencyIndex += 1
            ) {
              const fieldEfficiencyEntry = effectivenessEntry.fieldEfficiency[fieldEfficiencyIndex];

              for (
                let referenceIndex = 0;
                fieldEfficiencyEntry.references
                && referenceIndex < fieldEfficiencyEntry.references.length;
                referenceIndex += 1
              ) {
                const reference = fieldEfficiencyEntry.references[referenceIndex];

                // if reference doesn't exist, add it
                if (referenceMeta.references.indexOf(reference.id) === -1) {
                  referenceMeta.references.push(reference.id);
                  referenceMeta.referenceInfo[reference.id] = reference;
                }
              }
            }
          }
        }

        return referenceMeta;
      }
      // ///////////////////////////////////////////////////////////////////////
      $http
        .get(`${$rootScope.api_url}/public/technology-product/tech-info/${prodTechId}`)
        .then((response) => {
          if (response && response.data) {
            const { data } = response;

            // # REFERENCES ----------------------------------------------------
            let tempReferences = [];
            let tempReferenceInfo = {};

            if (!data.references) data.references = [];
            if (!data.referenceInfo) data.referenceInfo = {};

            if (data.references.length > 0) {
              // if references exist, get references and change data.references
              // to be an array of id's instead of an array of objects
              // *** NOTE: Need to do this so reference numbers work properly in UI. ***

              // FIXME: Convert to for-of or .forEach() loop once tested
              for (
                let referenceIndex = 0;
                referenceIndex < data.references.length;
                referenceIndex += 1
              ) {
                const refId = data.references[referenceIndex].id;
                const refInfo = _.find(data.referenceInfo, { id: refId });

                if (tempReferences.indexOf(refId) === -1) {
                  tempReferences.push(refId);
                }

                tempReferenceInfo[refId] = refInfo;
              }
            } else {
              // if there are no references sent, check for references
              const microbiologicalParametersReferences = getReferencesForParameters(
                data.microbiologicalParameters,
              );

              const otherParametersReferences = getReferencesForParameters(data.otherParameters);

              tempReferences = _.uniq(
                _.concat(
                  microbiologicalParametersReferences.references,
                  otherParametersReferences.references,
                ),
              );

              tempReferenceInfo = _.assign(
                microbiologicalParametersReferences.referenceInfo,
                otherParametersReferences.referenceInfo,
              );
            }

            data.references = tempReferences;
            data.referenceInfo = tempReferenceInfo;
            // -----------------------------------------------------------------

            data.mainImageUrl = HwtsUiUtil.prodtechMainImageUri(data);
            data.factSheetGraphicUrl = HwtsUiUtil.prodtechFactSheetImageUri(data);

            data.body = data.body.replace(styleRegex, '');

            $scope.prodTechInfo = data;
            $scope.bodySafeHTML = $sce.trustAsHtml(data.body);
            $rootScope.prodtechTitle = data.title;
            setMetaTags();
            if (!$scope.$$phase) {
              $scope.$apply();
            }
          } else if (!response.data) {
            captureErrorMessage(
              'ProdTech Technical Information given falsy (null or undefined) data',
            );
          } else if (_.isEmpty(response.data)) {
            captureErrorMessage('ProdTech Technical Information given empty data');
          }

          $scope.loadingProdTechInfo = false;
        })
        .catch((error) => {
          captureError(error);
        });
      // ///////////////////////////////////////////////////////////////////////
      $scope.selectEffectivenessClass = (effectiveness) => {
        let effectivenessClass = '';

        if (!effectiveness) return effectivenessClass;

        if (effectiveness.indexOf('(>99%)') !== -1) {
          effectivenessClass = 'very-effective';
        } else if (effectiveness.indexOf('(>90%)') !== -1) {
          effectivenessClass = 'effective';
        } else if (effectiveness.indexOf('(>80%)') !== -1) {
          effectivenessClass = 'somewhat-effective';
        } else if (effectiveness.indexOf('(<80%)') !== -1) {
          effectivenessClass = 'not-effective';
        }

        return effectivenessClass;
      };

      // ///////////////////////////////////////////////////////////////////////
      function htmlToPlaintext(text) {
        return text ? window.he.decode(String(text).replace(/<[^>]+>/gm, '')) : '';
      }

      $scope.getCitationText = ($index, item) => {
        if (!item) {
          return '';
        }
        return $sce.trustAsHtml(
          `${$index + 1}. ${htmlToPlaintext(item.citation ? item.citation : item.title)}`,
        );
      };

      $scope.getCitationLink = (item) => {
        if (!item) {
          return '';
        }
        const slugId = item.id.substring(0, 8);
        return `/${item.type}/${slugId}/${item.title}`;
      };

      // FIXME: prodTechData is of unknown type - this should be resolved
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      $scope.shouldShowInfoSection = (prodTechData: any, isArray = false) => {
        let show = false;
        if (prodTechData) {
          if (prodTechData.value || prodTechData.comments) {
            show = true;
          }

          if (isArray) {
            // FIXME: This is the legacy iterator... Is there a better pattern?
            // eslint-disable-next-line no-restricted-syntax
            for (const prodTechDataKey in prodTechData) {
              if (prodTechData[prodTechDataKey].value || prodTechData[prodTechDataKey].comments) {
                show = true;
              }
            }
          }
        }
        return show;
      };
      // ///////////////////////////////////////////////////////////////////////
    },
  ]);
})();
