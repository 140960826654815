/* eslint-disable no-param-reassign */

import angular from 'angular';

(() => {
  angular //
    .module('app') //
    .controller('LearnLessonCtrl', [
      '$rootScope',
      '$scope',
      '$state',
      '$window',
      'LessonUtil',
      ($rootScope, $scope, $state) => {
        $scope.lessonId = $state.params.id;
        $scope.browsingLanguage = $rootScope.browsingLanguage;
      },
    ]);
})();
