
import Vue from 'vue';

import { Lesson } from '@src/client/vue-app/types/Lesson';
import { Resize } from '@src/client/vue-app/directives/Resize';
import LessonService from '@src/client/vue-app/services/learn/LessonService';
import { defaultLocale } from '@src/client/vue-app/config';
import SoundCloudPlayer from './SoundCloudPlayer.vue';

const lessonService = new LessonService();

export default Vue.extend({
  name: 'LessonViewContent',
  components: {
    SoundCloudPlayer,
  },
  directives: {
    Resize,
  },
  props: {
    lesson: {
      type: Object as () => Lesson,
      required: true,
    },
    locale: {
      type: String,
      default: defaultLocale,
    },
  },
  computed: {
    tempTitle(): string {
      const contentName = this.lesson.lessonType === 'embedded-module' ? 'Module' : 'Video';
      return `${contentName} still under development`;
    },
    tempMessage(): string {
      const contentName = this.lesson.lessonType === 'embedded-module' ? 'module' : 'video';
      return `A localized version of the "${this.lesson.tyle}" ${contentName} will be available soon.`;
    },
    resourceUrl(): string {
      return lessonService.getLessonResourceUrl(this.lesson.id, this.locale);
    },
  },
});
