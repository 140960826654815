
import Vue from 'vue';

import goToStateMixin from '@src/client/vue-app/mixins/goToStateMixin';

export default Vue.extend({
  mixins: [goToStateMixin],
  props: {
    cardData: {
      type: Object,
      required: true,
    },
    iconFormattingClass: {
      type: String,
      default: null,
    },
  },
});
