import { Lesson } from '@src/client/vue-app/types/Lesson';

export const learnLessons: Lesson[] = [
  {
    id: 'lesson-1',
    topic: 'hwts',
    slug: 'the-multi-barrier-approach-to-safe-drinking-water',
    title: 'The Multi-Barrier Approach to Safe Drinking Water',
    lessonType: 'embedded-module',
    order: 1,
    groupSlugs: ['hwts', 'treatment', 'implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Multi-Barrier_Approach_new_yfd5df.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Multi-Barrier_Approach_new_yfd5df.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-2',
    topic: 'hwts',
    slug: 'household-water-treatment-and-safe-storage-hwts',
    title: 'Household Water Treatment and Safe Storage (HWTS)',
    lessonType: 'embedded-module',
    order: 2,
    groupSlugs: ['hwts', 'treatment', 'implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/HWTS_Intro_new_txx3p5.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/HWTS_Intro_new_txx3p5.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-3',
    topic: 'hwts',
    slug: 'what-is-correct-consistent-and-continued-use-of-hwts',
    title: 'What is Correct, Consistent and Continued Use of HWTS?',
    lessonType: 'embedded-module',
    order: 3,
    groupSlugs: ['hwts', 'implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/HCCC_Use_of_HWTS_lp7qhy.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/CCC_Use_of_HWTS_lp7qhy.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-4',
    topic: 'hwts',
    slug: 'source-water-protection',
    title: 'Source Water Protection',
    lessonType: 'embedded-module',
    order: 4,
    groupSlugs: ['hwts'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/source_protection_l37bif.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/source_protection_l37bif.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-5',
    topic: 'hwts',
    slug: 'safe-water-transport',
    title: 'Safe Water Transportation',
    lessonType: 'embedded-module',
    order: 5,
    groupSlugs: ['hwts'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/transport_vnyukf.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/transport_vnyukf.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-6',
    topic: 'hwts',
    slug: 'treatment-sedimentation',
    title: 'Treatment: Sedimentation',
    lessonType: 'embedded-module',
    order: 6,
    groupSlugs: ['hwts', 'treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Treatment_Sedimentation_new_tyme2w.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Treatment_Sedimentation_new_tyme2w.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-7',
    topic: 'hwts',
    slug: 'sedimentation-method-settling',
    title: 'Sedimentation Method: Settling',
    lessonType: 'embedded-module',
    order: 7,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Settling_new_gtygzk.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Settling_new_gtygzk.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-8',
    topic: 'hwts',
    slug: 'sedimentation-method-natural-coagulants',
    title: 'Sedimentation Method: Natural Coagulants',
    lessonType: 'embedded-module',
    order: 8,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Natural_Coagulants_nrgigo.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Natural_Coagulants_nrgigo.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-9',
    topic: 'hwts',
    slug: 'sedimentation-method-chemical-coagulants',
    title: 'Sedimentation Method: Chemical Coagulants',
    lessonType: 'embedded-module',
    order: 9,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Chemical_Coagulants_vsnsoh.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Chemical_Coagulants_vsnsoh.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-10',
    topic: 'hwts',
    slug: 'treatment-filtration',
    title: 'Treatment: Filtration',
    lessonType: 'embedded-module',
    order: 10,
    groupSlugs: ['hwts', 'treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Treatment_Filtration_new_yn88zk.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Treatment_Filtration_new_yn88zk.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-11',
    topic: 'hwts',
    slug: 'filtration-method-cloth-filter',
    title: 'Filtration Method: Cloth Filter',
    lessonType: 'embedded-module',
    order: 11,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Cloth_Filtration_rnbej2.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Cloth_Filtration_rnbej2.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-12',
    topic: 'hwts',
    slug: 'filtration-method-membrane-filter',
    title: 'Filtration Method: Membrane Filter',
    lessonType: 'embedded-module',
    order: 12,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Membrane_Filter_new_ydmdxb.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Membrane_Filter_new_ydmdxb.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-13',
    topic: 'hwts',
    slug: 'filtration-method-ceramic-pot-filter',
    title: 'Filtration Method: Ceramic Pot Filter',
    lessonType: 'embedded-module',
    order: 13,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Cermic_pot_new_i6wjj2.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Cermic_pot_new_i6wjj2.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-26',
    topic: 'hwts',
    slug: 'filtration-method-ceramic-candle-filter',
    title: 'Filtration Method: Ceramic Candle Filter',
    lessonType: 'embedded-module',
    order: 14,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/best-ceramic-water-filters_vzllfk.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/best-ceramic-water-filters_vzllfk.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-14',
    topic: 'hwts',
    slug: 'filtration-method-biosand-filter',
    title: 'Filtration Method: Biosand Filter',
    lessonType: 'embedded-module',
    order: 15,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/BSF_gsd9pe.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/BSF_gsd9pe.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-15',
    topic: 'hwts',
    slug: 'treatment-disinfection',
    title: 'Treatment: Disinfection',
    lessonType: 'embedded-module',
    order: 16,
    groupSlugs: ['hwts', 'treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Treatment_Disinfection_ppdeou.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Treatment_Disinfection_ppdeou.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-16',
    topic: 'hwts',
    slug: 'disinfection-method-chlorine',
    title: 'Disinfection Method: Chlorine',
    lessonType: 'embedded-module',
    order: 17,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Chlorine_a17uyg.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Chlorine_a17uyg.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-17',
    topic: 'hwts',
    slug: 'disinfection-method-boiling',
    title: 'Disinfection Method: Boiling',
    lessonType: 'embedded-module',
    order: 18,
    groupSlugs: ['treatment'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Boil_odebwj.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Boil_odebwj.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-18',
    topic: 'hwts',
    slug: 'safe-water-storage-and-handling',
    title: 'Safe Water Storage and Handling',
    lessonType: 'embedded-module',
    order: 19,
    groupSlugs: ['hwts'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/safestorage_dark_mw1kvz.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/safestorage_dark_mw1kvz.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-19',
    topic: 'hwts',
    slug: 'common-myths-and-misconceptions-of-hwts',
    title: 'Common Water Treatment Myths and Misconceptions',
    lessonType: 'embedded-module',
    order: 20,
    groupSlugs: ['hwts', 'implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Myths_and_Misconceptions_new_c3dlry.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Myths_and_Misconceptions_new_c3dlry.jpg',
    },
    supportedLocales: ['en', 'es'],
  },
  {
    id: 'lesson-27',
    topic: 'hwts',
    slug: 'technology-applicability-framework-for-hwts',
    title: 'Technology Applicability Framework (TAF) for HWTS',
    lessonType: 'embedded-module',
    order: 21,
    groupSlugs: ['implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/TAF_b1cmzg.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/TAF_b1cmzg.jpg',
    },
    supportedLocales: ['es'],
  },
  {
    id: 'lesson-28',
    topic: 'hwts',
    slug: 'service-delivery-model',
    title: 'Service Delivery Model',
    lessonType: 'embedded-module',
    order: 22,
    groupSlugs: ['implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Service_delivery_fpkckh.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Service_delivery_fpkckh.jpg',
    },
    supportedLocales: ['es'],
  },
  {
    id: 'lesson-29',
    topic: 'hwts',
    slug: 'market-based-approaches-to-hwts',
    title: 'Market Based Approaches to HWTS',
    lessonType: 'embedded-module',
    order: 23,
    groupSlugs: ['implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584051180/hwts-e-learning/thumbnails/Market-based_zajjhd.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,w_400/v1584051180/hwts-e-learning/thumbnails/Market-based_zajjhd.jpg',
    },
    supportedLocales: ['en'],
  },
  {
    id: 'lesson-20',
    topic: 'hwts',
    slug: 'colombia-regulatory-changes-to-enable-hwts',
    title: 'Colombia – Regulatory changes to enable HWTS',
    lessonType: 'embedded-video',
    order: 24,
    groupSlugs: ['implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584111585/hwts-e-learning/thumbnails/Colombia_Regulatory_frevbz.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,h_300/v1584111585/hwts-e-learning/thumbnails/Colombia_Regulatory_frevbz.jpg',
    },
    resourceUrls: {
      en: 'https://player.vimeo.com/video/408680998?texttrack=en',
      fr: 'https://player.vimeo.com/video/408680998?texttrack=fr',
      es: 'https://player.vimeo.com/video/408680998?texttrack=es',
    },
  },
  {
    id: 'lesson-21',
    topic: 'hwts',
    slug: 'colombia-hwts-implementors-insights',
    title: 'Colombia – HWTS Implementors insights (Learning Exchange, Bogota 2019)',
    lessonType: 'embedded-video',
    order: 25,
    groupSlugs: ['implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584111585/hwts-e-learning/thumbnails/Implementors_kcjhpi.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,h_300/v1584111585/hwts-e-learning/thumbnails/Implementors_kcjhpi.jpg',
    },
    resourceUrls: {
      en: 'https://player.vimeo.com/video/408688958?texttrack=en',
      fr: 'https://player.vimeo.com/video/408688958?texttrack=fr',
      es: 'https://player.vimeo.com/video/408688958?texttrack=es',
    },
  },
  {
    id: 'lesson-22',
    topic: 'hwts',
    slug: 'colombia-biosand-filter-case-study-in-viota-cundinamarca',
    title: 'Colombia – Biosand Filter Case Study in Viotá, Cundinamarca',
    lessonType: 'embedded-video',
    order: 26,
    groupSlugs: ['implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584111585/hwts-e-learning/thumbnails/BSFCaseStudy_iw7avg.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,h_300/v1584111585/hwts-e-learning/thumbnails/BSFCaseStudy_iw7avg.jpg',
    },
    resourceUrls: {
      en: 'https://player.vimeo.com/video/408695632?texttrack=en',
      fr: 'https://player.vimeo.com/video/408695632?texttrack=fr',
      es: 'https://player.vimeo.com/video/408695632?texttrack=es',
    },
  },
  {
    id: 'lesson-23',
    topic: 'hwts',
    slug: 'colombia-ceramic-pot-case-study-in-jerjet-la-guajira',
    title: 'Colombia – Ceramic Pot Case Study in Jerjet, La Guajira',
    lessonType: 'embedded-video',
    order: 27,
    groupSlugs: ['implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584111585/hwts-e-learning/thumbnails/vulnerable_ui3kpn.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,h_300/v1584111585/hwts-e-learning/thumbnails/vulnerable_ui3kpn.jpg',
    },
    resourceUrls: {
      en: 'https://player.vimeo.com/video/408674525?texttrack=en',
      fr: 'https://player.vimeo.com/video/408674525?texttrack=fr',
      es: 'https://player.vimeo.com/video/408674525?texttrack=es',
    },
  },
  // {
  //   id: 'lesson-24',
  //   topic: 'hwts',
  //   slug: 'magazine-hwts-market-based-approaches',
  //   title: 'Magazine: HWTS Market Based Approaches',
  //   lessonType: 'embedded-module',
  //   order: 24,
  //   groupSlugs: ['implementation'],
  //   images: {
  //     banner:
  //       'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584111585/hwts-e-learning/thumbnails/Magazine_dark_xdkmbq.jpg',
  //     preview:
  //       'https://res.cloudinary.com/cawst-am/image/upload/c_scale,h_300/v1584111585/hwts-e-learning/thumbnails/Magazine_dark_xdkmbq.jpg',
  //   },
  //   supportedLocales: ['en', 'es'],
  // },
  {
    id: 'lesson-25',
    topic: 'hwts',
    slug: 'podcasts-hwts-market-based-approaches-case-studies',
    title: 'Podcasts: HWTS Market Based Approaches Case Studies',
    lessonType: 'podcast-sound-cloud',
    order: 28,
    groupSlugs: ['implementation'],
    images: {
      banner:
        'https://res.cloudinary.com/cawst-am/image/upload/c_fill,h_400,w_970/v1584111585/hwts-e-learning/thumbnails/ecofiltro_dark_yjugnh.jpg',
      preview:
        'https://res.cloudinary.com/cawst-am/image/upload/c_scale,h_300/v1584111585/hwts-e-learning/thumbnails/ecofiltro_dark_yjugnh.jpg',
    },
    resourceUrls: {
      en:
        'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/775126272&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
    },
  },
];
