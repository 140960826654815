import Vue from 'vue';

import { AngularJsRouterState } from '@src/client/vue-app/types/AngularJsRouterState';
import { eventBus } from '../event-bus';

export default Vue.extend({
  methods: {
    goToState(state: AngularJsRouterState): void {
      eventBus.$emit('ng:stateChange', state);
    },
  },
});
