var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "prodtech-search-bar-view-toggle" }, [
    _c(
      "div",
      {
        staticClass: "btn btn-default",
        class: { selected: _vm.isGridSelected },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.updateViewType("grid")
          },
        },
      },
      [_c("i", { staticClass: "fa fa-th" })]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "btn btn-default",
        class: { selected: _vm.isListSelected },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.updateViewType("list")
          },
        },
      },
      [_c("i", { staticClass: "fa fa-th-list" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }