
import Vue from 'vue';

import { ProdTech } from '@src/client/vue-app/types/ProdTech';
import { AngularJsRouterState } from '@src/client/vue-app/types/AngularJsRouterState';

import goToStateMixin from '@src/client/vue-app/mixins/goToStateMixin';

export default Vue.extend({
  name: 'ProdTechResultsListItemActions',
  mixins: [goToStateMixin],
  props: {
    prodTech: {
      // FIXME: Apply appropriate typing once required field types are known
      type: Object as () => ProdTech,
      required: true,
    },
  },
  computed: {
    detailsState(): AngularJsRouterState {
      return {
        stateName: 'prodtech.detail',
        params: {
          // FIXME: prodtechID and prodtechSlug are legacy angular router params
          prodtechID: this.prodTech.slugId,
          prodtechSlug: this.prodTech.slug,
        },
      };
    },
    technicalState(): AngularJsRouterState {
      return {
        stateName: 'prodtech.detail.techInfo',
        params: {
          prodtechID: this.prodTech.slugId,
          prodtechSlug: this.prodTech.slug,
        },
      };
    },
    isTechInfoAvailable(): boolean {
      // Technologies always have technical information available
      if (this.prodTech.type === 'technology') {
        return true;
      }

      // We do not give technical information on emerging or nascent technologies
      const invalidProgressions = ['Emerging', 'Nascent'];
      const progression = this.prodTech.technicalInformation.progression || null;
      return progression && !invalidProgressions.includes(progression);
    },
  },
});
