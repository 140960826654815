/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

(() => {
  angular.module('app').controller('HomePageCtrl', [
    '$http',
    '$rootScope',
    '$scope',
    '$state',
    'GoogleMapUtil',
    'HwtsUiUtil',
    'MetaTagsUtil',
    function ($http, $rootScope, $scope, $state, GoogleMapUtil, HwtsUiUtil, MetaTagsUtil) {
      $http
        .get($rootScope.api_url + '/public/home')
        .then((response) => {
          const { data } = response;
          data.prodTechs.forEach((prodTech) => {
            prodTech.mainImageUrl = _.get(prodTech, 'images[0].uri', null);
          });
          response.data.experiences.forEach((experience) => {
            experience.organizations = _.get(experience, 'organizations[0].name', null);
          });
          $scope.prodTechs = data.prodTechs;
          $scope.experiences = data.experiences;
        })
        .catch((err) => {
          console.error('error in HomePageCtrl:', err);
        });

      $scope.projects = [];

      $scope.homeMap = {};
      $scope.homeMap.mapSettings = GoogleMapUtil.getMapSettings();
      $scope.homeMap.mapSettings.map.window.closeClick();
      $scope.homeMap.mapSettings.mapEvents = {
        zoom_changed(map) {
          const zoomLevel = map.getZoom();
          if (zoomLevel >= 6) {
            $scope.homeMap.mapSettings.typeOfMarker = 'spider';
            $scope.homeMap.mapSettings.typeOptions = _.clone(
              $scope.homeMap.mapSettings.spiderfierOptions,
            );
          } else {
            $scope.homeMap.mapSettings.typeOfMarker = 'cluster';
            $scope.homeMap.mapSettings.typeOptions = _.clone(
              $scope.homeMap.mapSettings.clusterOptions,
            );
          }
        },
      };
      $scope.homeMap.mapSettings.map.center = {
        latitude: 1.51,
        longitude: -1.76,
      };
      $scope.homeMap.mapSettings.map.zoom = 2;

      $scope.prodtechMainImageUri = HwtsUiUtil.prodtechMainImageUri;

      GoogleMapUtil.getProjects((projects) => {
        $scope.projects = projects;
        $scope.$apply();
      });

      $scope.searchPageGo = (query) => {
        // can't get submit on enter to work without this function
        const searchQuery = query || '';
        $state.go('search', { query: searchQuery, page: 1 }, { reload: true });
      };

      MetaTagsUtil.SetSocialMetaTags({});
    },
  ]);
})();
