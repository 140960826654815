import { ProdTech } from '@src/client/vue-app/types/ProdTech';

export function sortProdTechs(prodTechs: ProdTech[]): ProdTech[] {
  const result = prodTechs.slice();

  result.sort((a, b) => {
    const aIsTechnology = a.type === 'technology';
    const bIsTechnology = b.type === 'technology';

    if (aIsTechnology && bIsTechnology) {
      return a.title.localeCompare(b.title);
    }

    if (aIsTechnology) {
      return -1;
    }

    if (bIsTechnology) {
      return 1;
    }

    if ((a.showOnFrontPage && b.showOnFrontPage) || (!a.showOnFrontPage && !b.showOnFrontPage)) {
      return a.title.localeCompare(b.title);
    }

    if (a.showOnFrontPage) {
      return -1;
    }

    if (b.showOnFrontPage) {
      return 1;
    }

    return 0;
  });

  return result;
}
