import type { Lesson } from '@src/client/vue-app/types/Lesson';

export function getSupportedLocaleCodes(lesson: Lesson): string[] {
  const localeSet = new Set([
    ...(lesson.supportedLocales || []),
    ...(lesson.resourceUrls ? Object.keys(lesson.resourceUrls) : []),
  ]);

  return Array
    .from(localeSet)
    .sort((a, b) => a.localeCompare(b));
}
