var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "lessons" }, [
    _c(
      "div",
      { staticClass: "lessons__grid" },
      _vm._l(_vm.lessons, function (lesson) {
        return _c("LessonListItem", {
          key: `lesson-${lesson.id}`,
          staticClass: "lessons__grid-item",
          attrs: { lesson: lesson, locale: _vm.locale },
          on: {
            "lesson-selected": function ($event) {
              return _vm.$emit("lesson-selected", lesson.id)
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }