import { organizationDetails } from '@cawst/core/lib/config/organizationDetails';
import { configsMap } from './_constants';
import { DeploymentConfig } from './_types';
import { mergeMapValues } from './mergeMapValues';

const deploymentTarget = process.env.DEPLOYMENT_TARGET || 'local';

export const deploymentConfig = {
  ...mergeMapValues(configsMap, { targetKey: deploymentTarget }) as DeploymentConfig,
  organizationDetails,
};
