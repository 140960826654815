/* eslint-disable no-param-reassign */

import angular from 'angular';
import { capitalize, get, isEmpty } from 'lodash';

import { captureErrorMessage } from '@src/client/scripts/util/captureErrorMessage';

(() => {
  angular.module('app').controller('ProdTechDetailCtrl', [
    '$element',
    '$http',
    '$rootScope',
    '$scope',
    '$state',
    '$uiViewScroll',
    'GoogleMapUtil',
    'HwtsUiUtil',
    'MetaTagsUtil',
    (
      $element,
      $http,
      $rootScope,
      $scope,
      $state,
      $uiViewScroll,
      GoogleMapUtil,
      HwtsUiUtil,
      MetaTagsUtil,
    ) => {
      // //////////////////////////////////////////////////////////////////////
      const prodTechId = $state.params.prodtechID ? $state.params.prodtechID : null;
      let prodTechFullId = prodTechId; // Is assigned to inside `getProdTechData(...)`

      if (!prodTechId) {
        $state.go('prodtech');
      }
      // //////////////////////////////////////////////////////////////////////
      $scope.prodTechDetail = {};
      // //////////////////////////////////////////////////////////////////////
      $scope.prodTechDetail.data = {};
      $scope.prodTechDetail.sections = {
        faq: {
          id: 'faq',
          show: false,
          title: 'Frequently Asked Questions (FAQ)',
          items: [],
        },
        'case-study': {
          id: 'case-study',
          show: false,
          title: 'Case Studies',
          items: [],
        },
        experience: {
          id: 'experience',
          show: false,
          title: 'Experiences',
          items: [],
        },
        research: {
          id: 'research',
          show: false,
          title: 'Research',
          items: [],
        },
        document: {
          id: 'document',
          show: false,
          title: 'More Information',
          items: [],
        },
        project: {
          id: 'project',
          show: false,
          title: 'Projects',
          items: [],
        },
      };
      // //////////////////////////////////////////////////////////////////////
      function setMetaTags() {
        MetaTagsUtil.SetSocialMetaTags({
          ogTitle: `${capitalize($scope.prodTechDetail.data.type)}: ${
            $scope.prodTechDetail.data.title
          } | Household Water Treatment and Safe Storage Knowledge Base`,
          ogDescription: $scope.prodTechDetail.data.description || ' ',
          ogUrl: `products-technologies/${prodTechId}/${$state.params.prodtechSlug}`,
          ogImage: 'images/social/hwts_social_media_prodtech.jpg',
        });
      }
      // //////////////////////////////////////////////////////////////////////
      function getProdTechData(id, cb) {
        $http.get(`${$rootScope.api_url}/public/technology-product/${id}`).then(
          (response) => {
            if (response && response.data) {
              if (response && response.data) {
                $rootScope.prodtechTitle = response.data.title;
                prodTechFullId = response.data.id;
                $scope.prodTechDetail.data = response.data;
                $scope.prodTechDetail.data.mainImageUrl = HwtsUiUtil.prodtechMainImageUri(
                  response.data,
                );
                setMetaTags();
                if (!$scope.$$phase) $scope.$apply();
              }
              if ($scope.control && $scope.control.refresh) {
                $scope.control.refresh();
                $scope.control.getGMap().setCenter(new window.google.maps.LatLng(1.51, -1.76));
              }
              cb(true);
            } else {
              if (!response.data) {
                captureErrorMessage('ProdTech info page was given null data');
              } else if (isEmpty(response.data)) {
                captureErrorMessage('ProdTech info page was given empty data');
              }

              cb(false);
            }
          },
          () => {
            captureErrorMessage('ProdTech info page data promise rejected');
            cb(false);
          },
        );
      }

      function getProdTechContent(fullId, contentType, cb?) {
        function showIfEmpty() {
          if (contentType === 'experience' || contentType === 'research') {
            $scope.prodTechDetail.sections[contentType].show = true;
          }
        }
        function failure() {
          showIfEmpty();
          if (cb) {
            cb(false);
          }
        }
        function success(response) {
          if (response && response.data) {
            if (response.data.length > 0) {
              $scope.prodTechDetail.sections[contentType].items = response.data;
              $scope.prodTechDetail.sections[contentType].show = true;
              if (!$scope.$$phase) $scope.$apply();
            }
            if (cb) {
              cb(true);
            }
          } else {
            failure();
          }
        }
        $http
          .get(`${$rootScope.api_url}/public/technology-product/${fullId}/${contentType}`)
          .then(success, failure);
      }
      // //////////////////////////////////////////////////////////////////////
      $scope.prodTechDetail.loading = true;
      $scope.prodTechDetail.loadingContent = true;
      $scope.prodTechDetail.loadingProjects = true;

      getProdTechData(prodTechId, (_success) => {
        $scope.prodTechDetail.loading = false;

        getProdTechContent(prodTechFullId, 'faq');
        getProdTechContent(prodTechFullId, 'case-study');
        getProdTechContent(prodTechFullId, 'experience');
        getProdTechContent(prodTechFullId, 'research');
        getProdTechContent(prodTechFullId, 'document');

        $scope.prodTechDetail.loadingContent = false;

        GoogleMapUtil.getProjects((projects) => {
          $scope.prodTechDetail.sections.project.items = projects.filter((project) => {
            if (project.relatedItems) {
              return project.relatedItems.indexOf(prodTechFullId) !== -1;
            }
            if (project.prodTechs) {
              const relatedProdTechs = project.prodTechs.filter(
                (prodTech) => prodTech.id === prodTechFullId
              );
              if (relatedProdTechs.length > 0) {
                return true;
              }
            }

            return false;
          });

          if ($scope.prodTechDetail.loadingProjects) {
            $scope.prodTechDetail.loadingProjects = false;
          }

          if (
            $scope.prodTechDetail.sections.project.items.length > 0
            && !$scope.prodTechDetail.sections.project.show
          ) {
            $scope.prodTechDetail.sections.project.show = true;
          }

          $scope.$apply();
        });
      });
      // //////////////////////////////////////////////////////////////////////
      $scope.prodTechDetail.scrollToSection = (elementId) => {
        $uiViewScroll($element.find(`#${elementId}`));
      };

      $scope.prodTechDetail.showTechInfoBtn = () => {
        const isTechnology = get($scope.prodTechDetail.data, 'type') === 'technology';
        if (isTechnology) {
          return true;
        }

        const isDraft = get($scope.prodTechDetail.data, 'draft');
        const progression = get($scope.prodTechDetail.data, 'technicalInformation.progression');
        return !isDraft && progression && progression !== 'Nascent' && progression !== 'Emerging';
      };

      $scope.prodTechDetail.showProgression = () => {
        const isTechnology = get($scope.prodTechDetail.data, 'type') === 'technology';
        const progression = get($scope.prodTechDetail.data, 'technicalInformation.progression');

        return !isTechnology && progression && progression !== 'Nascent';
      };
    },
  ]);
})();
