/* eslint-disable @typescript-eslint/camelcase */

import angular from 'angular';

import { findProdTechMainImageUrl } from '@src/client/vue-app/util/findProdTechMainImageUrl';

(() => {
  angular.module('app.services').service('HwtsUiUtil', [
    '$rootScope',
    '$state',
    'store',
    '$window',
    function ($rootScope, $state, store, $window) {
      // //////////////////////////////////////////////////////////////////////
      const imageServeUrl = $rootScope.image_serve_url;
      // //////////////////////////////////////////////////////////////////////
      this.contentTypeLabelMap = {
        casestudy: 'Case Study',
        'case-study': 'Case Study',
        document: 'Document',
        experience: 'Experience',
        faq: 'FAQ (Frequently Asked Questions)',
        implementation_update: 'Implementation Update',
        prodtech: 'Product or Technology',
        project: 'Project',
        research: 'Research',
        update: 'Update',
        manufacturer_sheet: 'Manufacturer Sheet',
        policy_regulation: 'Policy or Regulation',
        technical_update: 'Technical Update',
        website: 'Website',
        manual: 'Manual',
        evaluation: 'Evaluation',
        'journal-article': 'Journal Article',
      };

      this.hwtsItemTypeMap = {
        hwtsCaseStudy: 'case-study',
        hwtsResearch: 'research',
        hwtsDocument: 'document',
        hwtsProject: 'project',
        hwtsExperience: 'experience',
        hwtsProductTechnology: 'prodtech',
        hwtsFaq: 'faq',
      };

      this.contentTypeSlugMap = {
        casestudy: 'case-study',
        manufacturer_sheet: 'document',
        policy_regulation: 'document',
        manual: 'document',
        implementation_update: 'document',
        technical_update: 'document',
        evaluation: 'document',
        website: 'document',
      };

      this.prodtechDetailLabelMap = {
        batchVolume: {
          id: 'batchVolume',
          label: 'Batch Volume',
          defaultUnits: 'Litres',
        },
        flowRate: {
          id: 'flowRate',
          label: 'Flow Rate',
          defaultUnits: 'Litres per Minute',
        },
        dosageRate: {
          id: 'dosageRate',
          label: 'Dosage Rate',
          defaultUnits: '',
        },
        timeToTreat: {
          id: 'timeToTreat',
          label: 'Time to Treat',
          defaultUnits: '',
        },
        lifespan: {
          id: 'lifespan',
          label: 'Lifespan',
          defaultUnits: '',
        },
        operatingCost: {
          id: 'operatingCost',
          label: 'Operating Cost',
          defaultUnits: 'USD',
        },
        logistics: {
          id: 'logistics',
          label: 'Logistics',
          defaultUnits: '',
        },
        factoryPrice: {
          id: 'factoryPrice',
          label: 'Factory Price',
          defaultUnits: 'USD',
        },
        inCountryPrice: {
          id: 'inCountryPrice',
          label: 'In-Country Price',
          defaultUnits: 'USD',
        },
      };
      // //////////////////////////////////////////////////////////////////////
      this.stringIsEmail = (string) => {
        // FIXME: ESLint reports that the escaped \[ characters are done so uselessly
        //   but without tests, it would seem foolish to modify the regex
        // eslint-disable-next-line no-useless-escape
        const regex = /(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/g;
        return regex.test(string);
      };
      // //////////////////////////////////////////////////////////////////////
      this.redirectBack = () => {
        const currentStateIsForm = $state.current.name === 'form';
        const nextPageAfterFormSubmit = store.get('nextPageAfterFormSubmit')
          ? store.get('nextPageAfterFormSubmit')
          : null;

        if ($rootScope.fromStateName && $rootScope.fromStateName.length > 0) {
          // if the previous page was on hwts.info
          // --> go back to that page
          $window.history.back();
        } else if (currentStateIsForm && nextPageAfterFormSubmit) {
          // if the previous page was not on hwts.info,
          //   and the current page is a form,
          //   and 'nextPageAfterFormSubmit' has been set and is available in 'store'
          // --> go to the url in 'store', then delete that stored url
          $state.go(nextPageAfterFormSubmit);
          store.remove('nextPageAfterFormSubmit');
        } else {
          // if the previous page was not on hwts.info,
          // --> go to the hwts.info home page
          $state.go('home');
        }
      };
      // //////////////////////////////////////////////////////////////////////
      this.getPublicPdfDownload = (uri) => {
        $window.open(`${$rootScope.api_url}/public/download?uri=${uri}`);
      };
      // //////////////////////////////////////////////////////////////////////
      this.prodtechMainImageUri = (prodtech) => findProdTechMainImageUrl(prodtech);
      // //////////////////////////////////////////////////////////////////////
      this.prodtechFactSheetImageUri = (prodtech) => {
        let uri = null;
        if (prodtech && prodtech.factSheetGraphicUrl) {
          uri = imageServeUrl + prodtech.factSheetGraphicUrl;
        }
        return uri;
      };
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
