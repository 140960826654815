import { ProdTech } from '@src/client/vue-app/types/ProdTech';

export function filterProdTechByTreatmentMethod(prodTechs: ProdTech[], method: string): ProdTech[] {
  // If method is falsy, then don't bother filtering
  if (!method) {
    return prodTechs;
  }

  return prodTechs.filter(
    // FIXME: Use optional chaining once TS has been upgraded
    (prodTech) => prodTech.technicalInformation
      && prodTech.technicalInformation.treatmentProcess
      && prodTech.technicalInformation.treatmentProcess.includes(method),
  );
}
