/* eslint-disable block-scoped-var */
/* eslint-disable func-names */
/* eslint-disable guard-for-in */
/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable vars-on-top */

var target = document;
var translationStrings = translationStrings ? translationStrings : {};
var translatedStrings = [];
var observer = new MutationObserver(function(mutations) {
  mutations.forEach(function(mutation) {
    for (var i in mutation.addedNodes) {
      var node = mutation.addedNodes[i];
      if (node.tagName) {
        handleTagMutation(node);
      }
    }
    if (mutation.type === 'characterData') {
      handleTagMutation(mutation.target, true);
    }
  });
});
var ignoredTags = ['SCRIPT', 'STYLE'];
function handleTagMutation(node, force) {
  if (!force && (!node.tagName || ignoredTags.indexOf(node.tagName) !== -1)) {
    return;
  }

  addTranslationString(node);
  for (var j in node.childNodes) {
    handleTagMutation(node.childNodes[j]);
  }
}
function addTranslationString(node) {
  for (var i = 0; i < node.childNodes.length; i += 1) {
    if (node.childNodes[i].nodeType === 3) {
      addTranslationStringInternal(node.childNodes[i]);
    }
  }
  if (node.childNodes.length === 0) {
    addTranslationStringInternal(node);
  }
}
function addTranslationStringInternal(node) {
  if (findAncestor(node, 'a0-lock-container')) return false;
  if (findAncestor(node, 'angular-google-map')) return false;
  if (isDoNotTranslate(node)) return false;
  var text = node.textContent;
  var translatedText;
  text = text.trim();
  if (text.length > 0 && isNaN(Number(text)) && translatedStrings.indexOf(text) === -1) {
    translatedText = getTranslatedText(text);
    translatedStrings.push(translatedText);
    node.textContent = node.textContent.replace(text, translatedText);
  }
  if (node.placeholder && node.placeholder.length > 0) {
    text = node.placeholder;
    translatedText = getTranslatedText(text);
    translatedStrings.push(translatedText);
    node.placeholder = node.placeholder.replace(text, translatedText);
  }
  return true;
}
function isDoNotTranslate(node) {
  if (node.parentElement && node.parentElement.hasAttribute('notranslate')) {
    return true;
  }
  return false;
}
function findAncestor(node, cls) {
  while ((node = node.parentElement) && !node.classList.contains(cls));
  return node;
}
function getTranslatedText(text) {
  if (translationStrings.hasOwnProperty(text)) {
    return translationStrings[text];
  }
  // #HWTS-43 workaround: return the untranslated text
  // TODO: Log missing translations
  return text;
}

var config = {
  attributes: true,
  childList: true,
  characterData: true,
  subtree: true,
};
observer.observe(target, config);
// observer.disconnect();

/*
function flipString(aString) {
  var last = aString.length - 1;
  var result = new Array(aString.length);
  for (var i = last; i >= 0; i -= 1) {
    var c = aString.charAt(i);
    var r = flipTable[c];
    result[last - i] = r !== undefined ? r : c;
  }
  return result.join('');
}
*/

/*
var flipTable = {
  '\u0021': '\u00A1',
  '\u0022': '\u201E',
  '\u0026': '\u214B',
  '\u0027': '\u002C',
  '\u0028': '\u0029',
  '\u002E': '\u02D9',
  '\u0033': '\u0190',
  '\u0034': '\u152D',
  '\u0036': '\u0039',
  '\u0037': '\u2C62',
  '\u003B': '\u061B',
  '\u003C': '\u003E',
  '\u003F': '\u00BF',
  A: '\u2200',
  B: '\u10412',
  C: '\u2183',
  D: '\u25D6',
  E: '\u018E',
  F: '\u2132',
  G: '\u2141',
  J: '\u017F',
  K: '\u22CA',
  L: '\u2142',
  M: '\u0057',
  N: '\u1D0E',
  P: '\u0500',
  Q: '\u038C',
  R: '\u1D1A',
  T: '\u22A5',
  U: '\u2229',
  V: '\u1D27',
  Y: '\u2144',
  '\u005B': '\u005D',
  _: '\u203E',
  a: '\u0250',
  b: '\u0071',
  c: '\u0254',
  d: '\u0070',
  e: '\u01DD',
  f: '\u025F',
  g: '\u0183',
  h: '\u0265',
  i: '\u0131',
  j: '\u027E',
  k: '\u029E',
  l: '\u0283',
  m: '\u026F',
  n: '\u0075',
  r: '\u0279',
  t: '\u0287',
  v: '\u028C',
  w: '\u028D',
  y: '\u028E',
  '\u007B': '\u007D',
  '\u203F': '\u2040',
  '\u2045': '\u2046',
  '\u2234': '\u2235',
};
*/
