import angular from 'angular';

(() => {
  angular.module('app').controller('HwtsNetworkCtrl', [
    'MetaTagsUtil',
    (MetaTagsUtil) => {
      MetaTagsUtil.SetSocialMetaTags({
        title: 'The HWTS Network',
        ogImage: 'images/network/hwts_network_social.jpg',
        ogImageWidth: 500,
        ogImageHeight: 250,
        description:
          'The Household Water Treatment and Safe Storage (HWTS) Network is a global alliance working to scale up the use of HWTS technologies to improve water quality.',
        ogTitle: 'The HWTS Network',
        ogDescription:
          'The Household Water Treatment and Safe Storage (HWTS) Network is a global alliance working to scale up the use of HWTS technologies to improve water quality.',
        ogUrl: 'network',
      });
    },
  ]);
})();
