
import Vue from 'vue';

export default Vue.extend({
  name: 'SoundCloudPlayer',
  props: {
    embedUrl: {
      type: String,
      required: true,
    },
    height: {
      type: [Number, String],
      default: 300,
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
  },
  computed: {
    style(): { height: string; width: string } {
      const formatStyleMeasure = (measure: string | number): string => (typeof measure === 'number' ? `${measure}px` : measure);

      return {
        height: formatStyleMeasure(this.height),
        width: formatStyleMeasure(this.width),
      };
    },
  },
});
