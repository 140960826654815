var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper network no-translate" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.browsingLanguage === "en"
      ? _c("div", { staticClass: "container content-md" }, [
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.browsingLanguage === "es"
      ? _c("div", { staticClass: "container content-md" }, [
          _vm._m(4),
          _vm._v(" "),
          _vm._m(5),
          _vm._v(" "),
          _vm._m(6),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.browsingLanguage === "fr"
      ? _c("div", { staticClass: "container content-md" }, [
          _vm._m(7),
          _vm._v(" "),
          _vm._m(8),
          _vm._v(" "),
          _vm._m(9),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "breadcrumbs-v3 cawst-hero-banner img-home",
        staticStyle: {
          "min-height": "240px",
          "background-image":
            "url('https://res.cloudinary.com/cawst/image/upload/v1670958738/Web/HWTS/Sanitary-Inspection-Form/HWTS-Sanitary-Inspection-Forms-BG.jpg')",
        },
        attrs: { name: "Hero" },
      },
      [
        _c(
          "div",
          {
            staticClass: "container content-md padding-bottom-0 padding-top-5",
          },
          [
            _c("div", { staticClass: "col-md-10 col-md-offset-2" }, [
              _c("h1", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("\n          HWTS Network"),
                _c("br"),
                _c("span", { staticStyle: { "text-transform": "none" } }, [
                  _vm._v("Sanitary Inspection Forms"),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-8 col-md-offset-2" }, [
        _c("p", { staticClass: "lead" }, [
          _vm._v(
            "Assessing your drinking water quality can be expensive and complicated. It doesn't have to be, with sanitary inspection forms. They use standardized scores to help you assess risk and select the right technology, all without the need for specialized equipment or technicians."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("We're working with advisors at the "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.cawst.org/?utm_source=Referral&utm_medium=Web&utm_campaign=SanitaryInspection",
                target: "_blank",
              },
            },
            [
              _vm._v(
                "Centre for Affordable Water and Sanitation Technology (CAWST)"
              ),
            ]
          ),
          _vm._v(" and researchers at the "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.surrey.ac.uk/department-civil-environmental-engineering/research/centre-environmental-health-and-engineering",
                target: "_blank",
              },
            },
            [_vm._v("University of Surrey")]
          ),
          _vm._v(
            " to develop forms that can be used by community representatives, health workers, water inspectors and humanitarian professionals. Your feedback will help us to ensure that they are truly universal!"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row margin-top-30" }, [
      _c(
        "div",
        {
          staticClass: "col-md-12",
          staticStyle: { "padding-left": "0", "padding-right": "0" },
        },
        [
          _c("div", { staticClass: "tag-box tag-box-v1" }, [
            _c("h2", [
              _vm._v("Please review the draft sanitary inspection forms for "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://ncv.microsoft.com/ykOkR3uM0q",
                    target: "_blank",
                  },
                },
                [_vm._v("boiling")]
              ),
              _vm._v(" and "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://ncv.microsoft.com/U1XpBz9fGE",
                    target: "_blank",
                  },
                },
                [_vm._v("household slow sand filtration")]
              ),
              _vm._v("."),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Each form includes 10 - 17 questions covering various potential risks that could make drinking water unsafe. They're designed to measure knowledge, practice and behaviour. We need your feedback on the questions, for example:"
              ),
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [_vm._v("Do they apply to your context?")]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "Do they clearly capture a major risk to water quality in your experience?"
                ),
              ]),
              _vm._v(" "),
              _c("li", [_vm._v("Are they clearly worded?")]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Are the suggested actions clear and understandable?"),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Is anything missing, or should anything be removed?"),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Your review of each form should take approximately 15 minutes."
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row margin-top-30" }, [
      _c(
        "div",
        { staticClass: "col-md-6", staticStyle: { "padding-left": "0" } },
        [
          _c("div", { staticClass: "tag-box tag-box-v1" }, [
            _c("h2", [
              _vm._v("Household water treatment: "),
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://ncv.microsoft.com/ykOkR3uM0q",
                      target: "_blank",
                      title: "Boiling",
                    },
                  },
                  [_vm._v("Boiling")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://res.cloudinary.com/cawst/image/upload/v1675186585/Web/HWTS/Sanitary-Inspection-Form/EN/Household-water-treatment-Inspection-package-boiling-EN-v2.pdf",
                      target: "_blank",
                      download: "Boiling-full-inspection-package.pdf",
                    },
                  },
                  [_vm._v("Download the full inspection package for boiling")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675186758/Web/HWTS/Sanitary-Inspection-Form/EN/Household-water-treatment-Management-advice-sheet-boiling-EN-v2.pdf",
                    target: "_blank",
                    download: "Boiling-management-advice-sheet.pdf",
                  },
                },
                [_vm._v("Download the management advice sheet")]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675186854/Web/HWTS/Sanitary-Inspection-Form/EN/Household-water-treatment-Technical-fact-sheet-boiling-EN-v2.pdf",
                    target: "_blank",
                    download: "Boiling-technical-fact-sheet.pdf",
                  },
                },
                [_vm._v("Download the technical fact sheet")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6", staticStyle: { "padding-right": "0" } },
        [
          _c("div", { staticClass: "tag-box tag-box-v1" }, [
            _c("h2", [
              _vm._v("Household water treatment: "),
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://ncv.microsoft.com/U1XpBz9fGE",
                      target: "_blank",
                      title: "Household Slow Sand Filtration",
                    },
                  },
                  [_vm._v("Household Slow Sand Filtration")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://res.cloudinary.com/cawst/image/upload/v1675187146/Web/HWTS/Sanitary-Inspection-Form/EN/Household-water-treatment-Inspection-package-HSSF-EN-v2.pdf",
                      target: "_blank",
                      download:
                        "Household-Slow-Sand-Filtration-full-package.pdf",
                    },
                  },
                  [
                    _vm._v(
                      "Download the full inspection package for household slow sand filtration"
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675187241/Web/HWTS/Sanitary-Inspection-Form/EN/Household-water-treatment-Management-advice-sheet-HSSF-EN-v2.pdf",
                    target: "_blank",
                    download:
                      "Household-Slow-Sand-Filtration-management-advice-sheet.pdf",
                  },
                },
                [_vm._v("Download the management advice sheet")]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675187295/Web/HWTS/Sanitary-Inspection-Form/EN/Household-water-treatment-Technical-fact-sheet-HSSF-EN-v2.pdf",
                    target: "_blank",
                    download:
                      "Household-Slow-Sand-Filtration-technical-fact-sheet.pdf",
                  },
                },
                [_vm._v("Download the technical fact sheet")]
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-8 col-md-offset-2" }, [
        _c("p", { staticClass: "lead cawst-translation-singleton" }, [
          _vm._v(
            "Evaluar la calidad del agua de consumo puede ser costoso y complicado. Con los formularios de inspección sanitaria, no tiene por qué serlo.  Los formularios de inspección sanitaria son herramientas sencillas, económicas, prácticas y efectivas para evaluar la calidad del agua de consumo sin que sea necesario recurrir a equipos especializados o técnicos. Son particularmente útiles en regiones con recursos son limitados."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.surrey.ac.uk/department-civil-environmental-engineering/research/centre-environmental-health-and-engineering",
                target: "_blank",
              },
            },
            [_vm._v("La Universidad de Surrey")]
          ),
          _vm._v(" y el "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.cawst.org/?utm_source=Referral&utm_medium=Web&utm_campaign=SanitaryInspection",
                target: "_blank",
              },
            },
            [
              _vm._v(
                "Centro de Tecnologías Asequibles de Agua y Saneamiento (CAWST)"
              ),
            ]
          ),
          _vm._v(
            " crearon un nuevo paquete de formularios de inspección sanitaria que puede ayudarlo a monitorear y evaluar el conocimiento, las prácticas y el comportamiento en torno al agua de consumo a nivel domiciliario y comunitario. Utilizan un conjunto estándar de preguntas y recomendaciones para ayudarlo a garantizar un uso correcto, consistente y continuo de las tecnologías de tratamiento de agua a nivel domiciliario."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row margin-top-30" }, [
      _c("div", { staticClass: "tag-box tag-box-v1" }, [
        _c("p", [
          _vm._v(
            "Vea el borrador del formulario de inspección sanitaria aquí, para "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://ncv.microsoft.com/uezL4poIsM)",
                target: "_blank",
              },
            },
            [_vm._v("hervido")]
          ),
          _vm._v(" y "),
          _c(
            "a",
            {
              attrs: {
                href: "https://ncv.microsoft.com/l3fInj9l2j",
                target: "_blank",
              },
            },
            [_vm._v("filtración con filtro lento de arena de uso doméstico")]
          ),
          _vm._v(
            ".  Incluye de 10 a 17 preguntas que abarcan los distintos riesgos posibles que podrían afectar al agua de consumo y evitar que sea segura. Necesitamos saber qué opina de las preguntas, por ejemplo:"
          ),
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("¿Se aplican a su contexto?")]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Según su experiencia, ¿reflejan un riesgo importante para la calidad del agua?"
            ),
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("¿Están bien redactadas?")]),
          _vm._v(" "),
          _c("li", [
            _vm._v("¿Las acciones sugeridas son claras y se entienden?"),
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("¿Falta o debería quitarse algo?")]),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Su revisión debería tomarle 15 minutos aproximadamente."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row margin-top-30" }, [
      _c(
        "div",
        { staticClass: "col-md-6", staticStyle: { "padding-left": "0" } },
        [
          _c("div", { staticClass: "tag-box tag-box-v1" }, [
            _c("h2", [
              _vm._v("Tratamiento de agua a nivel domiciliario: "),
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://ncv.microsoft.com/uezL4poIsM)",
                      target: "_blank",
                      title: "Hervido",
                    },
                  },
                  [_vm._v("Hervido")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://res.cloudinary.com/cawst/image/upload/v1675195877/Web/HWTS/Sanitary-Inspection-Form/ES/Household-water-treatment-Inspection-package-boiling-ES-v2.pdf",
                      target: "_blank",
                      download: "Hervido-paquete-completo-de-inspección.pdf",
                    },
                  },
                  [_vm._v("Descargue el paquete completo de inspección")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675195941/Web/HWTS/Sanitary-Inspection-Form/ES/Household-water-treatment-Management-advice-sheet-boiling-ES-v2.pdf",
                    target: "_blank",
                    download: "Hervido-hoja-de-recomendaciones-de-gestión.pdf",
                  },
                },
                [_vm._v("Descargue la hoja de recomendaciones de gestión")]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675196020/Web/HWTS/Sanitary-Inspection-Form/ES/Household-water-treatment-Technical-fact-sheet-boiling-ES-v2.pdf",
                    target: "_blank",
                    download: "Hervido-hoja-de-datos-técnicos.pdf",
                  },
                },
                [_vm._v("Descargue la hoja de datos técnicos")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6", staticStyle: { "padding-right": "0" } },
        [
          _c("div", { staticClass: "tag-box tag-box-v1" }, [
            _c("h2", [
              _vm._v("Tratamiento de agua a nivel domiciliario: "),
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://ncv.microsoft.com/l3fInj9l2j",
                      target: "_blank",
                      title:
                        "Filtración con filtro lento de arena de uso doméstico",
                    },
                  },
                  [
                    _vm._v(
                      "Filtración con filtro lento de arena de uso doméstico"
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://res.cloudinary.com/cawst/image/upload/v1675196231/Web/HWTS/Sanitary-Inspection-Form/ES/Household-water-treatment-Inspection-package-HSSF-ES-v2.pdf",
                      target: "_blank",
                      download:
                        "Filtración-con-filtro-lento-de-arena-de-uso-doméstico-paquete-completo-de-inspección.pdf",
                    },
                  },
                  [_vm._v("Descargue el paquete completo de inspección")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675196541/Web/HWTS/Sanitary-Inspection-Form/ES/Household-water-treatment-Management-advice-sheet-HSSF-ES-v2.pdf",
                    target: "_blank",
                    download:
                      "Filtración-con-filtro-lento-de-arena-de-uso-doméstico-paquete-recomendaciones-de-gestión.pdf",
                  },
                },
                [_vm._v("Descargue la hoja de recomendaciones de gestión")]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675196652/Web/HWTS/Sanitary-Inspection-Form/ES/Household-water-treatment-Technical-fact-sheet-HSSF-ES-v2.pdf",
                    target: "_blank",
                    download:
                      "Filtración-con-filtro-lento-de-arena-de-uso-doméstico-paquete-datos-técnicos.pdf",
                  },
                },
                [_vm._v("Descargue la hoja de datos técnicos")]
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-8 col-md-offset-2" }, [
        _c("p", { staticClass: "lead cawst-translation-singleton" }, [
          _vm._v(
            "L'évaluation de la qualité de l'eau de boisson peut être coûteuse et complexe. Ce n'est plus une fatalité grâce aux formulaires d'inspection sanitaire. Les formulaires d'inspection sanitaire sont un outil simple, peu coûteux et pratique d'évaluation de la qualité de votre eau de boisson — sans avoir besoin de recourir à du matériel ou des techniciens spécialisés. Ils sont particulièrement utiles dans les régions où les ressources sont limitées."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.surrey.ac.uk/department-civil-environmental-engineering/research/centre-environmental-health-and-engineering",
                target: "_blank",
              },
            },
            [_vm._v("L'Université de Surrey")]
          ),
          _vm._v(" et "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.cawst.org/?utm_source=Referral&utm_medium=Web&utm_campaign=SanitaryInspection",
                target: "_blank",
              },
            },
            [
              _vm._v(
                "CAWST (Centre for Affordable Water and Sanitation Technology)"
              ),
            ]
          ),
          _vm._v(
            " ont élaboré un nouvel ensemble de formulaires d'inspection sanitaire qui peut vous aider à contrôler et évaluer les connaissances, pratiques et comportements liés à l'eau de boisson chez vous et dans votre communauté. Ils font appel à une série standardisée de questions et de recommandations pour vous aider à garantir l'utilisation correcte, constante et continue des technologies de traitement de l'eau à domicile."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row margin-top-30" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "tag-box tag-box-v1" }, [
          _c("p", [
            _vm._v(
              "Veuillez réviser l'ébauche de formulaire d'inspection sanitaire ici, pour "
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://ncv.microsoft.com/nzjJNr9pIK",
                  target: "_blank",
                },
              },
              [_vm._v("ébullition")]
            ),
            _vm._v(" et "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://ncv.microsoft.com/r3zq4csbow",
                  target: "_blank",
                },
              },
              [_vm._v("filtration lente sur sable à domicile")]
            ),
            _vm._v(
              ".  Il contient 10 à 17 questions traitant différents risques pouvant affecter l'eau de boisson et la rendre insalubre. Nous avons besoin de votre avis sur les questions, par exemple :"
            ),
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v("Sont-elles applicables à votre contexte ?")]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Visent-elles clairement un risque majeur pour la qualité de l'eau dont vous avez fait l'expérience ?"
              ),
            ]),
            _vm._v(" "),
            _c("li", [_vm._v("Sont-elles clairement formulées ?")]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Les actions suggérées sont-elles claires et compréhensibles ?"
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Y a-t-il des éléments manquants ou devant être supprimés ?"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("La revue devrait vous prendre environ 15 minutes."),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row margin-top-30" }, [
      _c(
        "div",
        { staticClass: "col-md-6", staticStyle: { "padding-left": "0" } },
        [
          _c("div", { staticClass: "tag-box tag-box-v1" }, [
            _c("h2", [
              _vm._v("Traitement de l'eau à domicile: "),
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://ncv.microsoft.com/nzjJNr9pIK",
                      target: "_blank",
                      title: "Ébullition",
                    },
                  },
                  [_vm._v("Ébullition")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://res.cloudinary.com/cawst/image/upload/v1675188921/Web/HWTS/Sanitary-Inspection-Form/FR/Household-water-treatment-Inspection-package-boiling-FR-v2.pdf",
                      target: "_blank",
                      download: "Ébullition-package-d'inspection-complet.pdf",
                    },
                  },
                  [_vm._v("Télécharger le package d'inspection complet")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675189035/Web/HWTS/Sanitary-Inspection-Form/FR/Household-water-treatment-Management-advice-sheet-boiling-FR-v2.pdf",
                    target: "_blank",
                    download: "Ébullition-fiche-de-conseils-de-gestion.pdf",
                  },
                },
                [_vm._v("Télécharger la fiche de conseils de gestion")]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675189313/Web/HWTS/Sanitary-Inspection-Form/FR/Household-water-treatment-Technical-fact-sheet-boiling-FR-v2.pdf",
                    target: "_blank",
                    download: "Ébullition-fiche-technique.pdf",
                  },
                },
                [_vm._v("Télécharger la fiche technique")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6", staticStyle: { "padding-right": "0" } },
        [
          _c("div", { staticClass: "tag-box tag-box-v1" }, [
            _c("h2", [
              _vm._v("Traitement de l'eau à domicile: "),
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://ncv.microsoft.com/r3zq4csbow",
                      target: "_blank",
                      title: "Filtration lente sur sable à domicile",
                    },
                  },
                  [_vm._v("Filtration lente sur sable à domicile")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://res.cloudinary.com/cawst/image/upload/v1675190475/Web/HWTS/Sanitary-Inspection-Form/FR/Household-water-treatment-Inspection-package-HSSF-FR-v2.pdf",
                      target: "_blank",
                      download:
                        "Filtration-lente-sur-sable-à-domicile-package-d'inspection-complet.pdf",
                    },
                  },
                  [_vm._v("Télécharger le package d'inspection complet")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675190695/Web/HWTS/Sanitary-Inspection-Form/FR/Household-water-treatment-Management-advice-sheet-HSSF-FR-v2.pdf",
                    target: "_blank",
                    download:
                      "Filtration-lente-sur-sable-fiche-de-conseils-de-gestion.pdf",
                  },
                },
                [_vm._v("Télécharger la fiche de conseils de gestion")]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://res.cloudinary.com/cawst/image/upload/v1675190877/Web/HWTS/Sanitary-Inspection-Form/FR/Household-water-treatment-Technical-fact-sheet-HSSF-FR-v2.pdf",
                    target: "_blank",
                    download:
                      "Filtration-lente-sur-sable-à-domicile-fiche-technique.pdf",
                  },
                },
                [_vm._v("Télécharger la fiche technique")]
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }