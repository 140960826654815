
import Vue from 'vue';

import goToStateMixin from '@src/client/vue-app/mixins/goToStateMixin';
import {
  contaminantEffectiveToken,
  contaminantVeryEffectiveToken,
} from '@src/client/vue-app/filters/data';
import {
  defaultMessage,
  formatRateDataMessage,
} from '@src/client/vue-app/util/formatRateDataMessage';
import ProdTechResultsListItemMbaList from './ProdTechResultsListItemMbaList.vue';
import ProdTechImage from './ProdTechImage.vue';
import ProdTechResultsListItemActions from './ProdTechResultsListItemActions.vue';
import ProgressionBar from './ProgressionBar.vue';

const desktopImgSize = '251px';
const tabletImgSize = '159px';

export default Vue.extend({
  name: 'ProdTechResultsListItem',
  components: {
    ProdTechResultsListItemMbaList,
    ProdTechImage,
    ProdTechResultsListItemActions,
    ProgressionBar,
  },
  mixins: [goToStateMixin],
  props: {
    prodTech: {
      // FIXME: Apply appropriate typing once required field types are known
      type: Object,
      required: true,
    },
  },
  data: () => ({ defaultMessage, width: 0, height: 0 }),
  computed: {
    barriers(): string[] {
      if (
        !this.prodTech.technicalInformation
        || !this.prodTech.technicalInformation.treatmentProcess
      ) {
        return [];
      }

      return this.prodTech.technicalInformation.treatmentProcess;
    },
    flowRateMessage(): string {
      if (!this.prodTech.technicalInformation || !this.prodTech.technicalInformation.flowRate) {
        return defaultMessage;
      }

      return formatRateDataMessage(this.prodTech.technicalInformation.flowRate);
    },
    parentTechnologies(): string[] {
      if (!this.prodTech.prodtech) {
        return [];
      }

      return this.prodTech.prodtech.map(({ title }) => title);
    },
    targetContaminants(): string[] {
      if (
        !this.prodTech.technicalInformation
        || !this.prodTech.technicalInformation.treatmentCapacity
      ) {
        return [];
      }

      return this.prodTech.technicalInformation.treatmentCapacity
        .filter(({ effectiveness }) => (
          effectiveness === contaminantVeryEffectiveToken
            || effectiveness === contaminantEffectiveToken
        ))
        .map(({ parameter }) => parameter);
    },
    timeToTreatMessage(): string {
      if (!this.prodTech.technicalInformation || !this.prodTech.technicalInformation.timeToTreat) {
        return defaultMessage;
      }

      return formatRateDataMessage(this.prodTech.technicalInformation.timeToTreat);
    },
    isDesktopOrAbove(): boolean {
      return this.width >= 992 - 60;
    },
    isTabletOrAbove(): boolean {
      return this.width >= 450 - 60;
    },
    imgSize(): string {
      if (this.isDesktopOrAbove) {
        return desktopImgSize;
      }

      if (this.isTabletOrAbove) {
        return tabletImgSize;
      }

      return `${this.width - 40}px`;
    },
    progression(): string {
      let currentLabel = 'Emerging';

      if (this.prodTech && this.prodTech.technicalInformation) {
        currentLabel = this.prodTech.technicalInformation.progression || 'Emerging';
      }

      return currentLabel;
    },
  },
  mounted() {
    this.width = this.$el.offsetWidth;
    this.height = this.$el.offsetHeight;
  },
  methods: {
    onProgressionClicked() {
      this.$emit('open-progression-details');
    },
    onResize({ width, height }) {
      this.width = width;
      this.height = height;
    },
  },
});
