/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/camelcase */

import angular from 'angular';

import { deploymentConfig } from '@src/config/deploymentConfig';
import { eventBus } from '@src/client/vue-app/event-bus';
import { AngularJsRouterState } from '@src/client/vue-app/types/AngularJsRouterState';

const app = angular.module('app');
// ----------
// -- Environment variables
app.run([
  '$rootScope',
  '$state',
  '$window',
  function ($rootScope, $state, $window) {
    $rootScope.api_url = deploymentConfig.api.appUrl;
    $rootScope.auth0_client_id = deploymentConfig.auth0.clientId;
    $rootScope.auth0_domain = deploymentConfig.auth0.domain;
    $rootScope.image_serve_url = deploymentConfig.resources.imageBaseUrl;
    $window.states = $state.get();
  },
]);

// ----------
// -- State Change Success
app.run([
  '$location',
  '$rootScope',
  '$window',
  'MetaTagsUtil',
  'store',
  function ($location, $rootScope, $window, MetaTagsUtil, store) {
    $rootScope.$on('$stateChangeSuccess', function (
      _event,
      toState,
      _toParams,
      fromState,
      _fromParams,
    ) {
      // to make variables available
      $rootScope.fromStateName = fromState.name;
      $rootScope.toStateName = toState.name;

      // add form redirect path to store, bug fix for HWTS-119
      function setNextPageAfterFormSubmit() {
        const toStateIsForm = toState.name === 'form';
        const fromStateExists = fromState.name && fromState.name.length > 0;
        const fromStateIsNotForm = $rootScope.fromStateName !== 'form';

        if (toStateIsForm && fromStateExists && fromStateIsNotForm) {
          store.set('nextPageAfterFormSubmit', fromState.name);
        }
      }
      setNextPageAfterFormSubmit();

      $window.scrollTo(0, 0);

      // set MetaTagsUtil on state change
      MetaTagsUtil.stateChangedMetaTags();

      // google analytics
      window.gtag('config', window.appSettings.google.analyticsId, {
        page_path: $location.url(),
      });
    });

    // translation
    if (window.Translation) {
      $rootScope.translate = function (str) {
        return window.Translation.translate(str);
      };
    }
  },
]);
// ----------
// -- Set global language variable
app.run([
  '$document',
  '$rootScope',
  function ($document, $rootScope) {
    $rootScope.browsingLanguage = '';

    /* Given a string and a regex containing a capture group, find the first match and return the
         content captured in that match. */
    function getFirstMatch(stringToParse: string, pattern: RegExp): string {
      const firstMatch = stringToParse.match(pattern);
      if (firstMatch) {
        const [, capturedContent] = firstMatch;
        return capturedContent;
      }
      return '';
    }
    const defaultLanguage = 'en';
    const supportedLanguages = ['en', 'es', 'fr'];
    const desiredLanguage = getFirstMatch($document[0].cookie, /l=([a-z]{2})/);
    $rootScope.browsingLanguage = supportedLanguages.includes(desiredLanguage)
      ? desiredLanguage
      : defaultLanguage;
  },
]);

// --------
// -- Vue to Angular State Changes
app.run([
  '$state',
  function ($state) {
    eventBus.$on('ng:stateChange', ({ stateName, params, config }: AngularJsRouterState) => {
      $state.go(stateName, params, config);
    });
  },
]);
