import { Lesson } from '@src/client/vue-app/types/Lesson';
import { LessonGroup } from '@src/client/vue-app/types/LessonGroup';

import { learnLessonGroups } from '@src/client/vue-app/data/learn-lesson-groups';
import { learnLessons } from '@src/client/vue-app/data/learn-lessons';
import { getLessonResourceUrl } from '@src/client/vue-app/util/getLessonResourceUrl';

// Using the Vue pattern for a "service", since that is just a simple class.
//   AngularJS services require additional infrastructure and cannot be
//   accessed inside of ngVue components easily.
export default class LessonService {
  private readonly lessonGroups: LessonGroup[];
  private readonly lessons: Lesson[];

  constructor(lessons: Lesson[] = learnLessons, lessonGroups: LessonGroup[] = learnLessonGroups) {
    this.lessonGroups = lessonGroups;
    this.lessons = lessons.slice().sort((a, b) => a.order - b.order);
  }

  public getLessonById(lessonId: string): Lesson | null {
    const foundLesson = this.lessons.find((lesson) => lesson.id === lessonId);
    return foundLesson || null;
  }

  public getLessonGroupBySlug(groupSlug: string): LessonGroup | null {
    const foundGroup = this.lessonGroups.find((group) => group.slug === groupSlug);
    return foundGroup || null;
  }

  public getLessonGroups(): LessonGroup[] {
    return this.lessonGroups;
  }

  public getLessonResourceUrl(lessonId: string, locale: string): string {
    const foundLesson = this.getLessonById(lessonId);
    return foundLesson ? getLessonResourceUrl(foundLesson, locale) : '';
  }

  public getLessons(): Lesson[] {
    return this.lessons;
  }

  public getLessonsByGroupSlug(groupSlug?: string): Lesson[] {
    if (!groupSlug) {
      return this.lessons;
    }

    return this.lessons.filter((lesson) => lesson.groupSlugs.includes(groupSlug));
  }

  public getNextLesson(lessonId: string): Lesson | null {
    if (!this.hasNextLesson(lessonId)) {
      return null;
    }

    const lessonIndex = this.lessons.findIndex((lesson) => lesson.id === lessonId);
    return this.lessons[lessonIndex + 1];
  }

  public getPreviousLesson(lessonId: string): Lesson | null {
    if (!this.hasPreviousLesson(lessonId)) {
      return null;
    }

    const lessonIndex = this.lessons.findIndex((lesson) => lesson.id === lessonId);
    return this.lessons[lessonIndex - 1];
  }

  public hasNextLesson(lessonId: string): boolean {
    const lessonIndex = this.lessons.findIndex((lesson) => lesson.id === lessonId);
    return lessonIndex >= 0 && lessonIndex < this.lessons.length - 1;
  }

  public hasPreviousLesson(lessonId: string): boolean {
    const lessonIndex = this.lessons.findIndex((lesson) => lesson.id === lessonId);
    return lessonIndex > 0;
  }
}
