import angular from 'angular';
import 'ngVue';
import Vue from 'vue';
import VueResizeObserver from 'vue-resize-observer';

import BaseButton from './components/BaseButton.vue';
import BaseLabel from './components/BaseLabel.vue';
import Hero from './components/Hero.vue';
import LearnBetaAlert from './components/LearnBetaAlert.vue';
import LearnHomeHeading from './components/LearnHomeHeading.vue';
import LearnHomeTabContent from './components/LearnHomeTabContent.vue';
import MainSectionCardList from './components/MainSectionCardList.vue';
import ProdTechResultsList from './components/ProdTechResultsList.vue';
import ProdTechSearchBar from './components/ProdTechSearchBar.vue';
import ProdTechSidebarSelect from './components/ProdTechSidebarSelect.vue';
import LearnLesson from './views/LearnLesson.vue';
import SanitaryInspectionFormsPage from './views/SanitaryInspectionFormsPage.vue';

Vue.component('base-btn', BaseButton);
Vue.component('base-label', BaseLabel);

Vue.use(VueResizeObserver);

angular //
  .module('vue.components', ['ngVue'])
  .value('Hero', Hero)
  .value('LearnBetaAlert', LearnBetaAlert)
  .value('LearnHomeHeading', LearnHomeHeading)
  .value('LearnHomeTabContent', LearnHomeTabContent)
  .value('LearnLesson', LearnLesson)
  .value('MainSectionCardList', MainSectionCardList)
  .value('ProdTechResultsList', ProdTechResultsList)
  .value('ProdTechSearchBar', ProdTechSearchBar)
  .value('ProdTechSidebarSelect', ProdTechSidebarSelect)
  .value('SanitaryInspectionFormsPage', SanitaryInspectionFormsPage);
