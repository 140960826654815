/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */

(function() {
  angular.module('app.directives').directive('contentSection', [
    '$state',
    'GoogleMapUtil',
    function($state, GoogleMapUtil) {
      // ///////////////////////////////////////////////////////////////////////
      const sectionsWithoutListLimit = ['prodtech', 'government', 'keyitems', 'project'];
      // ///////////////////////////////////////////////////////////////////////
      const nonGovernmentDocuments = [
        'document',
        'manual',
        'website',
        'evaluation',
        'technical_update',
        'implementation_update',
        'manufacturer_sheet',
        'faq',
      ];
      // ///////////////////////////////////////////////////////////////////////
      function setListLimit(sectionId, sectionListLimit) {
        // set number of items to show in the list, default is 3
        let limit = 3;

        if (sectionsWithoutListLimit.indexOf(sectionId) > -1) {
          limit = 10;
          // sections without a limit will have a limit of 10 so the list doesn't
          // get too long in the UI
        } else if (sectionListLimit) {
          limit = sectionListLimit;
          // if a list limit is set on the directive then use that limit
        }

        return limit;
      }
      // ///////////////////////////////////////////////////////////////////////
      function setViewMoreUrl(sectionId, filterBy, filterByValue) {
        // set the url to the search page to view more resources
        let url = '/search?page=1';

        if (sectionId && sectionId !== 'keyitems') {
          if (sectionId === 'document') {
            url += `&resourceTypes=${nonGovernmentDocuments.join(';')}`;
          } else {
            url += '&resourceTypes=' + sectionId;
          }
        }

        if (filterBy === 'product') {
          url += '&products=' + filterByValue;
        }

        if (filterBy === 'technology') {
          url += '&technologies=' + filterByValue;
        }

        if (filterBy === 'country') {
          url += '&countries=' + filterByValue;
        }

        return url;
      }
      // ///////////////////////////////////////////////////////////////////////
      function setTotalItemsMessage(sectionId, itemsLength, listLimit) {
        if (!sectionId || !itemsLength || !listLimit) {
          return '';
        }

        let message = '';

        if (sectionsWithoutListLimit.indexOf(sectionId) > -1) {
          message = window.hasOwnProperty('Translation')
            ? Translation.translate('Showing %s', [itemsLength])
            : 'Showing ' + itemsLength;
        } else if (itemsLength < listLimit) {
          message = window.hasOwnProperty('Translation')
            ? Translation.translate('Showing %s of %s', [itemsLength, itemsLength])
            : 'Showing ' + itemsLength + ' of ' + itemsLength;
        } else {
          message = window.hasOwnProperty('Translation')
            ? Translation.translate('Showing %s of %s', [listLimit, itemsLength])
            : 'Showing ' + listLimit + ' of ' + itemsLength;
        }

        return message;
      }
      // ///////////////////////////////////////////////////////////////////////
      return {
        restrict: 'E',
        templateUrl: 'scripts/directives/content_section.html',
        scope: {
          // section details
          section: '=',
          sectionListLimit: '=', // optional, default is 3
          // filters for the view more url
          filterBy: '=',
          filterByValue: '=',
          // project map
          mapCenter: '=',
          loadingProjects: '=', // optional
        },
        link: function(scope, element, attr) {
          // Set the url for the View More button
          const filterByValue = scope.filterByValue ? encodeURIComponent(scope.filterByValue) : '';
          scope.viewMoreUrl = setViewMoreUrl(scope.section.id, scope.filterBy, filterByValue);

          // Set the list limit for the section
          const sectionListLimit = scope.sectionListLimit ? scope.sectionListLimit : null;
          scope.listLimit = setListLimit(scope.section.id, sectionListLimit);

          // Set the message displaying the total items showing (i.e. Showing # of #)
          scope.section.totalItemsMessage = setTotalItemsMessage(
            scope.section.id,
            scope.section.items.length,
            scope.listLimit,
          );

          // Indicate whether to show the "View More" button or not
          scope.showViewMore = (itemsLength, listLimit) => itemsLength > listLimit;

          // Set resource type to send to search item type directive
          scope.searchItemType = (sectionId, itemType) => {
            var type = sectionId ? sectionId : '';
            if (sectionId === 'keyitems' && itemType) {
              type = itemType;
            }
            if (sectionId === 'government') {
              type = 'document';
            }
            return type;
          };

          // Project map
          scope.showMap = function() {
            var show = true;
            if (scope.section.id !== 'project') {
              show = false;
            }
            if (scope.loadingProjects) {
              show = false;
            }
            if (scope.section.items.length <= 0) {
              show = false;
            }
            return show;
          };

          function projectsInit(type, page, countryName) {
            if (scope.section.id === 'project') {
              scope.control = {};
              scope.mapSettings = GoogleMapUtil.getMapSettings();

              if (scope.filterBy === 'country') {
                // scope.mapSettings.map.center = scope.mapCenter;
                scope.mapSettings.map.zoom = 6;
              } else {
                scope.mapSettings.map.zoom = 2;
              }

              var latitudes = [];
              var longitudes = [];

              scope.section.items.forEach(function(project) {
                if (project.latitude) {
                  latitudes.push(project.latitude);
                }

                if (project.longitude) {
                  longitudes.push(project.longitude);
                }
              });

              scope.mapSettings.map.center = {
                latitude: _.mean(latitudes),
                longitude: _.mean(longitudes),
              };

              scope.mapSettings.mapEvents = {
                zoom_changed: function(map) {
                  var zoomLevel = map.getZoom();
                  if (zoomLevel >= 6) {
                    scope.mapSettings.typeOfMarker = 'spider';
                    scope.mapSettings.typeOptions = _.clone(scope.mapSettings.spiderfierOptions);
                  } else {
                    scope.mapSettings.typeOfMarker = 'cluster';
                    scope.mapSettings.typeOptions = _.clone(scope.mapSettings.clusterOptions);
                  }
                },
              };
            }
          }

          projectsInit();
          // //////////////////////////////////////////////////////////////////
        },
      };
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
