var render = function render(_c, _vm) {
  return _vm._m(0)
}
var staticRenderFns = [
  function (_c, _vm) {
    return _c("div", { staticClass: "banner bg-primary" }, [
      _c("div", { staticClass: "container banner__body" }, [
        _c("p", { staticClass: "text-center banner__body--label" }, [
          _vm._v("\n      Have questions or comments?\n    "),
        ]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "text-center margin-bottom-0 banner__body--label" },
          [
            _vm._v("\n      Contact us at\n      "),
            _c(
              "a",
              {
                staticClass: "text-center banner__body--link",
                attrs: { href: "mailto:support@hwts.info" },
              },
              [_vm._v("support@hwts.info")]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }