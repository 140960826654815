/* eslint-disable default-case */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable vars-on-top */

(function() {
  angular.module('app').controller('ContentPageCtrl', [
    '$http',
    '$rootScope',
    '$sce',
    '$scope',
    '$state',
    'HwtsUiUtil',
    'MetaTagsUtil',
    function($http, $rootScope, $sce, $scope, $state, HwtsUiUtil, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////
      $scope.getPublicPdfDownload = HwtsUiUtil.getPublicPdfDownload;
      // //////////////////////////////////////////////////////////////////////
      var contentId = $state.params.contentId ? $state.params.contentId : '';
      // //////////////////////////////////////////////////////////////////////
      $scope.contentPage = {};
      $scope.contentPage.loading = true;
      $scope.contentPage.contentType = $state.params.contentType ? $state.params.contentType : '';
      // //////////////////////////////////////////////////////////////////////
      function removeHtmlFromString(string) {
        var html = string;
        var div = document.createElement('div');
        var att = document.createAttribute('style');
        att.value = 'display: none';
        div.setAttributeNode(att);
        div.innerHTML = html;

        var cleanString = div.innerText;
        return cleanString;
      }

      function metaTagsContentLabel(details) {
        var label = details.type;
        if (
          details.hwtsItemType === 'hwtsResearch' &&
          details.publicationType &&
          details.publicationType.length > 0
        )
          label = details.publicationType;
        if (details.hwtsItemType === 'hwtsCaseStudy') label = 'case-study';
        if (details.hwtsItemType === 'hwtsFaq') label = 'FAQ';
        if (details.hwtsItemType === 'hwtsExperience') label = 'experience';
        if (HwtsUiUtil.contentTypeLabelMap.hasOwnProperty(label))
          label = HwtsUiUtil.contentTypeLabelMap[label];
        return label;
      }

      function metaTagsDescription(details) {
        var desc = ' ';
        if (details.description && details.description.length > 0)
          desc = removeHtmlFromString(details.description);
        return desc;
      }

      function metaTagsImage(contentType) {
        var img = null;
        switch (contentType) {
          case 'hwtsResearch':
            img = 'research';
            break;
          case 'hwtsCaseStudy':
            img = 'casestudy';
            break;
          case 'hwtsDocument':
            img = 'document';
            break;
          case 'hwtsExperience':
            img = 'experience';
            break;
          case 'hwtsFaq':
            img = 'faq';
            break;
        }
        return img ? 'images/social/hwts_social_media_' + img + '.jpg' : null;
      }

      function setMetaTags() {
        MetaTagsUtil.SetSocialMetaTags({
          ogTitle:
            metaTagsContentLabel($scope.contentPage.details) +
            ': ' +
            $rootScope.contentTitle +
            ' | Household Water Treatment and Safe Storage Knowledge Base',
          ogDescription: metaTagsDescription($scope.contentPage.details),
          ogUrl: $scope.contentPage.contentType + '/' + contentId + '/' + $state.params.contentSlug,
          ogImage: metaTagsImage($scope.contentPage.details.hwtsItemType),
        });
      }
      // //////////////////////////////////////////////////////////////////////
      $http
        .get($rootScope.api_url + '/public/' + $scope.contentPage.contentType + '/' + contentId)
        .then(
          function(response) {
            if (response && response.data) {
              $scope.contentPage.details = response.data;

              if ($scope.contentPage.contentType === 'document')
                $scope.contentPage.details.descriptionTrusted = $sce.trustAsHtml(
                  $scope.contentPage.details.description,
                );
              if ($scope.contentPage.contentType === 'research')
                $scope.contentPage.details.abstractTrusted = $sce.trustAsHtml(
                  $scope.contentPage.details.abstract,
                );

              $rootScope.contentTitle = _.truncate(response.data.title, {
                length: 75,
              });
              setMetaTags();
              if (!$scope.$$phase) {
                $scope.$apply();
              }
            } else {
              redirectToResourceNotFound();
            }
            $scope.contentPage.loading = false;
          },
          function() {
            /* Error */
            redirectToResourceNotFound();
          },
        );
      // //////////////////////////////////////////////////////////////////////

      function redirectToResourceNotFound() {
        $state.go('404');
      }
    },
  ]);
})();
