/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */

(function() {
  angular.module('app.services').factory('SweetAlertUtil', [
    '$q',
    function($q) {
      // http://t4t5.github.io/sweetalert/
      return {
        // //////////////////////////////////////////////////////////////////////
        alert: function(message, options) {
          return swal(
            angular.extend(
              {
                title: 'Alert',
                text: message,
                type: 'warning',
                showCancelButton: false,
              },
              options,
            ),
          );
        },
        // //////////////////////////////////////////////////////////////////////
        info: function(message, title, options) {
          return swal(
            angular.extend(
              {
                title: title,
                text: message,
                type: 'info',
              },
              options,
            ),
          );
        },
        // //////////////////////////////////////////////////////////////////////
        success: function(message, options) {
          return swal(
            angular.extend(
              {
                title: 'Success',
                text: message,
                type: 'success',
              },
              options,
            ),
          );
        },
        // //////////////////////////////////////////////////////////////////////
        error: function(message, options) {
          return swal(
            angular.extend(
              {
                title: 'Error',
                text: message,
                type: 'error',
              },
              options,
            ),
          );
        },
        // //////////////////////////////////////////////////////////////////////
        confirm: function(message, options) {
          var defered = $q.defer();
          var swalOptions = angular.extend(
            {
              title: 'Alert',
              text: message,
              type: 'warning',
              showCancelButton: true,
            },
            options,
          );
          swal(
            swalOptions,
            function(r) {
              defered.resolve(r);
            },
            function(e) {
              defered.reject(e);
            },
          );
          return defered.promise;
        },
        // //////////////////////////////////////////////////////////////////////
        prompt: function(message, placeholder, options) {
          var defered = $q.defer();
          var swalOptions = angular.extend(
            {
              title: 'Prompt',
              text: message,
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              animation: 'slide-from-top',
              inputPlaceholder: placeholder,
            },
            options,
          );
          swal(
            swalOptions,
            function(r) {
              if (r === '') {
                swal.showInputError('You need to write something!');
              } else {
                defered.resolve(r);
              }
            },
            function(e) {
              defered.reject(e);
            },
          );
          return defered.promise;
        },
        // //////////////////////////////////////////////////////////////////////
        alertPromise: function(message, title, type, options) {
          var defered = $q.defer();
          var swalOptions = angular.extend(
            {
              title: title,
              text: message,
              type: type,
              showCancelButton: false,
            },
            options,
          );
          swal(
            swalOptions,
            function(r) {
              defered.resolve(r);
            },
            function(e) {
              defered.reject(e);
            },
          );
          return defered.promise;
        },
        // //////////////////////////////////////////////////////////////////////
      };
    },
  ]);
})();
