var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "col-md-4 content-boxes-v7" }, [
    _c("i", {
      staticClass: "fa pull-left",
      class: [_vm.cardData.icon, _vm.iconFormattingClass],
    }),
    _vm._v(" "),
    _c("div", { staticClass: "content-boxes-in-v7" }, [
      _c("h3", [
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                return _vm.goToState(_vm.cardData.state)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.cardData.title) + "\n      ")]
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n      " + _vm._s(_vm.cardData.description) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }