
import Vue from 'vue';

import type { Lesson } from '@src/client/vue-app/types/Lesson';

import { defaultLocale } from '@src/client/vue-app/config';

import LessonListItem from './LessonListItem.vue';

export default Vue.extend({
  name: 'LessonList',
  components: {
    LessonListItem,
  },
  props: {
    lessons: {
      type: Array as () => Array<Lesson>,
      default: () => [],
    },
    locale: {
      type: String,
      default: defaultLocale,
    },
  },
});
