/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */

(function() {
  angular.module('app').controller('FormsCtrl', [
    '$scope',
    '$state',
    'FormUtil',
    'GoogleMapUtil',
    'HwtsUiUtil',
    'SweetAlertUtil',
    'User',
    function($scope, $state, FormUtil, GoogleMapUtil, HwtsUiUtil, SweetAlertUtil, User) {
      // //////////////////////////////////////////////////////////////////////////
      var formDetails = [
        {
          form: 'add-a-product',
          title: 'Add a Product',
          desc:
            'Submit a request to add a product to the Household Water Treatment and Safe Storage Knowledge Base. We will get back to you as soon as possible.',
          descriptionTitle: 'Short description',
          locationTitle: null,
          productTitle: null,
          titleField: 'Product name',
          titleRequired: true,
          function: FormUtil.addProduct,
        },
        {
          form: 'add-an-experience',
          title: 'Share Your Experience',
          desc:
            'Share your experience implementing a household water treatment and/or safe storage product or technology.',
          descriptionTitle: 'Summary of experience',
          locationTitle: 'Where does this experience apply?',
          productTitle: 'Related HWTS Product(s)',
          titleField: null,
          titleRequired: false,
          function: FormUtil.addExperience,
        },
        {
          form: 'add-a-project',
          title: 'Add a Project',
          desc:
            'Submit a request to add a project to the Household Water Treatment and Safe Storage Knowledge Base project map. We will get back to you as soon as possible. If you have any questions please contact us at support@hwts.info.',
          descriptionTitle: null,
          locationTitle: 'Where is your project located?',
          productTitle: 'What HWTS products where implemented in this project?',
          titleField: 'Project title',
          titleRequired: false,
          function: FormUtil.addProject,
        },
        {
          form: 'claim-a-project',
          title: 'Claim this project',
          desc:
            'Did you or your organization implement this project? Submit a request to claim this project. We will get back to you as soon as possible. If you have any questions please contact us at support@hwts.info.',
          descriptionTitle: null,
          locationTitle: null,
          productTitle: null,
          titleField: null,
          titleRequired: false,
          function: FormUtil.claimProject,
        },
      ];
      // //////////////////////////////////////////////////////////////////////
      function displayFormLabel(form, label) {
        var details = _.find(formDetails, { form: form }) || null;
        var inputLabels = {
          title: details && details.title ? details.title : 'HWTS Knowledge Base Form',
          desc:
            details && details.desc
              ? details.desc
              : 'Please complete all require fields. We will get back to you as soon as possible.',
          descriptionTitle:
            details && details.descriptionTitle ? details.descriptionTitle : 'Summary',
          locationTitle: details && details.locationTitle ? details.locationTitle : 'Location',
          productTitle: details && details.productTitle ? details.productTitle : 'Add products',
          titleField: details && details.titleField ? details.titleField : 'Title',
        };
        return inputLabels[label];
      }
      // //////////////////////////////////////////////////////////////////////
      function isRequired(form, field) {
        var details = _.find(formDetails, { form: form }) || null;
        var fields = {
          title: details && details.titleRequired ? details.titleRequired : false,
        };
        return fields[field];
      }
      // //////////////////////////////////////////////////////////////////////
      function displayUserName(userInfo) {
        var userName = '';
        if (userInfo) {
          if (userInfo.firstName) userName += userInfo.firstName;
          if (userInfo.firstName && userInfo.lastName) userName += ' ';
          if (userInfo.lastName) userName += userInfo.lastName;
        }
        return userName;
      }
      // //////////////////////////////////////////////////////////////////////
      function setProjectTitle(input) {
        var title = '';
        if (input && input.title) {
          title = input.title;
        }
        if (input && !input.title) {
          var orgName = _.get(input, 'organizations[0].name', '');
          var prodTech =
            _.get(input, 'prodTechs[0].title', null) !== null
              ? ' (' + _.get(input, 'prodTechs[0].title') + ')'
              : '';
          var country =
            _.get(input, 'locations[0].countryName', null) !== null
              ? ' in ' + _.get(input, 'locations[0].countryName')
              : '';
          title = orgName + prodTech + country;
        }
        return title;
      }
      // //////////////////////////////////////////////////////////////////////
      function sendRequest(form, input) {
        if (!input) {
          console.error('Input required to send request.');
        }
        if (!form) {
          console.error('Form info required to send request.');
        }
        function onSuccess() {
          $scope.forms.failure = false;
          SweetAlertUtil.alertPromise(
            'The request has been successfully submitted! A CAWST expert will get back to you as soon as possible.',
            'Success',
            'success',
          ).then(function() {
            HwtsUiUtil.redirectBack();
          });
        }
        function onFailure() {
          SweetAlertUtil.alert(
            'The request could not be submitted, please try again. If the problem persists please contact CAWST at support@hwts.info.',
          );
          $scope.forms.failure = true;
        }
        var details = _.find(formDetails, { form: form }) || null;
        if (details && details.function) {
          if (details.form === 'add-a-project') {
            input.title = setProjectTitle(input);
          }
          details.function(input, function(success) {
            if (success) onSuccess();
            else onFailure();
          });
        } else {
          onFailure();
        }
      }
      // //////////////////////////////////////////////////////////////////////
      var profile = User.retrieveProfile();
      var projectId = $state.params.pid ? $state.params.pid : null;
      var location = $state.params.loc ? $state.params.loc : null;
      var prodTech = $state.params.pt ? $state.params.pt : null;

      $scope.forms = {};
      $scope.forms.currentForm = $state.params.form ? $state.params.form : null;

      $scope.forms.displayFormLabel = displayFormLabel;
      $scope.forms.isRequired = isRequired;
      $scope.forms.getOrganizationList = FormUtil.getOrganizationList;
      $scope.forms.getProductsList = FormUtil.getProductsList;
      $scope.forms.getLocation = FormUtil.getLocation;

      $scope.forms.input = {};
      $scope.forms.input.contacts = [
        {
          email: profile && profile.email ? profile.email : '',
          fullName: displayUserName(profile),
          organization:
            profile && profile.organization && profile.organization.name ? profile.organization.name : '',
        },
      ];

      if ($scope.forms.currentForm !== 'add-an-experience') {
        $scope.forms.input.websites = [
          {
            title: '',
            url: '',
          },
        ];
      }

      if ($scope.forms.currentForm === 'add-a-project') {
        $scope.forms.input.projectOngoing = true;
      }
      // //////////////////////////////////////////////////////////////////////
      if (projectId) {
        GoogleMapUtil.getProject(projectId, function(project) {
          if (project) {
            $scope.forms.input.projectId = project.id;
            $scope.forms.input.projectTitle = project.title;
            $scope.forms.input.projectCountry = project.locations[0].displayName;
            $scope.forms.input.projectDescription = project.description;
            $scope.forms.input.projectProds = _.map(project.prodTechList, 'name').join(', ');
          }
        });
      }
      // //////////////////////////////////////////////////////////////////////
      $scope.forms.datePicker = {};
      $scope.forms.datePicker.startDateOpen = false;
      $scope.forms.datePicker.endDateOpen = false;
      $scope.forms.datePicker.open = function(datePicker) {
        if (datePicker === 'start') $scope.forms.datePicker.startDateOpen = true;
        if (datePicker === 'end') $scope.forms.datePicker.endDateOpen = true;
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.forms.submit = function(isValid) {
        if (!isValid) {
          SweetAlertUtil.error('Please fill out all required form fields.');
          return;
        }
        if (!User.isSignedIn()) {
          User.signIn(
            function() {
              sendRequest($scope.forms.currentForm, $scope.forms.input);
            },
            function() {
              /* Error */
            },
          );
        } else {
          sendRequest($scope.forms.currentForm, $scope.forms.input);
        }
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.forms.cancel = function() {
        SweetAlertUtil.confirm(
          'Are you sure you want to cancel? If you do all data will be lost.',
        ).then(
          function(confirm) {
            if (confirm) HwtsUiUtil.redirectBack();
          },
          function() {
            /* fail */
          },
        );
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.isSignedIn = function() {
        return User.isSignedIn();
      };
    },
  ]);
})();
