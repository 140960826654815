// Dependencies (third-party code) to be imported into `vendor-bundle`

// The allowed properties of `window` are typed in `globals.d.ts`
window.he = require('he');
window.jQuery = require('jquery');
window._ = require('lodash');
window.moment = require('moment');

require('bootstrap');
require('sweetalert');

require('angular');
require('angular-animate');
require('angular-cookies');
require('angular-resource');
require('angular-sanitize');
require('@uirouter/angularjs');
require('angular-ui-bootstrap'); // tpls?
require('angular-filter');
require('angular-breadcrumb');
require('angular-simple-logger');
require('angular-google-maps');
require('ng-tags-input');
require('angular-touch');
require('angular-css');
require('angular-storage');

require('auth0-js');
require('angular-auth0/src');

// Vue migration utilities
require('ngVue');
