/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */

(function() {
  angular.module('app.directives').directive('headerSearchInline', [
    '$state',
    function($state) {
      return {
        restrict: 'E',
        templateUrl: 'scripts/directives/header_search_inline.html',
        link: function(scope, element, _attr) {
          scope.query = '';

          scope.showHideSearchBar = function() {
            if (element.find('.search-btn').hasClass('fa-search')) {
              element.find('.search-open').fadeIn(500);
              element.find('.search-btn').removeClass('fa-search');
              element.find('.search-btn').addClass('fa-times');
              element.find('#headerInlineSearchBar').focus();
            } else {
              element.find('.search-open').fadeOut(500);
              element.find('.search-btn').addClass('fa-search');
              element.find('.search-btn').removeClass('fa-times');
            }
          };

          scope.search = function(query) {
            var searchQuery = query ? query : '';
            $state.go('search', { query: searchQuery, page: 1 }, { reload: true });
          };
        },
      };
    },
  ]);
})();
