import { render, staticRenderFns } from "./ProdTechImage.vue?vue&type=template&id=c3ee74f6&"
import script from "./ProdTechImage.vue?vue&type=script&lang=ts&"
export * from "./ProdTechImage.vue?vue&type=script&lang=ts&"
import style0 from "./ProdTechImage.vue?vue&type=style&index=0&id=c3ee74f6&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/node-apps/hwts-site/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c3ee74f6')) {
      api.createRecord('c3ee74f6', component.options)
    } else {
      api.reload('c3ee74f6', component.options)
    }
    module.hot.accept("./ProdTechImage.vue?vue&type=template&id=c3ee74f6&", function () {
      api.rerender('c3ee74f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/vue-app/components/ProdTechImage.vue"
export default component.exports