import { ProdTech } from '@src/client/vue-app/types/ProdTech';

export function filterProdTechByTechnology(prodTechs: ProdTech[], technologyId: string) {
  if (!technologyId) {
    return prodTechs;
  }

  return prodTechs.filter((prodTech) => {
    // If the item is a technology, include it only if it's the one we're filtering on.
    if (prodTech.type === 'technology') {
      return prodTech.id === technologyId;
    }

    // Otherwise, return all products that rely on the technology.
    return prodTech.prodtech && prodTech.prodtech.some((parent) => parent.id === technologyId);
  });
}
