var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "breadcrumbs-v3 cawst-hero-banner",
      class: _vm.imageClass,
      style: _vm.styles,
    },
    [
      _c(
        "div",
        { staticClass: "container cawst-hero-banner__body" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.video
        ? [
            _c(
              "video",
              {
                staticClass: "hero-animation",
                attrs: {
                  poster: _vm.video.poster,
                  playsinline: "",
                  autoplay: "",
                  loop: "",
                  muted: "",
                },
                domProps: { muted: true },
              },
              _vm._l(_vm.video.sources, function (source, index) {
                return _c("source", {
                  key: `hero-video-source-${index}`,
                  attrs: { src: source.src, type: source.type },
                })
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }