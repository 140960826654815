var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("div", { staticClass: "container g-pt-100 g-pb-40" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("MainSectionCard", { attrs: { "card-data": _vm.emergencyData } }),
          _vm._v(" "),
          _c("MainSectionCard", {
            attrs: {
              "card-data": _vm.prodTechData,
              "icon-formatting-class": "cstm-margin-left-25",
            },
          }),
          _vm._v(" "),
          _c("MainSectionCard", { attrs: { "card-data": _vm.approchesData } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("MainSectionCard", { attrs: { "card-data": _vm.projectsData } }),
          _vm._v(" "),
          _c("MainSectionCard", {
            attrs: { "card-data": _vm.countryFocusData },
          }),
          _vm._v(" "),
          _c("MainSectionCard", { attrs: { "card-data": _vm.monitoringData } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }