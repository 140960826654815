/* eslint-disable no-param-reassign */

import angular from 'angular';

import { config } from '@src/client/vue-app/config';

interface MbaScope extends angular.IScope {
  mbaMethods: string[];
  setActiveClass: Function;
  treatmentProcess: string;
}

angular.module('app.directives').directive('multiBarrierApproach', () => ({
  restrict: 'E',
  templateUrl: 'scripts/directives/multibarrier_approach.tpl.html',
  scope: {
    treatmentProcess: '=',
  },
  link(scope: MbaScope, _elem, _attr) {
    scope.mbaMethods = config.mbaMethods;

    scope.setActiveClass = (treatmentMethod: string): string => {
      const defaultClass = '';
      const activeMethodClass = 'mba-box-active';

      if (!scope.treatmentProcess || !treatmentMethod) {
        return defaultClass;
      }

      if (scope.treatmentProcess.indexOf(treatmentMethod) !== -1) {
        return activeMethodClass;
      }

      return defaultClass;
    };
  },
}));
