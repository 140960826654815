import { ProdTech } from '@src/client/vue-app/types/ProdTech';
import { deploymentConfig } from '@src/config/deploymentConfig';

const { imageBaseUrl } = deploymentConfig.resources;

// FIXME: Apply typings to prodTech
export function findProdTechMainImageUrl(prodTech: ProdTech): string | null {
  // If the prodtech is falsy, then return null
  if (!prodTech) {
    return null;
  }

  // If the prodtech already has a `mainImageUrl`, use that
  if (prodTech.mainImageUrl) {
    return imageBaseUrl + prodTech.mainImageUrl;
  }

  const getUri = (index: number) => {
    // If we do not have images for the index, then return null
    if (!prodTech.images || prodTech.images.length === 0 || prodTech.images.length - 1 < index) {
      return null;
    }

    // Safety check - some data entries have an entry without a `uri` field
    const imgUri = prodTech.images[index] ? prodTech.images[index].uri : null;

    return imgUri ? imageBaseUrl + imgUri : null;
  };

  const mainIndex = prodTech.images.findIndex((image) => image.isMainImage);
  let uri = getUri(mainIndex) || getUri(0);

  if (!uri && prodTech.type === 'product') {
    uri = 'images/generic_prodtech.jpg';
  }

  return uri;
}
