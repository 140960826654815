/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */

(function() {
  angular.module('app').controller('ThemesCtrl', [
    '$element',
    '$http',
    '$rootScope',
    '$scope',
    '$state',
    '$uiViewScroll',
    'MetaTagsUtil',
    function($element, $http, $rootScope, $scope, $state, $uiViewScroll, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////
      var themeSlug = $state.params.themeSlug ? $state.params.themeSlug : '';
      // //////////////////////////////////////////////////////////////////////
      $scope.scrollToSection = function(elementId) {
        $uiViewScroll($element.find('#' + elementId));
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.sectionListLimit = 3;
      $scope.keyDocumentLimit = $scope.sectionListLimit;
      $scope.faqLimit = $scope.sectionListLimit;
      $scope.caseStudiesLimit = $scope.sectionListLimit;
      $scope.experiencesLimit = $scope.sectionListLimit;
      $scope.researchLimit = $scope.sectionListLimit;
      $scope.moreInfoLimit = $scope.sectionListLimit;
      // //////////////////////////////////////////////////////////////////////
      function setMetaTags() {
        function getThemeImage(themeSlug) {
          return themeSlug === 'approaches' ? 'approaches' : 'monitoring';
        }

        MetaTagsUtil.SetSocialMetaTags({
          ogTitle:
            $scope.themeDetails.title +
            ' | Household Water Treatment and Safe Storage Knowledge Base',
          ogDescription: $scope.themeDetails.description,
          ogUrl: 'theme/' + themeSlug,
          ogImage: 'images/social/hwts_social_media_' + getThemeImage(themeSlug) + '.jpg',
        });
      }
      // //////////////////////////////////////////////////////////////////////
      $scope.loadingThemeDetails = true;

      $http.get($rootScope.api_url + '/public/theme/' + themeSlug).then(
        function(response) {
          if (response && response.data) {
            var data = response.data;
            $scope.themeDetails = data;
            $rootScope.themeTitle = data.title;
            if (!$scope.$$phase) $scope.$apply();
          }
          $scope.loadingThemeDetails = false;
          setMetaTags();
        },
        function() {
          /* Error */
        },
      );
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
