/* eslint-disable no-param-reassign */

import angular from 'angular';

(() => {
  angular //
    .module('app') //
    .controller('LearnNavCtrl', [
      '$scope',
      '$state',
      'LessonUtil',
      ($scope, $state, LessonUtil) => {
        const currentId = $state.params.id;

        $scope.isEmbeddedModule = (): boolean => {
          if (!currentId) {
            return false;
          }

          const lesson = LessonUtil.getLessonById(currentId);

          // FIXME: The constant reuse of 'embedded-module' suggests an enum may be appropriate
          return lesson && lesson.lessonType === 'embedded-module';
        };
      },
    ]);
})();
