var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "progression-bar",
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm._l(_vm.sortedProgressions, function (progression, index) {
        return [
          _c(
            "div",
            {
              key: `${_vm.prodTech.id}-${progression.id}`,
              staticClass: "circle",
              class: { done: _vm.showCircleProgressed(progression) },
              staticStyle: { position: "relative", "z-index": "1" },
            },
            [
              _c("span", { staticClass: "label-progression" }, [
                _c("img", { attrs: { src: progression.imageSrc } }),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "title emerging" }, [
                _vm._v(_vm._s(progression.label)),
              ]),
            ]
          ),
          _vm._v(" "),
          index < _vm.sortedProgressions.length - 1
            ? _c("span", {
                key: `${_vm.prodTech.id}-${progression.id}-bar`,
                staticClass: "bar",
                class: { done: _vm.showBarProgressed(progression) },
                staticStyle: { position: "relative", "z-index": "0" },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }