interface MergeMapValuesOptions<K> {
  disallowUndefined?: boolean;
  targetKey?: K;
}

export function toUniqueArray<T>(iterable: Iterable<T>): T[] {
  return [...new Set(iterable)];
}

/* Sequentially merge the map's object values and return the result.
   If `targetKey` is provided, don't merge values after that key.
   If `disallowUndefined: true`, ensure that the returned object has no undefined top-level entries.
 */
export function mergeMapValues<R, K = string>(
  map: Map<K, Partial<R>>, options: MergeMapValuesOptions<K> = {}
): Partial<R> | R {
  const { disallowUndefined, targetKey } = options;
  if (targetKey && !map.has(targetKey)) {
    throw new Error(`Target key '${targetKey}' does not exist in the map`);
  }

  const recordsToMerge = targetKey
    ? Array.from(map.values()).slice(0, Array.from(map.keys()).indexOf(targetKey) + 1)
    : Array.from(map.values());

  const mergedRecords = recordsToMerge.reduce((accConfig, config) => ({
    ...accConfig,
    ...config,
  }), {});

  const allProps = toUniqueArray(Array.from(map.values())
    .reduce(
      (accProps, value) => [...accProps, ...Object.keys(value)],
      [] as string[]
    ));
  if (Object.keys(mergedRecords).length !== allProps.length) {
    if (disallowUndefined) {
      throw new Error('Some values are undefined');
    }
    return mergedRecords;
  }
  return mergedRecords as R;
}
