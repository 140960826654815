type DeploymentTarget = 'dev' | 'local' | 'prod';

export function getDeploymentTarget(): DeploymentTarget {
  const deploymentTarget = process.env.DEPLOYMENT_TARGET || 'local';

  if (!['dev', 'local', 'prod'].includes(deploymentTarget)) {
    throw new Error(`Invalid DEPLOYMENT_TARGET: ${deploymentTarget}`);
  }

  return deploymentTarget as DeploymentTarget;
}
