import { ClientConfig } from '@sanity/client';
import { getDeploymentTarget } from '../util/getDeploymentTarget';

const isProduction = getDeploymentTarget() === 'prod';

export const hwtsNetworkSanityConfig: ClientConfig = {
  projectId: '65se5djk',
  dataset: isProduction ? 'production' : 'development',
  useCdn: isProduction,
  apiVersion: '2023-09-15',
};
