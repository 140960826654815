var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass: "lesson-list-item",
      style: _vm.styles,
      attrs: { href: _vm.resourceUrl },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("lesson-selected", _vm.lesson.id)
        },
      },
    },
    [
      _c("div", { staticClass: "lesson-list-item__locales" }, [
        _vm._v(_vm._s(_vm.supportedLocales.join(" | ").toLocaleUpperCase())),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "lesson-list-item__link" }, [
        _vm._v(_vm._s(_vm.lesson.title)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }