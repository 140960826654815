
import Vue from 'vue';

import { findProdTechMainImageUrl } from '@src/client/vue-app/util/findProdTechMainImageUrl';

export default Vue.extend({
  name: 'ProdTechImage',
  props: {
    prodTech: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: '125px',
    },
  },
  computed: {
    imageClasses(): string[] {
      if (this.prodTech.type === 'technology') {
        return [`block-${this.prodTech.overlayColor || 'default'}`];
      }

      return [];
    },
    imageSrc() {
      return findProdTechMainImageUrl(this.prodTech);
    },
  },
});
