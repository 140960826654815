var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "alert-block alert fade in", class: _vm.alertTypeClass },
    [
      !_vm.permanent
        ? _c(
            "button",
            {
              staticClass: "alert-block__dismiss-btn close",
              attrs: {
                type: "button",
                "data-dismiss": "alert",
                "aria-hidden": "true",
              },
            },
            [_vm._v("\n    ×\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "alert-block__body" }, [_vm._t("default")], 2),
      _vm._v(" "),
      this.$slots.actions
        ? _c(
            "div",
            { staticClass: "alert-block__actions" },
            [_vm._t("actions")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }