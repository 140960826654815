var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "lesson-view-pagination" }, [
    _c(
      "a",
      {
        staticClass: "lesson-view-pagination__link",
        class: {
          "lesson-view-pagination__link--disabled": !_vm.hasPreviousLesson,
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.onPrevious.apply(null, arguments)
          },
        },
      },
      [_c("i", { staticClass: "fa fa-angle-left" }), _vm._v(" Previous")]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "lesson-view-pagination__link",
        staticStyle: { "margin-left": "30px", "margin-right": "30px" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.$emit("menu")
          },
        },
      },
      [_c("i", { staticClass: "fa fa-th" }), _vm._v(" Menu")]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "lesson-view-pagination__link",
        class: { "lesson-view-pagination__link--disabled": !_vm.hasNextLesson },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.onNext.apply(null, arguments)
          },
        },
      },
      [_vm._v("Next "), _c("i", { staticClass: "fa fa-angle-right" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }