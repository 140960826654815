import angular from 'angular';
import { toHTML } from '@portabletext/to-html';
import {
  SanityImageSource,
  ImageFormat,
} from '@sanity/image-url/lib/types/types';
import { networkSanity as sanity } from '../../clients/sanity';

interface UrlForSanityImageOptions {
  width?: number;
  height?: number;
  format?: ImageFormat;
}

interface FeatureImage {
  image: SanityImageSource;
  altText: string;
}

interface CallToAction {
  text: string;
  url: string;
}

interface ContentSection {
  _key: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any[]; // Portable text block typing is not publicly available
}

interface NetworkEventsQueryResult {
  _id: string;
  title: string;
  featureImage: FeatureImage;
  mainUrl?: string;
  callToAction?: CallToAction;
  dateAndTimeInfo: string;
  supplementaryText?: string;
  contentSections: ContentSection[];
}

(() => {
  angular.module('app').controller('HwtsNetworkNewsCtrl', [
    '$rootScope',
    '$scope',
    'MetaTagsUtil',
    ($rootScope, $scope, MetaTagsUtil) => {
      const networkEventsQuery = `* [_type == 'networkEvent' && language == $language] | order(defined(sortingDate) desc, sortingDate desc, _createdAt desc) | {
        _id,
        title,
        featureImage {
          image,
          altText,
        },
        mainUrl,
        callToAction {
          text,
          url,
        },
        dateAndTimeInfo,
        supplementaryText,
        contentSections[] {
          _key,
          title,
          content,
        },
      }`;

      // Image builder function
      function urlForSanityImage(
        source: SanityImageSource,
        options: UrlForSanityImageOptions = {}
      ): string {
        const { width, height, format } = options;

        let builder = sanity.imageUrlBuilder.image(source);

        if (typeof width !== 'undefined') {
          builder = builder.width(width);
        }

        if (typeof height !== 'undefined') {
          builder = builder.height(height);
        }

        if (format) {
          builder = builder.format(format);
        }

        return builder.url();
      }

      function toInlineImageSrc(source: SanityImageSource): string {
        return urlForSanityImage(source, { width: 300, format: 'webp' });
      }

      /* eslint-disable no-param-reassign */
      $scope.isLoading = true;
      $scope.networkEvents = [] as NetworkEventsQueryResult[];
      $scope.thumbnailUrlFor = (source: SanityImageSource): string => urlForSanityImage(source, {
        width: 750,
        height: 400,
        format: 'webp',
      });
      $scope.renderPortableText = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        portableTextBlocks: any[]
      ): string => toHTML(portableTextBlocks, {
        components: {
          marks: {
            link({ children, value }) {
              // Open links in new tabs, and prevent clickjacking
              return `<a href="${value.href}" target="_blank" rel="noreferrer noopener">${children}</a>`;
            },
          },
          types: {
            // Block typing is not importable, so any is used as a stand in
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            imageWithAlt(imageBlock: any) {
              const src = toInlineImageSrc(imageBlock.value.image);
              const alt = imageBlock.value.altText;
              return `<img src="${src}" alt="${alt}">`;
            },
          },
        },
      });
      /* eslint-enable no-param-reassign */

      async function fetchNewsEvents(): Promise<void> {
        /* eslint-disable no-param-reassign */
        $scope.isLoading = true;
        $scope.networkEvents = await sanity.client.fetch(networkEventsQuery, {
          language: $rootScope.browsingLanguage,
        });
        $scope.$apply(() => {
          $scope.isLoading = false;
        });
        /* eslint-enable no-param-reassign */
      }

      // At time of writing, language switching forces a page refresh, allowing us to avoid setting
      //   up a watcher. For future iterations (e.g. in Vue), this may not be true. The
      //   fetchNewsEvents() function should then be called from the appropriate life cycle hook.
      fetchNewsEvents();

      MetaTagsUtil.SetSocialMetaTags({
        title: 'The HWTS Network',
        ogImage: 'images/network/hwts_network_social.jpg',
        ogImageWidth: 500,
        ogImageHeight: 250,
        description:
          'The Household Water Treatment and Safe Storage (HWTS) Network is a global alliance working to scale up the use of HWTS technologies to improve water quality.',
        ogTitle: 'The HWTS Network',
        ogDescription:
          'The Household Water Treatment and Safe Storage (HWTS) Network is a global alliance working to scale up the use of HWTS technologies to improve water quality.',
        ogUrl: 'network',
      });
    },
  ]);
})();
