var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "base-select" }, [
    _c("label", { staticClass: "select" }, [
      _c(
        "select",
        {
          attrs: { id: _vm.id || _vm.name, name: _vm.name },
          domProps: { value: _vm.value },
          on: { change: _vm.onChange },
        },
        [
          _vm._l(_vm.items, function (item, index) {
            return [
              !!item.groupName
                ? _c(
                    "optgroup",
                    { key: item.groupName, attrs: { label: item.groupName } },
                    _vm._l(item.items, function (nestedItem, nestedIndex) {
                      return _c(
                        "option",
                        {
                          key: `${item.groupName}-${_vm.name}-${nestedIndex}`,
                          domProps: { value: _vm.getItemValue(nestedItem) },
                        },
                        [_vm._v(_vm._s(nestedItem.label))]
                      )
                    }),
                    0
                  )
                : _c(
                    "option",
                    {
                      key: `${_vm.name}-${index}`,
                      domProps: { value: _vm.getItemValue(item) },
                    },
                    [_vm._v(_vm._s(item.label))]
                  ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("i", {
        staticStyle: { right: "-2px", "background-color": "transparent" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }