var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "learn-lesson-view" },
    [
      _c("div", { staticClass: "learn-lesson-view__spacer" }),
      _vm._v(" "),
      !!_vm.lesson && _vm.lesson.lessonType === "embedded-module"
        ? [
            _c(
              "div",
              { staticClass: "container margin-top-30 margin-bottom-30" },
              [
                _c(
                  "h1",
                  {
                    staticClass:
                      "text-transform-uppercase text-center margin-bottom-30",
                  },
                  [_vm._v(_vm._s(_vm.lesson.title))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "learn-lesson-view__message",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openLessonUrl.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._m(0)]
                ),
                _vm._v(" "),
                _c("LessonViewPagination", {
                  attrs: {
                    "has-next-lesson": _vm.hasNextLesson,
                    "has-previous-lesson": _vm.hasPreviousLesson,
                  },
                  on: {
                    previous: _vm.onPrevious,
                    next: _vm.onNext,
                    menu: _vm.onMenu,
                  },
                }),
              ],
              1
            ),
          ]
        : !!_vm.lesson
        ? [
            _c(
              "div",
              { staticClass: "container margin-top-60" },
              [
                _c(
                  "h1",
                  {
                    staticClass:
                      "text-transform-uppercase text-center margin-bottom-30",
                  },
                  [_vm._v(_vm._s(_vm.lesson.title))]
                ),
                _vm._v(" "),
                _c("LessonViewContent", {
                  staticClass: "margin-bottom-30",
                  attrs: { lesson: _vm.lesson, locale: _vm.locale },
                }),
                _vm._v(" "),
                _c("LessonViewPagination", {
                  staticClass: "margin-bottom-30",
                  attrs: {
                    "has-next-lesson": _vm.hasNextLesson,
                    "has-previous-lesson": _vm.hasPreviousLesson,
                  },
                  on: {
                    previous: _vm.onPrevious,
                    next: _vm.onNext,
                    menu: _vm.onMenu,
                  },
                }),
              ],
              1
            ),
          ]
        : [
            _c("div", { staticClass: "container learn-lesson-view__message" }, [
              _c("h1", { staticClass: "learn-lesson-view__message--title" }, [
                _vm._v("Lesson Not Found"),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "learn-lesson-view__message--link",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goToState({ stateName: "learn" })
                    },
                  },
                },
                [_vm._v("Back to Lesson List")]
              ),
            ]),
          ],
      _vm._v(" "),
      _c("LessonViewBanner"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "learn-lesson-view__message--link" }, [
      _c("i", { staticClass: "fa fa-external-link" }),
      _vm._v(" View lesson"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }