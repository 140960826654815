import { DeploymentConfig, DeploymentTarget } from './_types';

const api = {
  baseRoute: '/hwts',
  origin: {
    dev: 'https://api.cawstdev.org',
    prod: 'https://api.cawst.org',
    local: 'http://localhost:3003',
  },
};

const port = 3002;

const assetBaseUrls = {
  dev: 'https://s3-us-west-2.amazonaws.com/cawst-hwts-dev/',
  prod: 'https://s3-us-west-2.amazonaws.com/cawst-hwts-prod/',
};

export const configsMap = new Map<DeploymentTarget, Partial<DeploymentConfig>>([
  [
    'prod',
    {
      api: {
        appUrl: `${api.origin.prod}${api.baseRoute}`,
        origin: api.origin.prod,
      },
      apiBaseRoute: api.baseRoute,
      assetBaseUrl: 'https://cawst-hwts-public.s3.amazonaws.com/',
      auth0: {
        audience: 'https://cawst.auth0.com/userinfo',
        clientId: 'wwWNxqPrq17QHvtkM0LUZASNHdAAyb3t',
        domain: 'cawst.auth0.com',
      },
      google: {
        analyticsId: 'UA-12911484-10',
        maps: {
          api: {
            key: 'AIzaSyDAJsfakICYrn4P6UHGwEtbfQYbbkyhd5U',
          },
        },
      },
      mandrill: {
        apiKey: 'jkPPSIATtH77TNnOoBgibA',
      },
      origin: 'https://www.hwts.info',
      port,
      prerender: {
        token: 'sEUXNvWPFHOpVgXsSmEW',
      },
      resources: {
        assetBaseUrl: assetBaseUrls.prod,
        imageBaseUrl: assetBaseUrls.prod,
      },
      urls: {
        userAccount: 'https://resources.cawst.org/myaccount',
      },
    },
  ],
  [
    'dev',
    {
      api: {
        appUrl: `${api.origin.dev}${api.baseRoute}`,
        origin: api.origin.dev,
      },
      assetBaseUrl: 'https://cawst-hwts-dev-public.s3.amazonaws.com/',
      auth0: {
        audience: 'https://cawst.auth0.com/userinfo',
        clientId: '1UefSTmG0oyL13YrwpEW4cVaZPzVlxJs',
        domain: 'cawst.auth0.com',
      },
      origin: 'https://hwts.cawstdev.org',
      resources: {
        assetBaseUrl: assetBaseUrls.dev,
        imageBaseUrl: assetBaseUrls.dev,
      },
      urls: {
        userAccount: 'https://resources.cawstdev.org/myaccount',
      },
    },
  ],
  [
    'local',
    {
      api: {
        appUrl: `${api.origin.local}${api.baseRoute}`,
        origin: api.origin.local,
      },
      origin: `http://localhost:${port}`,
    },
  ],
]);
