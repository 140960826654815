/* eslint-disable no-undef */

(function() {
  angular.module('app').controller('ProjectsPageCtrl', [
    '$scope',
    'GoogleMapUtil',
    'MetaTagsUtil',
    function($scope, GoogleMapUtil, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////
      $scope.projects = [];
      $scope.projectsMap = {};
      $scope.projectsMap.mapSettings = GoogleMapUtil.getMapSettings();
      $scope.projectsMap.mapSettings.map.window.closeClick();
      $scope.projectsMap.mapSettings.mapEvents = {
        zoom_changed: function(map) {
          var zoomLevel = map.getZoom();
          if (zoomLevel >= 6) {
            $scope.projectsMap.mapSettings.typeOfMarker = 'spider';
            $scope.projectsMap.mapSettings.typeOptions = _.clone(
              $scope.projectsMap.mapSettings.spiderfierOptions,
            );
          } else {
            $scope.projectsMap.mapSettings.typeOfMarker = 'cluster';
            $scope.projectsMap.mapSettings.typeOptions = _.clone(
              $scope.projectsMap.mapSettings.clusterOptions,
            );
          }
        },
      };
      $scope.projectsLoaded = false;

      $scope.projectsMap.mapSettings.map.center = {
        latitude: 1.51,
        longitude: -1.76,
      };
      $scope.projectsMap.mapSettings.map.zoom = 2;
      // //////////////////////////////////////////////////////////////////////
      GoogleMapUtil.getProjects(function(_projects) {
        $scope.projects = _projects;
        $scope.projectsLoaded = true;
        $scope.$apply();
      });
      // //////////////////////////////////////////////////////////////////////
      $scope.showProjectOnMap = function(project) {
        $scope.projectsMap.mapSettings.map.center = {
          latitude: project.latitude,
          longitude: project.longitude,
        };
        $scope.projectsMap.mapSettings.map.zoom = 6;
        $scope.projectsMap.mapSettings.map.window.model = project;
        $scope.projectsMap.mapSettings.map.window.show = true;
      };
      // //////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: 'Project Map | Household Water Treatment and Safe Storage Knowledge Base',
        ogDescription:
          "Who's doing what where. See what's happening in your country and add your own project.",
        ogUrl: 'projects',
        ogImage: 'images/social/hwts_social_media_projects.jpg',
      });
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
