/* eslint-disable no-param-reassign */

import angular from 'angular';

(() => {
  angular.module('app.directives').directive('progressionbar', [
    '$uibModal',
    ($uibModal) => ({
      restrict: 'E',
      templateUrl: '/scripts/directives/progression_bar.html',
      scope: {
        progression: '@',
        hideHeader: '@',
      },
      link(scope, element, attr) {
        function applyProgressionClass(progressionValue: string) {
          // FIXME: No default case - should be refactored when appropriate
          // eslint-disable-next-line default-case
          switch (progressionValue) {
            case 'Established':
              element.find('.progression-bar .circle:nth-of-type(4)').addClass('done');
              element.find('.progression-bar .circle:nth-of-type(3)').addClass('done');
              element.find('.progression-bar .circle:nth-of-type(2)').addClass('done');
              element.find('.progression-bar .circle:nth-of-type(1)').addClass('done');
              element.find('.progression-bar .bar:nth-of-type(3)').addClass('done');
              element.find('.progression-bar .bar:nth-of-type(2)').addClass('done');
              element.find('.progression-bar .bar:nth-of-type(1)').addClass('done');
              break;

            case 'Adopted':
              element.find('.progression-bar .circle:nth-of-type(3)').addClass('done');
              element.find('.progression-bar .circle:nth-of-type(2)').addClass('done');
              element.find('.progression-bar .circle:nth-of-type(1)').addClass('done');
              element.find('.progression-bar .bar:nth-of-type(2)').addClass('done');
              element.find('.progression-bar .bar:nth-of-type(1)').addClass('done');
              break;

            case 'Tested':
              element.find('.progression-bar .circle:nth-of-type(2)').addClass('done');
              element.find('.progression-bar .circle:nth-of-type(1)').addClass('done');
              element.find('.progression-bar .bar:nth-of-type(1)').addClass('done');
              break;

            case 'Emerging':
              element.find('.progression-bar .circle:nth-of-type(1)').addClass('done');
              element.find('.progression-bar .bar:nth-of-type(1)').addClass('done');
              break;
          }
        }

        attr.$observe('progression', (value: string) => {
          applyProgressionClass(value);
        });

        // Assigning fields to scope is common in AngularJS - but TS does
        //   not recognize this
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        scope.openModal = () => {
          const modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'progressionInfoModal.tpl.html',
            controller: [
              '$scope',
              '$uibModalInstance',
              ($scope, $uibModalInstance) => {
                $scope.cancel = () => {
                  $uibModalInstance.dismiss('cancel');
                };
              },
            ],
            size: 'md',
            backdrop: true,
          });
          modalInstance.result.then(
            () => {
              // console.log('Finished');
            },
            () => {
              // console.log('Modal dismissed at : ' + new Date());
            },
          );
        };
      },
    }),
  ]);
})();
