/* eslint-disable no-param-reassign */

import angular from 'angular';
import he from 'he';
import * as _ from 'lodash';
import moment from 'moment';

import { fuzzyProdTechSearch } from '@src/client/vue-app/filters/fuzzyProdTechSearch';
import { filterProdTechByContaminant } from '@src/client/vue-app/filters/filterProdTechByContaminant';
import { filterProdTechByProgression } from '@src/client/vue-app/filters/filterProdTechByProgression';
import { filterProdTechByTreatmentMethod } from '@src/client/vue-app/filters/filterProdTechByTreatmentMethod';
import { sortProdTechs } from '@src/client/vue-app/filters/sortProdTechs';
import { filterProdTechByTechnology } from '@src/client/vue-app/filters/filterProdTechByTechnology';

(() => {
  angular
    .module('app.filters', [])
    // //////////////////////////////////////////////////////////////////////////
    .filter('htmlToPlaintextTruncate', () => (text, length, end) => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(length) || length === undefined) {
        length = 10;
      }
      if (end === undefined) {
        end = '...';
      }
      if (text.length <= length || text.length - end.length <= length) {
        return String(text).replace(/<[^>]+>/gm, '');
      }
      const htmlToPlaintext = String(text).replace(/<[^>]+>/gm, '');
      return String(htmlToPlaintext).substring(0, length - end.length) + end;
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('htmlToPlaintext', () => (text) => (text ? he.decode(String(text).replace(/<[^>]+>/gm, '')) : ''))
    // //////////////////////////////////////////////////////////////////////////
    .filter('contentTypeLabel', [
      'HwtsUiUtil',
      (HwtsUiUtil) => {
        const { contentTypeLabelMap } = HwtsUiUtil;

        function createLabel(str) {
          let ret = '';
          if (str) {
            ret = str.replace('_', ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
          }
          return ret;
        }

        function generateLabel(contentType) {
          let ret = '';
          if (contentType) {
            // eslint-disable-next-line no-prototype-builtins
            if (contentTypeLabelMap.hasOwnProperty(contentType)) {
              ret = contentTypeLabelMap[contentType];
            } else {
              ret = createLabel(contentType);
            }
          }
          return ret;
        }

        return (contentType) => generateLabel(contentType);
      },
    ])
    // //////////////////////////////////////////////////////////////////////////
    .filter('contentTypeSlug', [
      'HwtsUiUtil',
      (HwtsUiUtil) => {
        const map = HwtsUiUtil.contentTypeSlugMap;
        return (slug) => map[slug] || slug;
      },
    ])
    // //////////////////////////////////////////////////////////////////////////
    .filter('displayCountries', () => (countries) => {
      let display = countries;
      if (typeof countries !== 'string') {
        display = _.join(countries, ', ');
      }
      return display;
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('displayFilename', () => (file) => {
      if (!file) {
        return null;
      }
      return file.title ? decodeURI(file.title) : decodeURI(file.name);
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('displayLocationNames', () => (array) => {
      const ret = [];
      if (!array) {
        return null;
      }
      array.forEach((location) => {
        if (location.countryName && location.countryName.length > 0) {
          ret.push(location.countryName);
        }
      });
      return ret.join(', ');
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('displayLocationName', () => (location) => {
      // FIXME: We can probably refactor / clarify this large number of if statements
      if (!location) {
        return null;
      }
      if (location.countryName && location.countryName.length > 0) {
        if (
          location.displayName
            && location.displayName.length > 0
            && location.displayName !== location.countryName
        ) {
          return location.displayName;
        }
        return location.countryName;
      }
      return null;
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('displayOrganizations', () => (array, type) => {
      if (!array && type !== 'experience') {
        return null;
      }
      const ret = [];
      if (array) {
        array.forEach((org) => {
          if (org.name) {
            ret.push(org.name);
          }
        });
      }
      if (ret.length < 1 && type && type === 'experience') {
        ret[0] = 'Anonymous';
      }
      return ret.join(', ');
    })
    // //////////////////////////////////////////////////////////////////////////
    // Product / Technology Search Filters
    .filter('filterBySearchString', () => fuzzyProdTechSearch)
    .filter('filterByContaminant', () => filterProdTechByContaminant)
    .filter('filterByTechnology', () => filterProdTechByTechnology)
    .filter('filterByTreatmentMethod', () => filterProdTechByTreatmentMethod)
    .filter('filterByProgression', () => filterProdTechByProgression)
    .filter('sortProdTechs', () => sortProdTechs)
    // //////////////////////////////////////////////////////////////////////////
    .filter('filterIndependentTesting', () => (parameters) => {
      const list = [];

      function addParameter(value, parameter) {
        if (
          _.findIndex(list, { parameter: parameter.parameter }) < 0
            && value.testingType === 'Independent Testing'
        ) {
          list.push(parameter);
        }
      }

      angular.forEach(parameters, (parameter) => {
        angular.forEach(parameter.laboratoryEfficiency, (value) => {
          addParameter(value, parameter);
        });
        angular.forEach(parameter.fieldEfficiency, (value) => {
          addParameter(value, parameter);
        });
      });

      return list;
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('filterSections', () => (items) => {
      const result = {};
      angular.forEach(items, (value, key) => {
        if (value.show) {
          result[key] = value;
        }
      });
      return result;
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('joinArray', () => (array) => _.join(array, ', '))
    // //////////////////////////////////////////////////////////////////////////
    // FIXME: This is probably _not_ the intended behaviour, but we have no tests
    //   We should refactor this next time we need to edit this page.
    // eslint-disable-next-line consistent-return
    .filter('niceCaseStudyDate', () => (caseStudy) => {
      let niceDate = '';
      if (caseStudy && caseStudy.startDate) {
        const startDate = moment(caseStudy.startDate);
        const endDate = caseStudy.endDate ? moment(caseStudy.endDate) : null;

        if (!endDate || startDate.format('M YYYY') === endDate.format('M YYYY')) {
          niceDate = startDate.format('MMMM, YYYY');
        } else if (startDate.format('YYYY') === endDate.format('YYYY')) {
          niceDate = `${startDate.format('MMMM')} - ${endDate.format('MMMM, YYYY')}`;
        } else {
          niceDate = `${startDate.format('MMMM, YYYY')} - ${endDate.format('MMMM, YYYY')}`;
        }
        return niceDate;
      }
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('niceDocType', (HwtsUiUtil) => {
      const docTypeMap = HwtsUiUtil.contentTypeLabelMap;
      return (docType) => docTypeMap[docType] || 'Document';
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('prodtechInfoLabel', (HwtsUiUtil) => {
      const map = HwtsUiUtil.prodtechDetailLabelMap;
      return (section) => (map[section] ? map[section].label : section);
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('prodtechInfoUnit', (HwtsUiUtil) => {
      const map = HwtsUiUtil.prodtechDetailLabelMap;
      return (unit, section) => {
        let ret = '';
        if (unit && unit.length > 0) {
          ret = unit;
        } else if (map[section] && map[section].defaultUnits) {
          ret = map[section].defaultUnits;
        }
        return ret;
      };
    })
    // //////////////////////////////////////////////////////////////////////////
    .filter('trustAsHtml', ($sce) => (html) => $sce.trustAsHtml(html));
  // //////////////////////////////////////////////////////////////////////////
})();
