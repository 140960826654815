/* eslint-disable func-names */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */

(function() {
  angular.module('app').controller('MainAppController', [
    '$rootScope',
    '$scope',
    function($rootScope, $scope) {
      $scope.scroll = 0;

      // browsing language toggle
      $scope.SetLanguage = function(language) {
        document.cookie = 'l=' + language + ';path=/';
        location.reload(true);
      };

      $scope.browsingLanguage = $rootScope.browsingLanguage;
      $scope.currentLanguage = $rootScope.browsingLanguage.toUpperCase();
    },
  ]);
})();
