import angular from 'angular';

angular.module('app.routes', ['ncy-angular-breadcrumb']).config([
  '$stateProvider',
  '$urlRouterProvider',
  function ($stateProvider, $urlRouterProvider) {
    //
    // Return to the page from which the user logged in
    $urlRouterProvider.when('/callback', () => {
      // 'nextPathAfterLogin` is set in authService.signIn() when the user starts the signin
      // process.
      const nextPathAfterLogin = JSON.parse(window.localStorage.getItem('nextPathAfterLogin')) || '/';

      // `nextPathAfterLogin` is only needed once, so remove it from the store.
      window.localStorage.removeItem('nextPathAfterLogin');
      return nextPathAfterLogin;
    });

    $urlRouterProvider.otherwise('/404');

    $stateProvider

      .state('home', {
        url: '/',
        ncyBreadcrumb: {
          label: 'Home',
        },
        views: {
          '': {
            templateUrl: 'views/home.html',
            controller: 'HomePageCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

      .state('signin', {
        url: '/signin',
        controller: 'AuthCtrl',
        templateUrl: '/views/signin.html',
      })

      .state('search', {
        url: '/search?query&page&countries&products&resourceTypes&technologies&emergency',
        views: {
          '': {
            templateUrl: 'views/search.html',
            controller: 'SearchPageCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
        reloadOnSearch: false,
      })

      .state('prodtech', {
        url: '/products-technologies',
        ncyBreadcrumb: {
          label: 'Products & Technologies',
        },
        views: {
          '': {
            templateUrl: 'views/prodtech-list.html',
            controller: 'ProdTechListCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

      .state('prodtech.detail', {
        url: '/:prodtechID/:prodtechSlug',
        ncyBreadcrumb: {
          label: '{{ prodtechTitle }}',
          parent: 'prodtech',
        },
        templateUrl: '/views/prodtech-detail.html',
        controller: 'ProdTechDetailCtrl',
      })

      .state('prodtech.detail.techInfo', {
        url: '/technical-information',
        ncyBreadcrumb: {
          label: 'Technical Information',
        },
        templateUrl: '/views/prodtech-info.html',
        controller: 'ProdTechInfoCtrl',
      })

      .state('projects', {
        url: '/projects',
        ncyBreadcrumb: {
          label: 'Projects',
        },
        views: {
          '': {
            templateUrl: 'views/projects-map.html',
            controller: 'ProjectsPageCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
        },
      })

      .state('projects.detail', {
        url: '/:projectID/:projectSlug',
        ncyBreadcrumb: {
          label: '{{ projectTitle }}',
          parent: 'projects',
        },
        views: {
          '': {
            templateUrl: '/views/project.html',
            controller: 'ProjectDetailPageCtrl',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

      .state('country', {
        url: '/country-focus',
        ncyBreadcrumb: {
          label: 'Country Focus',
        },
        views: {
          '': {
            templateUrl: 'views/country-list.html',
            controller: 'CountryListPageCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

      .state('country.detail', {
        url: '/:slugId/:slug',
        ncyBreadcrumb: {
          label: '{{ breadcrumbCountryName }}',
        },
        templateUrl: 'views/country-detail.html',
        controller: 'CountryDetailPageCtrl',
      })

      .state('emergency', {
        url: '/emergency',
        ncyBreadcrumb: {
          label: 'HWTS in Emergencies',
        },
        views: {
          '': {
            templateUrl: 'views/emergency.html',
            controller: 'EmergencyCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

      .state('theme', {
        url: '/theme/:themeSlug',
        ncyBreadcrumb: {
          label: '{{ themeTitle }}',
        },
        views: {
          '': {
            templateUrl: 'views/theme.html',
            controller: 'ThemesCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

      .state('content', {
        url: '/:contentType/:contentId/:contentSlug',
        ncyBreadcrumb: {
          label: '{{ contentTitle }}',
        },
        views: {
          '': {
            templateUrl: '/views/content.html',
            controller: 'ContentPageCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

      .state('form', {
        url: '/form/:form?pid&loc&pt',
        views: {
          '': {
            templateUrl: '/views/forms.html',
            controller: 'FormsCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

      .state('intro', {
        url: '/the-big-picture',
        ncyBreadcrumb: {
          label: 'The Big Picture',
        },
        views: {
          '': {
            templateUrl: 'views/intro.html',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

    // Promotional Pages
      .state('promocolombia', {
        url: '/tandas',
        views: {
          '': {
            templateUrl: 'views/promotional/promo-colombia.html',
            controller: 'PromoColumbiaCtrl',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

    // HWTS Network landing page
      .state('network', {
        url: '/network',
        ncyBreadcrumb: {
          label: 'The HWTS Network',
        },
        views: {
          'header@': {
            templateUrl: '/views/partials/_hwts_network_header.html',
          },
          '': {
            templateUrl: '/views/network/home.html',
            controller: 'HwtsNetworkCtrl',
          },
          'footer@': {
            templateUrl: '/views/partials/_hwts_network_footer.html',
          },
        },
      })
      .state('network.about', {
        url: '/about',
        ncyBreadcrumb: {
          label: 'About',
        },
        views: {
          '': {
            templateUrl: '/views/network/about.html',
            controller: 'HwtsNetworkCtrl',
          },
        },
      })
      .state('network.becomeamember', {
        url: '/become-a-member',
        ncyBreadcrumb: {
          label: 'Become a Member',
        },
        views: {
          '': {
            templateUrl: '/views/network/become-a-member.html',
            controller: 'HwtsNetworkCtrl',
          },
        },
      })
      .state('network.forum', {
        url: '/forum',
        ncyBreadcrumb: {
          label: 'Discussion Forum',
        },
        views: {
          '': {
            templateUrl: '/views/network/forum.html',
            controller: 'HwtsNetworkCtrl',
          },
        },
      })
      .state('network.news', {
        url: '/news',
        ncyBreadcrumb: {
          label: 'News & Events',
        },
        views: {
          '': {
            templateUrl: '/views/network/news.html',
            controller: 'HwtsNetworkNewsCtrl',
          },
        },
      })
      .state('network.sanitary', {
        url: '/sanitary-inspection-forms',
        ncyBreadcrumb: {
          label: 'Sanitary Inspection Forms',
        },
        views: {
          '': {
            templateUrl: '/views/network/sanitary-inspection-forms.html',
            controller: 'HwtsNetworkCtrl',
          },
        },
      })
      .state('network.whitepaper', {
        // TODO: Remove once Storyblok is implemented, will be replaced
        url: '/whitepaper',
        ncyBreadcrumb: {
          label: 'HWTS & Markets white paper',
        },
        views: {
          '': {
            templateUrl: '/views/network/whitepaper.html',
            controller: 'HwtsNetworkCtrl',
          },
        },
      })
      .state('network.contact', {
        url: '/contact',
        ncyBreadcrumb: {
          label: 'Contact',
        },
        views: {
          '': {
            templateUrl: '/views/network/contact.html',
            controller: 'HwtsNetworkCtrl',
          },
        },
      })

    // Colombia e-Learning Prototype
      .state('learn', {
        url: '/learn',
        views: {
          '': {
            templateUrl: '/views/learn/home.html',
            controller: 'LearnHomeCtrl',
          },
          'header@': {
            templateUrl: '/views/learn/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })
      .state('learn.lesson', {
        url: '/:id',
        views: {
          '': {
            templateUrl: '/views/learn/lesson.html',
            controller: 'LearnLessonCtrl',
          },
          'header@': {
            templateUrl: '/views/learn/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      })

    // 404 error
      .state('404', {
        url: '/404',
        ncyBreadcrumb: {
          label: 'Not Found',
        },
        views: {
          '': {
            controller: '404ErrorCtrl',
            templateUrl: 'views/404.html',
          },
          'header@': {
            templateUrl: '/views/partials/_header.html',
          },
          'footer@': {
            templateUrl: '/views/partials/_footer.html',
          },
        },
      }); // end $stateProvider
  },
]); // end module app.routes
