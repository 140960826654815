
import Vue from 'vue';
import * as CSS from 'csstype';

import type { Lesson } from '@src/client/vue-app/types/Lesson';

import { defaultLocale } from '@src/client/vue-app/config';
import { getSupportedLocaleCodes } from '@src/client/vue-app/util/getSupportedLocaleCodes';
import { getLessonResourceUrl } from '@src/client/vue-app/util/getLessonResourceUrl';

export default Vue.extend({
  name: 'LessonListItem',
  props: {
    lesson: {
      type: Object as () => Lesson,
      required: true,
    },
    locale: {
      type: String,
      default: defaultLocale,
    },
  },
  computed: {
    resourceUrl(): string {
      return getLessonResourceUrl(this.lesson, this.locale);
    },
    styles(): CSS.Properties {
      return {
        background: `linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.5)), url(${
          this.lesson.images.preview
        })`,
      };
    },
    supportedLocales(): string[] {
      return getSupportedLocaleCodes(this.lesson);
    },
  },
});
