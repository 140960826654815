/* eslint-disable no-param-reassign */

import angular from 'angular';

(() => {
  angular //
    .module('app') //
    .controller('LearnHomeCtrl', [
      '$rootScope',
      '$scope',
      '$window',
      ($rootScope, $scope) => {
        $scope.browsingLanguage = $rootScope.browsingLanguage;
      },
    ]);
})();
