
import Vue from 'vue';

export default Vue.extend({
  name: 'BaseSelect',
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getItemValue(item): string {
      // If the item value is explicitly '', we should accept it even though it is falsy
      if (item.value === '') {
        return '';
      }

      return item.value || item.label;
    },
    onChange(changeEvent) {
      this.$emit('change', changeEvent);
    },
  },
});
