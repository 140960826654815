// NOTE: While this file is currently being used in an AngularJS filter factory,
//   we will eventually migrate it over to Vue - so this seems like the appropriate
//   location.
import Fuse from 'fuse.js';

import { SearchableProdTech } from '@src/client/vue-app/types/SearchableProdTech';

import { config } from '@src/client/vue-app/config';

export function fuzzyProdTechSearch(
  prodTechs: SearchableProdTech[],
  searchString: string,
): SearchableProdTech[] {
  // SAFETY CHECK: If we are passed a healthy source with no search string, do nothing
  if (!searchString && prodTechs) {
    return prodTechs;
  }

  // SAFETY CHECK: If we have an unhealthy array, return an empty array
  if (!prodTechs || prodTechs.length === 0) {
    return [];
  }

  const fuse = new Fuse(prodTechs, config.prodTechFuzzySearchOptions);

  const results = fuse.search(searchString);

  return results.map((result) => result.item);
}
