import angular from 'angular';

angular.module('app', [
  'angular-storage',
  'angularCSS',
  'angular.filter',
  'ncy-angular-breadcrumb',

  'ngAnimate',
  'ngCookies',
  'ngResource',
  'ngSanitize',
  'ngTagsInput',
  'ngTouch',
  'ngVue',

  'ui.bootstrap',
  'ui.router',

  'auth0.auth0',

  'uiGmapgoogle-maps',

  'app.routes',
  'app.services',
  'app.filters',
  'app.directives',
  'vue.components',
]);
