import angular from 'angular';

angular
  .module('app')
  // -- Remove hashbang
  .config([
    '$locationProvider',
    function ($locationProvider) {
      $locationProvider.html5Mode(true).hashPrefix('!');
    },
  ])

  // -- Breadcrumb Menu config
  .config([
    '$breadcrumbProvider',
    function ($breadcrumbProvider) {
      $breadcrumbProvider.setOptions({
        prefixStateName: 'home',
        templateUrl: 'views/partials/_breadcrumbs.html',
      });
    },
  ])

  // -- Pretty scrolling config
  .config([
    '$provide',
    function ($provide) {
      $provide.decorator('$uiViewScroll', function () {
        return function (uiViewElement) {
          // FIXME: Angular Animate is not recognized by TS
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          angular.element('html,body').animate(
            {
              scrollTop: uiViewElement.offset().top - 90,
            },
            500,
          );
        };
      });
    },
  ]);
