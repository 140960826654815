/* eslint-disable no-console */

// Parameter reassignment is common in AngularJS services
/* eslint-disable no-param-reassign */

angular.module('app.services').service('GoogleMapUtil', [
  '$http',
  '$rootScope',
  'store',
  'uiGmapGoogleMapApi',
  function ($http, $rootScope, store, uiGmapGoogleMapApi) {
    // //////////////////////////////////////////////////////////////////////
    // # Projects ///////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////
    let hwtsProjects = store.get('hwtsProjectsV3');
    const timestamp = window._.get(hwtsProjects, 'timestamp');
    let loaded = false;
    if (!timestamp || window.moment().isSameOrAfter(timestamp)) {
      hwtsProjects = {
        timestamp: window
          .moment()
          .add(7, 'days')
          .toDate(),
        projects: [
          {
            id: 10000,
            latitude: -179,
            longitude: 1,
          },
        ],
      };
      if (!loaded) {
        $http
          .get($rootScope.api_url + '/public/projects')
          .then((response) => {
            if (!response || !response.data) {
              return;
            }

            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            hwtsProjects.projects = response.data ? processProjects(response.data) : [];
            store.set('hwtsProjectsV3', hwtsProjects);
            loaded = true;
          })
          .catch((err) => {
            // MAYBEDO: Handle the error
            console.error(err);
          });
      }
    } else {
      loaded = true;
    }
    // //////////////////////////////////////////////////////////////////////
    function getLat(locations) {
      return window._.get(
        locations,
        '[0].geolocation.coordinates[1]',
        window._.get(locations, '[0].geolocation.latitude'),
      );
    }
    // //////////////////////////////////////////////////////////////////////
    function getLng(locations) {
      return window._.get(
        locations,
        '[0].geolocation.coordinates[0]',
        window._.get(locations, '[0].geolocation.longitude'),
      );
    }
    // ///////////////////////////////////////////////////////////////////
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function processProjects(projects: any[]) {
      projects.forEach((project, index) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        projects[index] = processProject(project);
      });
      return projects;
    }
    // //////////////////////////////////////////////////////////////////////
    function processProject(project) {
      project.organization = window._.get(project, 'organizations[0].name', null);
      project.latitude = getLat(project.locations);
      project.longitude = getLng(project.locations);
      project.countries = window._.get(project, 'locations[0].countryName', null);
      return project;
    }
    // //////////////////////////////////////////////////////////////////////
    function getProjects(cb) {
      const waitTimer = setInterval(() => {
        if (loaded) {
          clearInterval(waitTimer);
          cb(hwtsProjects.projects);
        }
      }, 500);
    }
    // //////////////////////////////////////////////////////////////////////
    function getProject(projectID, cb) {
      $http
        .get($rootScope.api_url + '/public/project/' + projectID)
        .then((response) => {
          if (response && response.data) {
            cb(processProject(response.data));
          } else {
            cb(null);
          }
        })
        .catch((response) => {
          // MAYBEDO: Handle the error
          console.error(response);
          cb(null);
        });
    }
    // //////////////////////////////////////////////////////////////////////
    // # Map ////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////
    const map = {
      center: {
        latitude: 1.51,
        longitude: -1.76,
      },
      zoom: 2,
      control: {},
      window: {
        model: null,
        show: false,
        closeClick() {
          this.show = false;
        },
      },
    };
      // //////////////////////////////////////////////////////////////////////
    const mapOptions = {
      scrollwheel: false,
      panControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      minZoom: 2,
      maxZoom: 15,
      zoomControl: true,
      zoomControlOptions: {},
    };

    let typeOfMarker = 'cluster';

    const markerOptions = {
      icon: '../../images/marker_icon.png',
    };

      interface SpiderfierOptionData {
        keepSpiderfied: boolean;
        legWeight: number;
        nearbyDistance: number;
      }

      interface ClusterOptionData {
        zoomOnClick: boolean;
        maxZoom: number;
        gridSize: number;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        styles: any[];
      }

      type IMapTypeOptionData = SpiderfierOptionData | ClusterOptionData;

      const spiderfierOptions: SpiderfierOptionData = {
        keepSpiderfied: true,
        legWeight: 1,
        nearbyDistance: 30,
      };

      const clusterOptions: ClusterOptionData = {
        zoomOnClick: true,
        maxZoom: 6,
        gridSize: 40,
        styles: [
          {
            textColor: '#333333',
            url: '../../images/cluster_icon.png',
            height: 50,
            width: 50,
          },
        ],
      };

      // Points to the active options
      let typeOptions: SpiderfierOptionData | ClusterOptionData = clusterOptions;
      // //////////////////////////////////////////////////////////////////////
      const mapEvents = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        zoom_changed(thisMap) {
          const zoomLevel = thisMap.getZoom();
          if (zoomLevel >= 6) {
            typeOfMarker = 'spider';
            typeOptions = spiderfierOptions;
          } else {
            typeOfMarker = 'cluster';
            typeOptions = clusterOptions;
          }
        },
      };

      const markerEvents = {
        click(_marker, _eventName, model) {
          map.window.model = model;
          map.window.show = true;
        },
      };
      // //////////////////////////////////////////////////////////////////////
      uiGmapGoogleMapApi
        .then(() => {
          mapOptions.zoomControlOptions = {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM,
          };

          // http://angular-ui.github.io/angular-google-maps/#!/api/GoogleMapApi
          // uiGmapGoogleMapApi is a promise.
          // The "then" callback function provides the google.maps object
          // Some of the directives require at least something to be defined originally,
          // e.g. $scope.markers = []
        })
        .catch((err) => {
          // MAYBEDO: Handle the error
          console.error(err);
        });
      // //////////////////////////////////////////////////////////////////////
      const mapSettings = {
        map,
        mapOptions,
        typeOfMarker,
        markerOptions,
        spiderfierOptions,
        clusterOptions,
        typeOptions,
        mapEvents,
        markerEvents,
      };
      function getMapSettings() {
        return window._.clone(mapSettings);
      }
      // //////////////////////////////////////////////////////////////////////
      return {
        getMapSettings,
        getProject,
        getProjects,
        processProjects,
      };
  },
]);
