import { Lesson } from '@src/client/vue-app/types/Lesson';
import { defaultLocale, environment } from '@src/client/vue-app/config';

function getEnvSensitiveModuleLink(lesson: Lesson, locale: string): string {
  const bucketName = environment.isProduction() ? 'cawst-hwts-prod' : 'cawst-hwts-dev';
  const userId = environment.getSignedInUserId();
  let verifiedLocale: string;

  if (!lesson.supportedLocales || lesson.supportedLocales.length === 0) {
    verifiedLocale = defaultLocale;
  } else {
    verifiedLocale = lesson.supportedLocales.includes(locale)
      ? locale
      : lesson.supportedLocales[0];
  }

  const baseUrl = `https://${bucketName}.s3-us-west-2.amazonaws.com/files/learn/articulate-lesson-files`;
  const idSuffix = userId ? `?u=${encodeURIComponent(userId)}` : '';

  return `${baseUrl}/${lesson.slug}/${verifiedLocale}/index.html${idSuffix}`;
}

function getLocaleSensitiveResourceUrl(lesson: Lesson, locale: string): string {
  let verifiedLocale: string;

  if (!lesson.resourceUrls) {
    verifiedLocale = defaultLocale;
  } else {
    verifiedLocale = Object.keys(lesson.resourceUrls).includes(locale)
      ? locale
      : defaultLocale;
  }

  return lesson.resourceUrls[verifiedLocale];
}

export function getLessonResourceUrl(lesson: Lesson, locale: string): string {
  const foundLesson = lesson;

  // Embedded modules need their resource urls constructed dependent on the environment
  if (foundLesson && foundLesson.lessonType === 'embedded-module') {
    return getEnvSensitiveModuleLink(foundLesson, locale);
  }

  const resourceUrlLessons = ['embedded-video', 'podcast-sound-cloud'];
  if (foundLesson && resourceUrlLessons.includes(foundLesson.lessonType)) {
    return getLocaleSensitiveResourceUrl(foundLesson, locale);
  }

  return '';
}
