/* eslint-disable no-param-reassign */

import angular from 'angular';

import {
  contaminantEffectiveToken,
  contaminantVeryEffectiveToken,
} from '@src/client/vue-app/filters/data';
import {
  WaterQualityParameters,
  waterQualityParameters,
} from '@src/client/vue-app/data/waterQualityParameters';
import { fuzzyProdTechSearch } from '@src/client/vue-app/filters/fuzzyProdTechSearch';
import { filterProdTechByContaminant } from '@src/client/vue-app/filters/filterProdTechByContaminant';
import { filterProdTechByProgression } from '@src/client/vue-app/filters/filterProdTechByProgression';
import { filterProdTechByTechnology } from '@src/client/vue-app/filters/filterProdTechByTechnology';
import { filterProdTechByTreatmentMethod } from '@src/client/vue-app/filters/filterProdTechByTreatmentMethod';
import { sortProdTechs } from '@src/client/vue-app/filters/sortProdTechs';
import { ProdTechSearchFilters } from '@src/client/vue-app/types/ProdTechSearchFilters';
import { ProdTech } from '@src/client/vue-app/types/ProdTech';
import { SelectItem } from '@src/client/vue-app/types/SelectItem';
import { captureErrorMessage } from '@src/client/scripts/util/captureErrorMessage';

function computeContaminantGroups(prodTechList?): WaterQualityParameters[] {
  if (!prodTechList) {
    return waterQualityParameters;
  }

  return waterQualityParameters.map(({ groupName, items }) => ({
    groupName,
    items: items.filter((item) => prodTechList.some((prodTech) => (
      prodTech
            && prodTech.technicalInformation
            && prodTech.technicalInformation.treatmentCapacity
            && prodTech.technicalInformation.treatmentCapacity.some((capacityEntry) => (
              capacityEntry.parameter === item.key
                && (capacityEntry.effectiveness === contaminantVeryEffectiveToken
                  || capacityEntry.effectiveness === contaminantEffectiveToken)
            ))
    )),),
  }));
}

(() => {
  angular.module('app').controller('ProdTechListCtrl', [
    '$element',
    '$http',
    '$rootScope',
    '$scope',
    '$uiViewScroll',
    '$uibModal',
    'HwtsUiUtil',
    'MetaTagsUtil',
    ($element, $http, $rootScope, $scope, $uiViewScroll, $uibModal, HwtsUiUtil, MetaTagsUtil) => {
      // //////////////////////////////////////////////////////////////////////
      $scope.loadingProdTechList = true;
      $scope.prodtechMainImageUri = HwtsUiUtil.prodtechMainImageUri;
      $scope.contaminantGroups = computeContaminantGroups();
      $scope.viewType = 'list';
      $scope.prodtechlist = [] as ProdTech[];
      $scope.technologies = [] as ProdTech[];
      $scope.selectableTechnologies = [] as SelectItem[];
      $scope.filteredProdTechs = [] as ProdTech[];

      // FIXME: ng-model won't respect primitives, so grouping these
      //   traits for now. Convert to component when possible.
      $scope.paginatedResults = {
        currentPage: 1,
        itemsPerPage: 10,
        prodTechs: [] as ProdTech[],
        resultsMessage: '',
      };

      $scope.filterProdTech = {} as ProdTechSearchFilters;
      // //////////////////////////////////////////////////////////////////////
      $scope.updateFilteredProdTechs = () => {
        let result = $scope.prodtechlist;
        result = fuzzyProdTechSearch(result, $scope.filterProdTech.search);
        result = filterProdTechByTechnology(result, $scope.filterProdTech.technology);
        result = filterProdTechByContaminant(result, $scope.filterProdTech.contaminant);
        result = filterProdTechByProgression(result, $scope.filterProdTech.progression);
        result = filterProdTechByTreatmentMethod(result, $scope.filterProdTech.method);
        result = sortProdTechs(result);

        $scope.filteredProdTechs = result;
      };

      $scope.updatePaginatedProdTechs = () => {
        const { currentPage, itemsPerPage } = $scope.paginatedResults;

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, $scope.filteredProdTechs.length);
        const prodTechs = $scope.filteredProdTechs.slice(startIndex, endIndex);

        const resultsMessage = startIndex === 0 && endIndex === 0
          ? 'Showing 1 of 1 result'
          : `Showing ${startIndex + 1}-${endIndex} of ${$scope.filteredProdTechs.length} results`;

        // FIXME: ng-model does not respect parameter reassignment and object
        //  references must be broken. Fix for this may just be migrating to Vue
        //  https://docs.angularjs.org/api/ng/directive/ngModel
        $scope.paginatedResults = {
          ...$scope.paginatedResults,
          prodTechs,
          resultsMessage,
        };
      };

      $scope.resetPagination = () => {
        // FIXME: ng-model does not respect parameter reassignment and object
        //  references must be broken. Fix for this may just be migrating to Vue
        //  https://docs.angularjs.org/api/ng/directive/ngModel
        $scope.paginatedResults = {
          ...$scope.paginatedResults,
          currentPage: 1,
        };
        $scope.updatePaginatedProdTechs();
      };

      $scope.onChangePage = () => {
        $scope.updatePaginatedProdTechs();
        $uiViewScroll($element.find('#top-of-page'));
      };

      $scope.onOpenProgressionDetails = () => {
        const modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'progressionInfoModal.tpl.html',
          controller: [
            '$scope',
            '$uibModalInstance',
            // eslint-disable-next-line no-shadow
            ($scope, $uibModalInstance) => {
              $scope.cancel = () => {
                $uibModalInstance.dismiss('cancel');
              };
            },
          ],
          size: 'md',
          backdrop: true,
        });

        // FIXME: Callbacks are required to prevent promise rejection errors from being thrown
        //   when the modal is closed.
        modalInstance.result.then(
          () => { /* no op */ },
          () => { /* no op */ },
        );
      };

      $scope.resetForm = () => {
        $scope.filterProdTech = {};
        $scope.updateFilteredProdTechs();
        $scope.resetPagination();
      };

      $scope.onUpdateFilters = (newFilters: ProdTechSearchFilters) => {
        $scope.filterProdTech = {
          ...$scope.filterProdTech,
          ...newFilters,
        };
        $scope.updateFilteredProdTechs();
        $scope.resetPagination();
      };

      $scope.onUpdateViewType = (value: string) => {
        $scope.viewType = value;
      };

      $scope.onScrollToNote = () => {
        $uiViewScroll($element.find('#contaminant-note'));
      };

      $scope.onUpdateSidebarTechnologyFilter = (technologyId) => {
        // If the technology is already selected, then toggle it off
        const value = $scope.filterProdTech.technology !== technologyId ? technologyId : '';

        $scope.onUpdateFilters({ technology: value });
      };

      // //////////////////////////////////////////////////////////////////////
      // FIXME: Attempting to convert to `async / await` syntax causes the initial load to be
      //   much slower... why?
      $http.get(`${$rootScope.api_url}/public/technology-product`).then(
        (response) => {
          if (response && response.data) {
            $scope.prodtechlist = response.data;

            if (response.data.length === 0) {
              captureErrorMessage('VWET API returned an empty array of prod-techs');
            }

            $scope.contaminantGroups = computeContaminantGroups($scope.prodtechlist);

            $scope.technologies = $scope.prodtechlist
              .filter(({ type }) => type === 'technology')
              .sort((a, b) => a.title.localeCompare(b.title));
            $scope.selectableTechnologies = $scope.technologies.map((tech) => ({
              value: tech.id,
              label: tech.title,
            }));

            if (!$scope.$$phase) {
              $scope.$apply();
            }
          } else {
            captureErrorMessage('VWET API returned a falsy data set');
          }
          $scope.loadingProdTechList = false;
          $scope.updateFilteredProdTechs();
          $scope.updatePaginatedProdTechs();
        },
        () => {
          captureErrorMessage('HWTS ProdTech data fetch was rejected');
        },
      );
      // //////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle:
          'Products and Technologies | Household Water Treatment and Safe Storage Knowledge Base',
        ogDescription:
          'The single largest collection of point-of-use treatment solutions. Find fact sheets, frequently asked questions and latest research.',
        ogUrl: 'products-technologies',
        ogImage: 'images/social/hwts_social_media_prodtech.jpg',
      });
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
