
import Vue from 'vue';

import ProdTechResultsListItem from './ProdTechResultsListItem.vue';

export default Vue.extend({
  name: 'ProdTechResultsList',
  components: { ProdTechResultsListItem },
  props: {
    prodTechs: {
      type: Array,
      required: true,
    },
  },
});
