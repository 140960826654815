/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */

/* eslint-disable func-names */

(function() {
  angular.module('app').controller('CountryDetailPageCtrl', [
    '$element',
    '$http',
    '$rootScope',
    '$scope',
    '$state',
    '$uiViewScroll',
    'GoogleMapUtil',
    'MetaTagsUtil',
    function(
      $element,
      $http,
      $rootScope,
      $scope,
      $state,
      $uiViewScroll,
      GoogleMapUtil,
      MetaTagsUtil,
    ) {
      // @TODO Don't send file information for research
      // //////////////////////////////////////////////////////////////////////
      var countryId = $state.params.slugId ? $state.params.slugId : null;

      if (!countryId) {
        $state.go('country');
      }
      // //////////////////////////////////////////////////////////////////////
      $scope.countryDetail = {
        data: {},
        sectionLength: 4,
        sections: {
          government: {
            id: 'government',
            show: false,
            title: 'Government Documents',
            items: [],
          },
          keyitems: {
            id: 'keyitems',
            show: false,
            title: 'Other Key Information',
            items: [],
          },
          prodtech: {
            id: 'prodtech',
            show: false,
            title: 'Products and Technologies',
            subtitle: 'Implemented in this country',
            items: [],
          },
          project: {
            id: 'project',
            show: false,
            title: 'Projects',
            items: [],
          },
          experience: {
            id: 'experience',
            show: false,
            title: 'Experiences',
            items: [],
          },
          'case-study': {
            id: 'case-study',
            show: false,
            title: 'Case Studies',
            items: [],
          },
          research: {
            id: 'research',
            show: false,
            title: 'Research',
            items: [],
          },
          document: {
            id: 'document',
            show: false,
            title: 'Other Documents',
            items: [],
          },
        },
        loading: true,
        loadingTop: true,
        collaborators: [],
      };
      // //////////////////////////////////////////////////////////////////////
      function setMetaTags() {
        MetaTagsUtil.SetSocialMetaTags({
          ogTitle:
            'Country: ' +
            $scope.countryDetail.data.name +
            ' (' +
            $scope.countryDetail.data.code +
            ')' +
            ' | Household Water Treatment and Safe Storage Knowledge Base',
          ogDescription: ' ',
          ogUrl: 'country-focus/' + countryId + '/' + $state.params.slug,
          ogImage: 'images/social/hwts_social_media_country.jpg',
        });
      }
      // //////////////////////////////////////////////////////////////////////
      function getCountryData(id, cb) {
        function updateSections(data) {
          var info = data.hwts_info;
          if (info) {
            if (info.keyItems && info.keyItems.length > 0) {
              $scope.countryDetail.sections.keyitems.items = info.keyItems;
              // $scope.countryDetail.sections.keyitems.show = true;
            }
            if (info.prodTechs && info.prodTechs.length > 0) {
              $scope.countryDetail.sections.prodtech.items = info.prodTechs;
              $scope.countryDetail.sections.prodtech.show = true;
            }
            if (info.collaborators && info.collaborators.length > 0) {
              $scope.countryDetail.collaborators = info.collaborators;
            }
          }
          $scope.countryDetail.sections.keyitems.show = true;
        }

        $http
          .get($rootScope.api_url + '/public/country-focus/' + id)
          .then(function(response) {
            if (response && response.data) {
              $rootScope.breadcrumbCountryName = response.data.name;
              $scope.countryDetail.data = response.data;
              updateSections(response.data);
              setMetaTags();
              if (!$scope.$$phase) {
                $scope.$apply();
              }
              if (cb) {
                cb('Success:', response);
              }
            }
          })
          .catch(function(error) {
            if (cb) {
              cb('Error:', error);
            }
          });
      }
      // //////////////////////////////////////////////////////////////////////
      function getCountryContent(countryCode, contentType, cb) {
        function showIfEmpty() {
          var sections = ['experience', 'research'];
          if (sections.indexOf(contentType) > -1) {
            $scope.countryDetail.sections[contentType].show = true;
          }
          if (contentType === 'document') {
            $scope.countryDetail.sections.government.show = true;
          }
        }

        $http
          .get($rootScope.api_url + '/public/country-focus/' + countryCode + '/' + contentType)
          .then(function(response) {
            if (response && response.data) {
              if (response.data.length > 0) {
                if (contentType === 'project') {
                  const projects = response.data
                    ? GoogleMapUtil.processProjects(response.data)
                    : [];
                  var latitudes = [];
                  var longitudes = [];
                  projects.forEach(function(project) {
                    if (project.latitude) {
                      latitudes.push(project.latitude);
                    }
                    if (project.longitude) {
                      longitudes.push(project.longitude);
                    }
                  });
                  $scope.countryDetail.data.center = {
                    latitude: _.mean(latitudes),
                    longitude: _.mean(longitudes),
                  };
                  $scope.countryDetail.sections[contentType].items = projects;
                } else if (contentType === 'document') {
                  var govDocs = [];
                  var otherDocs = [];
                  response.data.forEach(function(doc) {
                    if (doc.type === 'policy_regulation') govDocs.push(doc);
                    else otherDocs.push(doc);
                  });
                  $scope.countryDetail.sections.government.items = govDocs;
                  $scope.countryDetail.sections.document.items = otherDocs;
                  $scope.countryDetail.sections.government.show = true;
                  $scope.countryDetail.sections.document.show = otherDocs.length > 0;
                } else {
                  $scope.countryDetail.sections[contentType].items = response.data;
                }

                $scope.countryDetail.sections[contentType].show = true;

                if (!$scope.$$phase) {
                  $scope.$apply();
                }
              } else {
                showIfEmpty();
              }

              console.log($scope.countryDetail);
              if (cb) {
                cb('Success:', response);
              }
            } else {
              console.log('no response');
            }
          })
          .catch(function(error) {
            if (cb) {
              cb('Error:', error);
            }
          });
      }
      // //////////////////////////////////////////////////////////////////////
      getCountryData(countryId, function() {
        $scope.countryDetail.loadingTop = false;
        getCountryContent($scope.countryDetail.data.code, 'document');
        getCountryContent($scope.countryDetail.data.code, 'project');
        getCountryContent($scope.countryDetail.data.code, 'case-study');
        getCountryContent($scope.countryDetail.data.code, 'research');
        getCountryContent($scope.countryDetail.data.code, 'experience');
        $scope.countryDetail.loading = false;
      });
      // //////////////////////////////////////////////////////////////////////
      $scope.countryDetail.scrollToSection = function(elementId) {
        $uiViewScroll($element.find('#' + elementId));
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.countryDetail.hasContent = function() {
        var hasContent = false;
        var country = $scope.countryDetail.data;
        var sections = $scope.countryDetail.sections;

        if (
          country.hwts_info &&
          country.hwts_info.keyItems &&
          country.hwts_info.keyItems.length > 0
        ) {
          hasContent = true;
        }

        if (
          country.hwts_info &&
          country.hwts_info.prodTechs &&
          country.hwts_info.prodTechs.length > 0
        ) {
          hasContent = true;
        }

        for (var key in sections) {
          if (sections[key].items.length > 0) {
            hasContent = true;
          }
        }

        return hasContent;
      };
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
