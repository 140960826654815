var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.prodTechLabels, function (label) {
      return _c(
        "base-label",
        {
          key: label,
          style: {
            display: "inline-block",
            fontSize: "1.4rem",
            marginRight: "10px",
            padding: "6px",
          },
          attrs: { level: "info", circular: "" },
        },
        [_vm._v("\n    " + _vm._s(label) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }