
import Vue from 'vue';

interface HeroVideo {
  sources: VideoSource[];
  poster?: string;
}

interface VideoSource {
  src: string;
  type: string;
}

export default Vue.extend({
  name: 'Hero',
  props: {
    imageClass: {
      type: String,
      default: 'img-home',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    minHeight: {
      type: Number,
      default: 480,
    },
    video: {
      type: Object as () => HeroVideo | null,
      default: null,
    },
  },
  computed: {
    styles(): string {
      const imageStyles = this.imageUrl ? `background-image: url('${this.imageUrl}')` : '';
      const heightStyles = this.minHeight ? `min-height: ${this.minHeight}px` : '';

      return [imageStyles, heightStyles].filter((item) => !!item).join('; ');
    },
  },
});
