/* eslint-disable func-names */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */

(function() {
  angular.module('app').controller('SearchPageCtrl', [
    '$http',
    '$rootScope',
    '$scope',
    '$state',
    'MetaTagsUtil',
    function($http, $rootScope, $scope, $state, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////
      $scope.searchQuery = $state.params.query ? $state.params.query : '';
      $scope.noResultsQuery = $state.params.query ? $state.params.query : '';
      $scope.currentPage = Number($state.params.page) ? Number($state.params.page) : 1;
      $scope.pageSize = 10;
      $scope.searchError = false;
      $scope.loadingResults = false;

      $scope.filters = {};
      $scope.filters.countries = $state.params.countries ? $state.params.countries.split(';') : [];
      $scope.filters.resourceTypes = $state.params.resourceTypes
        ? $state.params.resourceTypes.split(';')
        : [];
      $scope.filters.products = $state.params.products ? $state.params.products.split(';') : [];
      $scope.filters.technologies = $state.params.technologies
        ? $state.params.technologies.split(';')
        : [];

      $scope.filterLimit = 5;
      $scope.countriesLimit = $scope.filterLimit;
      $scope.productsLimit = $scope.filterLimit;
      $scope.technologiesLimit = $scope.filterLimit;
      $scope.resourceTypeLimit = $scope.filterLimit;
      // //////////////////////////////////////////////////////////////////////
      $http
        .get($rootScope.api_url + '/public/search/filters')
        .then((response) => {
          if (response && response.data) {
            const data = response.data;
            // Remove update, only used in BSFKB
            const index = data.resourceType.indexOf('update');
            if (index > -1) {
              data.resourceType.splice(index, 1);
            }
            $scope.searchFilters = data;
          }
        })
        .catch((_err) => {
          /* Error */
        });
      // //////////////////////////////////////////////////////////////////////
      $scope.searchHwtsKb = function(query) {
        // search:  /search/query/page/pageSize/language
        // filters: &filter[type]=value1;value2;value3
        $scope.loadingResults = true;
        var searchQuery = query ? query : '';
        var page = $scope.currentPage - 1;
        var pageSize = $scope.pageSize;
        var language = 'en';
        var countryFilter =
          $scope.filters.countries.length > 0
            ? '&filter[countries]=' + $scope.filters.countries.join(';')
            : '';
        var resourceTypeFilter =
          $scope.filters.resourceTypes.length > 0
            ? '&filter[resourceTypes]=' + $scope.filters.resourceTypes.join(';')
            : '';
        var productFilter =
          $scope.filters.products.length > 0
            ? '&filter[products]=' + $scope.filters.products.join(';')
            : '';
        var technologyFilter =
          $scope.filters.technologies.length > 0
            ? '&filter[technologies]=' + $scope.filters.technologies.join(';')
            : '';
        var emergencyFilter = $scope.emergencyToggle === true ? '&filter[emergency]=true' : '';

        $http
          .get(
            $rootScope.api_url +
              '/public/search?q=' +
              searchQuery +
              '&p=' +
              page +
              '&s=' +
              pageSize +
              '&l=' +
              language +
              countryFilter +
              resourceTypeFilter +
              productFilter +
              technologyFilter +
              emergencyFilter,
          )
          .then(
            function(response) {
              if (response && response.data) {
                var data = response.data;
                $scope.searchError = false;
                $scope.searchResults = data.hits.hits;
                $scope.totalResults = data.hits.total;
              } else {
                $scope.searchError = true;
                $scope.errorMessage =
                  'Error: could not retrieve search results at this time. If you continue having issues please contact us at support@cawst.org.';
              }
              $scope.loadingResults = false;
            },
            function() {
              $scope.searchError = true;
              $scope.errorMessage =
                'Error: could not retrieve search results at this time. If you continue having issues please contact us at support@cawst.org.';
            },
          );
      };

      $scope.submitSearch = function(query, page) {
        var searchQuery = query ? query : '';
        var pageLanding = page ? page : 1;
        var countries = $scope.filters.countries.join(';');
        var resourceTypes = $scope.filters.resourceTypes.join(';');
        var products = $scope.filters.products.join(';');
        var emergency = $scope.emergencyToggle === true ? $scope.emergencyToggle : '';
        var technologies = $scope.filters.technologies.join(';');
        $state.go(
          'search',
          {
            query: searchQuery,
            page: pageLanding,
            countries: countries,
            resourceTypes: resourceTypes,
            products: products,
            technologies: technologies,
            emergency: emergency,
          },
          { reload: true },
        );
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.filterSelected = function(filter, value) {
        var filterSelected = $scope.filters[filter].indexOf(value) >= 0 ? true : false;
        return filterSelected;
      };

      $scope.addRemoveFilter = function(filter, value) {
        if (filter === 'emergency') {
          $scope.emergencyToggle = !$scope.emergencyToggle;
        } else {
          var filterExists = $scope.filters[filter].indexOf(value) >= 0 ? true : false;
          if (filterExists) {
            var index = $scope.filters[filter].indexOf(value);
            $scope.filters[filter].splice(index, 1);
          } else {
            $scope.filters[filter].push(value);
          }
        }
        $scope.submitSearch($scope.searchQuery, 1);
      };

      $scope.noFiltersAvailable = function(filters) {
        if (
          filters.countries.length > 0 ||
          filters.resourceTypes.length > 0 ||
          filters.products.length > 0 ||
          filters.technologies.length > 0 ||
          !filters.emergency
        ) {
          return false;
        }
        return true;
      };
      // //////////////////////////////////////////////////////////////////////
      $scope.$on('$viewContentLoaded', function() {
        $scope.searchHwtsKb($scope.searchQuery);
      });
      // //////////////////////////////////////////////////////////////////////
      function getSearchUrl(params) {
        var page = Number(params.page) ? Number(params.page) : 1;
        var url = 'search?page=' + page;

        if (params.query) {
          url += '&query=' + params.query;
        }
        if (params.countries) {
          url += '&countries=' + params.countries;
        }
        if (params.resourceTypes) {
          url += '&resourceTypes=' + params.resourceTypes;
        }
        if (params.products) {
          url += '&products=' + params.products;
        }
        if (params.technologies) {
          url += '&technologies=' + params.technologies;
        }
        if (params.emergency === true) {
          url += '&emergency=' + params.emergency;
        }

        return url;
      }

      MetaTagsUtil.SetSocialMetaTags({
        ogTitle: 'Search Results | Household Water Treatment and Safe Storage Knowledge Base',
        ogDescription:
          'Search the Household Water Treatment and Safe Storage Knowledge Base. Point-of-use water treatment solutions, from technology options to implementation best practices. Find everything and share your experience.',
        ogUrl: getSearchUrl($state.params),
      });
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
