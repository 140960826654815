var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "AlertBlock",
    {
      attrs: {
        title: "Our learning platform is still in development!",
        permanent: "",
      },
    },
    [
      _c("div", { staticClass: "margin-bottom-15" }, [
        _c("p", [
          _vm._v(
            "More helpful features will be coming in the next few weeks and months, including:"
          ),
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("Multi-language support (Spanish, French)")]),
          _vm._v(" "),
          _c("li", [_vm._v("Module content updates")]),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Any comments and feedback to help us improve are appreciated."
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn-u btn-u-orange",
          attrs: { href: "mailto:support@cawst.org" },
        },
        [_vm._v("Send us feedback")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }