interface RateData {
  lowerRange?: number;
  upperRange?: number;
  unit?: string;
  comments?: string;
}

export const defaultMessage = 'Not applicable';

export function formatRateDataMessage(data: RateData): string {
  const { lowerRange, upperRange, unit, comments } = data;

  if (lowerRange && upperRange && unit && comments) {
    return `${lowerRange} - ${upperRange} ${unit} (${comments})`;
  }

  if (lowerRange && upperRange && unit) {
    return `${lowerRange} - ${upperRange} ${unit}`;
  }

  if ((lowerRange || upperRange) && unit && comments) {
    return `${lowerRange || upperRange} ${unit} (${comments})`;
  }

  if ((lowerRange || upperRange) && unit) {
    return `${lowerRange || upperRange} ${unit}`;
  }

  return comments || defaultMessage;
}
