import { createClient, SanityClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { hwtsNetworkSanityConfig } from '../../config/app.config';

// Flagging as Network CMS, since HWTS CMS will likely be a different data lake
const networkSanityClient = createClient(hwtsNetworkSanityConfig);
const networkImageBuilder = imageUrlBuilder(networkSanityClient);

export const networkSanity: {
  client: SanityClient;
  imageUrlBuilder: ImageUrlBuilder;
} = {
  client: networkSanityClient,
  imageUrlBuilder: networkImageBuilder,
};
