
import Vue, { PropOptions } from 'vue';

import { ProdTech } from '@src/client/vue-app/types/ProdTech';

export default Vue.extend({
  name: 'ProdTechResultsListItemMbaList',
  props: {
    prodTech: {
      type: Object,
      required: true,
    } as PropOptions<ProdTech>,
  },
  computed: {
    prodTechLabels(): string[] {
      return this.prodTech?.technicalInformation?.treatmentProcess || [];
    },
  },
});
