/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */

(function() {
  angular.module('app.directives').directive('searchListItem', [
    '$rootScope',
    '$sce',
    '$window',
    'HwtsUiUtil',
    function($rootScope, $sce, $window, HwtsUiUtil) {
      // //////////////////////////////////////////////////////////////////////
      function getPdfUri(item) {
        var uri = null;
        if (item.files && item.files.length > 0) {
          var idx = _.findIndex(item.files, { type: 'application/pdf' });
          if (idx > -1 && item.files[idx].uri) {
            uri = item.files[idx].uri;
          }
        }
        return uri;
      }
      // //////////////////////////////////////////////////////////////////////
      function setContentUrl(item, type) {
        if (type === 'featured') {
          type = HwtsUiUtil.hwtsItemTypeMap[item.hwtsItemType];
        }
        var url = '/';
        if (type === 'prodtech') {
          // /products-technologies/:prodtechID/:prodtechSlug
          url += 'products-technologies';
        } else if (type === 'project') {
          // /projects/:projectID/:projectSlug
          url += 'projects';
        } else if (type === 'keyitems') {
          url += 'document';
        } else {
          // /:contentType/:contentId/:contentSlug
          url += type;
        }
        url += '/' + item.slugId + '/' + item.slug;
        return url;
      }
      // //////////////////////////////////////////////////////////////////////
      function normalizeData(item, type) {
        if (type === 'featured') {
          type = HwtsUiUtil.hwtsItemTypeMap[item.hwtsItemType];
        }
        var normalizedItem = _.cloneDeep(item);
        normalizedItem.typeLabel = HwtsUiUtil.contentTypeLabelMap[type];
        if (type === 'case-study') {
          normalizedItem.pdfUri = getPdfUri(item);
        }
        if (type === 'document') {
          normalizedItem.pdfUri = getPdfUri(item);
          normalizedItem.url = item.websites && item.websites[0] ? item.websites[0].url : null;
        }
        if (type === 'prodtech') {
          normalizedItem.mainImageUrl = HwtsUiUtil.prodtechMainImageUri(item);
        }
        if (normalizedItem.description) {
          normalizedItem.description = $sce.trustAsHtml(normalizedItem.description);
        }
        return normalizedItem;
      }
      // //////////////////////////////////////////////////////////////////////
      function setIconClass(type, docType) {
        if (type === 'featured') {
          type = HwtsUiUtil.hwtsItemTypeMap[item.hwtsItemType];
        }
        var css = 'fa fa-file-o';
        var classes = {
          'case-study': 'fa fa-pencil',
          research: 'fa fa-graduation-cap',
          experience: 'fa fa-quote-left',
          faq: 'fa fa-question-circle',
          project: 'fa fa-map-marker',
        };
        var docClasses = {
          policy_regulation: 'fa fa-globe',
          website: 'fa fa-link',
        };
        if (type === 'document') {
          css = docClasses[docType] ? docClasses[docType] : css;
        } else {
          css = classes[type] ? classes[type] : css;
        }
        return css;
      }
      // //////////////////////////////////////////////////////////////////////
      return {
        restrict: 'E',
        templateUrl: '/scripts/directives/search_list_item.html',
        scope: {
          item: '=',
          type: '=',
        },
        link: function(scope) {
          scope.descLength = 500;
          scope.showPdfDownload = false;

          scope.normalizedItem = normalizeData(scope.item, scope.type);
          scope.itemUrl = setContentUrl(scope.item, scope.type);

          scope.setIconClass = setIconClass;
          scope.getPublicPdfDownload = HwtsUiUtil.getPublicPdfDownload;
        },
      };
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
