/* eslint-disable func-names */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable radix */
/* eslint-disable vars-on-top */

(function() {
  angular
    .module('app.services') //
    .service('FormUtil', [
      '$http',
      '$rootScope',
      function($http, $rootScope) {
        // //////////////////////////////////////////////////////////////////////
        function addProduct(newProduct, cb) {
          var promise = $http.post($rootScope.api_url + '/protected/product', newProduct);
          function onSuccess(response) {
            if (response) cb(true);
            else cb(false);
          }
          function onFailure(error) {
            cb(false);
          }
          promise.then(onSuccess, onFailure);
        }
        // //////////////////////////////////////////////////////////////////////
        function addExperience(newExperience, cb) {
          var promise = $http.post($rootScope.api_url + '/protected/experience', newExperience);
          function onSuccess(response) {
            if (response) cb(true);
            else cb(false);
          }
          function onFailure(error) {
            cb(false);
          }
          promise.then(onSuccess, onFailure);
        }
        // //////////////////////////////////////////////////////////////////////
        function addProject(newProject, cb) {
          var promise = $http.post($rootScope.api_url + '/protected/project', newProject);
          function onSuccess(response) {
            if (response) cb(true);
            else cb(false);
          }
          function onFailure(error) {
            cb(false);
          }
          promise.then(onSuccess, onFailure);
        }
        // //////////////////////////////////////////////////////////////////////
        function claimProject(input, cb) {
          console.log(input);
          var messageDetails = _.cloneDeep(input);
          messageDetails.name = messageDetails.contacts[0].fullName;
          messageDetails.email = messageDetails.contacts[0].email;
          messageDetails.organization = messageDetails.contacts[0].organization;
          var promise = $http.post($rootScope.api_url + '/protected/project/claim', messageDetails);
          function onSuccess(response) {
            if (response) cb(true);
            else cb(false);
          }
          function onFailure(error) {
            cb(false);
          }
          promise.then(onSuccess, onFailure);
        }
        // //////////////////////////////////////////////////////////////////////
        function getOrganizationList(value) {
          return $http
            .get($rootScope.api_url + '/public/lookup/organization?keywords=' + value)
            .then(function(response) {
              return response;
            });
        }
        // //////////////////////////////////////////////////////////////////////
        function getProductsList(value) {
          return $http.get($rootScope.api_url + '/public/products').then(function(response) {
            var products = response.data;
            return products.filter(function(product) {
              return product.title.toLowerCase().indexOf(value.toLowerCase()) !== -1;
            });
          });
        }
        // //////////////////////////////////////////////////////////////////////
        function getLocation(searchVal) {
          return $http
            .get('https://maps.googleapis.com/maps/api/geocode/json', {
              params: {
                address: searchVal,
                sensor: false,
              },
              // checked by the angular-jwt interceptor; if true, no Authorization header is sent.
              // Not used in new auth flow.
              // skipAuthorization: true,
            })
            .then(function(response) {
              var results = response.data.results;
              var ret = [];
              for (var i in results) {
                var newItem = {};
                for (var j in results[i].address_components) {
                  var comp = results[i].address_components[j];
                  if (comp.types && comp.types.indexOf('country') !== -1) {
                    newItem.countryCode = comp.short_name;
                    newItem.countryName = comp.long_name;
                    break;
                  }
                }
                newItem.displayName = results[i].formatted_address;
                newItem.geolocation = {
                  latitude: results[i].geometry.location.lat,
                  longitude: results[i].geometry.location.lng,
                };
                newItem.raw = results[i];
                var numbers = searchVal.split(',');
                if (
                  numbers.length === 2 &&
                  isNaN(parseInt(numbers[0])) === false &&
                  isNaN(parseInt(numbers[0])) === false
                )
                  newItem.displayName = '(' + searchVal + ') ' + results[i].formatted_address;
                ret.push(newItem);
              }
              return ret;
            });
        }
        // //////////////////////////////////////////////////////////////////////
        return {
          addProduct: addProduct,
          addExperience: addExperience,
          addProject: addProject,
          claimProject: claimProject,
          getOrganizationList: getOrganizationList,
          getProductsList: getProductsList,
          getLocation: getLocation,
        };
        // //////////////////////////////////////////////////////////////////////
      },
    ]);
})();
