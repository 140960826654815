/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */

(function() {
  angular.module('app').controller('PromoColumbiaCtrl', [
    '$scope',
    'MetaTagsUtil',
    function($scope, MetaTagsUtil) {
      // //////////////////////////////////////////////////////////////////////
      $scope.promoColombia = {};
      // //////////////////////////////////////////////////////////////////////
      // reading language settings (translation, localization)
      // @TODO: replace with global localization system when in place
      $scope.promoColombia.language = 'es';
      $scope.promoColombia.languages = [
        { value: 'es', label: 'Spanish' },
        { value: 'en', label: 'English' },
      ];

      $scope.promoColombia.changeLanguage = function(langValue) {
        var availableLanguages = $scope.promoColombia.languages;
        if (langValue && _.findIndex(availableLanguages, { value: langValue }) > -1) {
          $scope.promoColombia.language = langValue;
        }
      };
      // //////////////////////////////////////////////////////////////////////
      MetaTagsUtil.SetSocialMetaTags({
        ogTitle:
          'Primer Taller Regional en Latinoamérica: Avanzando hacia la agenda de seguridad del agua',
        ogDescription: 'Bogotá, Colombia, del 7 al 9 de mayo de 2018',
        ogUrl: 'tandas',
        ogImage: 'files/promotional/news1.jpg',
        ogImageWidth: 973,
        ogImageHeight: 615,
      });
      // //////////////////////////////////////////////////////////////////////
    },
  ]);
})();
