
import Vue from 'vue';

const bootstrapLabelColorMap = new Map([
  ['danger', 'label-danger'],
  ['default', 'label-default'],
  ['info', 'label-info'],
  ['primary', 'label-primary'],
  ['success', 'label-success'],
  ['warning', 'label-warning'],
]);

const unifyLabelColorMap = new Map([
  ['default', 'label-u'],
  ['blue', 'label-blue'],
  ['red', 'label-red'],
  ['green', 'label-green'],
  ['turquoise', 'label-sea'],
  ['orange', 'label-orange'],
  ['yellow', 'label-yellow'],
  ['purple', 'label-purple'],
  ['aqua', 'label-aqua'],
  ['brown', 'label-brown'],
  ['dark-blue', 'label-dark-blue'],
  ['light-green', 'label-light-green'],
  ['dark', 'label-dark'],
  ['light', 'label-light'],
]);

// Component based off of the Unify label:
//  Unify_v1.9.4/HTML/shortcode_compo_labels.html
export default Vue.extend({
  name: 'BaseLabel',
  props: {
    circular: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    level: {
      type: String,
      default: null,
    },
    unifyColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    colorClass(): string {
      if (!this.unifyColor && !this.level) {
        return unifyLabelColorMap.get('default');
      }

      if (this.unifyColor) {
        return unifyLabelColorMap.get(this.unifyColor);
      }

      return bootstrapLabelColorMap.get(this.level);
    },
    roundingClass(): string {
      if (this.circular) {
        return 'rounded-2x';
      }

      if (this.rounded) {
        return 'rounded';
      }

      return '';
    },
  },
});
