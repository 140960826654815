/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */

(function() {
  angular
    .module('app.directives', [])
    .directive('incrementResults', function() {
      return {
        restrict: 'E',
        templateUrl: '/scripts/directives/incrementresults.html',
        scope: {
          resultsLimit: '=', // must be the scope variable used on the ng-repeat's limitTo filter
          resultsLength: '=', // the total length of the array of items
          minLimit: '=', // the minimum amount it can be limited to
          incrementAmount: '=incrementBy', // amount to increment show more & show less by
        },
      };
    })

    .directive('faqListItem', function() {
      return {
        restrict: 'E',
        templateUrl: '/scripts/directives/faq-list-item.tpl.html',
        scope: {
          faq: '=',
          contentPageGo: '=',
        },
      };
    })

    .directive('caseStudyListItem', function($rootScope, $window) {
      return {
        restrict: 'E',
        templateUrl: '/scripts/directives/casestudy-list-item.tpl.html',
        scope: {
          caseStudy: '=',
          contentPageGo: '=',
        },
        link: function(scope) {
          scope.getPdfDownload = function(uri) {
            $window.open($rootScope.api_url + '/public/download?uri=' + uri);
          };
        },
      };
    })

    .directive('experienceListItem', function() {
      return {
        restrict: 'E',
        templateUrl: '/scripts/directives/experience-list-item.tpl.html',
        scope: {
          experience: '=',
          contentPageGo: '=',
        },
      };
    })

    .directive('researchListItem', function() {
      return {
        restrict: 'E',
        templateUrl: '/scripts/directives/research-list-item.tpl.html',
        scope: {
          research: '=',
          contentPageGo: '=',
        },
      };
    })

    .directive('docListItem', function($rootScope, $window) {
      return {
        restrict: 'E',
        templateUrl: '/scripts/directives/document-list-item.tpl.html',
        scope: {
          doc: '=',
          contentPageGo: '=',
        },
        link: function(scope) {
          scope.getPdfDownload = function(uri) {
            $window.open($rootScope.api_url + '/public/download?uri=' + uri);
          };
        },
      };
    });
})();
