// =============================================================================
// 404 Controller
// =============================================================================

angular.module('app').controller('404ErrorCtrl', [
  'MetaTagsUtil',
  (MetaTagsUtil) => {
    MetaTagsUtil.SetSocialMetaTags({
      title: 'Oops, we could not find what you were looking for',
      ogTitle: 'Oops, we could not find what you were looking for',
      ogUrl: '/404',
    });
  },
]);
